import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IBaseCDSTermsSettings } from '../../models/cds-terms-settings/base-cds-terms-settings';
import { ICDSTermsSectionSettings } from '../../models/cds-terms-settings/cds-terms-section-settings';

@Component({
  selector: 'lv-cds-terms-settings',
  templateUrl: './lv-cds-terms-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvCdsTermsSettingsComponent implements OnInit{
  @Input() systemDefaults: IBaseCDSTermsSettings<ICDSTermsSectionSettings>;
  @Input() myDefaults: IBaseCDSTermsSettings<ICDSTermsSectionSettings>;

  constructor() {
  }

  ngOnInit(): void {
    this.myDefaults = {
      overrideSystemDefaults: true,
      settings: {},
    } as IBaseCDSTermsSettings<ICDSTermsSectionSettings>
    
    this.systemDefaults = {
      overrideSystemDefaults: false,
      settings: {},
    } as IBaseCDSTermsSettings<ICDSTermsSectionSettings>
  }

    /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns falg- true if element with given id is found
   */
    getTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
      return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
    }
}
