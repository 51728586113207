import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ICdsCreditSectionSettings } from '@lv-analytics/models/cds/market-data/market-data-cds-credit-settings/market-data-cds-credit-section-settings';
import { ICdsCreditSettings } from '@lv-analytics/models/cds/market-data/market-data-cds-credit-settings/market-data-cds-credit-settings';
import { IMarketDataCdsSettings } from '@lv-analytics/models/cds/market-data/market-data-cds-settings';
import { IInterestRatesSectionSettings } from '@lv-analytics/models/market-data-settings/interest-rates/interest-rates-section-settings';
import { IInterestRatesSettings } from '@lv-analytics/models/market-data-settings/interest-rates/interest-rates-settings';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';
import { CompanyAndUserSettingsService } from '@lv-analytics/services/company-settings/company-settings.service';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models/lv-data-master';
import { LvErrorService } from '@lv-core-ui/services';
import _ from 'lodash';

@Component({
  selector: 'lv-cds-market-data-settings',
  templateUrl: './lv-cds-market-data-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCdsMarketDataSettingsComponent implements OnInit, IDiscardable {

   @Output() startLoading: EventEmitter<void>;
    @Output() endLoading: EventEmitter<void>;
    @Output() didCancel: EventEmitter<void>;
  
    settings: IMarketDataCdsSettings;
    originalSettings: IMarketDataCdsSettings;
    systemYieldCurvesSettingsData: ISystemYieldCurvesSettingsView;
  
    constructor(private _companyAndUserSettingsService: CompanyAndUserSettingsService,
                private _errorService: LvErrorService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _discardChangesService: DiscardChangesService) {
      this.startLoading = new EventEmitter<void>();
      this.endLoading = new EventEmitter<void>();
      this.didCancel = new EventEmitter<void>();
  
      this.settings = {
        creditSettings: {
          systemDefaults: {} as ICdsCreditSectionSettings,
          environments: []
        } as ICdsCreditSettings,
        interestRatesSettings: {
          systemDefaults: {
            yieldCurveSettings: []
          } as IInterestRatesSectionSettings,
          environments: []
        } as IInterestRatesSettings,
      } as IMarketDataCdsSettings;
  
      this.originalSettings = _.cloneDeep(this.settings);
      this._discardChangesService.register('MarketData', this);
    }
  
    ngOnInit(): void {
      this.getMarketDataCdsSettings();
    }
  
      /**
     * Get Market Data Cds Settings
     */
      async getMarketDataCdsSettings () {
        try {
          this.startLoading.next();
          this.settings = await this._companyAndUserSettingsService.getMarketDataCdsSettings();
  
          this.originalSettings = _.cloneDeep(this.settings);
          this.systemYieldCurvesSettingsData = await this._companyAndUserSettingsService.getYieldCurveSettingsData();
        }
        catch (error) {
          this._errorService.handleError(error);
        }
        finally {
          this.endLoading.next();
          this._changeDetectorRef.detectChanges();
        }
      }
  
    @HostBinding('class.lv-market-data-setting-wrapper')
    get isLvMarketDataCdsSettingsComponent () {
      return true;
    }
  
    /**
     * 
     * @returns Method checks if any changes in state happened.
     */
    isChanged(): boolean {
      return !_.isEqual(this.settings, this.originalSettings);
    }
  
    /**
     * Method discards current changes in state that are not saved.
     */
    discardChanges(): void {
      this.settings = _.cloneDeep(this.originalSettings);
      this._changeDetectorRef.detectChanges();
    }
  
    /**
     * Occurs on cancel.
     */
    onCancel() {
      this.didCancel.next();
    }
  
     /**
     * Occurs on save and saves term settings.
     */
     async onSave() {
      if (!this.isChanged()) {
        return;
      }
  
      try {
        this.startLoading.next()
       
        await this._companyAndUserSettingsService.saveMarketDataCdsSettings(this.settings);
        this.originalSettings = _.cloneDeep(this.settings);
  
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
          {'settings_section': 'Market Data'}));
      }
      catch (error) {
        this._errorService.handleError(error);
      }
      finally {
        this.endLoading.next();
        this._changeDetectorRef.detectChanges();
      }
    }
  
    /**
     * Resets cds settings to default.
     */
    async onResetToDefault() {
      try {
        this.startLoading.next();
        this.settings = await this._companyAndUserSettingsService.resetMarketDataCdsSettings();
  
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
        {'settings_section': 'Market Data'}));
      }
      catch (error) {
        this._errorService.handleError(error);
      }
      finally {
        this.endLoading.next();
        this._changeDetectorRef.detectChanges();
      }
    }

}
