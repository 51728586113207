<lv-section-settings
    [settings]="settings"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="interestRatesOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]"
    [dataCy]="'DM-1492'">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-interest-rates-bond-section
      [settings]="systemSettings"
      [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData">
    </lv-interest-rates-bond-section>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-interest-rates-bond-section
      [settings]="settings"
      [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-interest-rates-bond-section>
  </ng-template>
</lv-section-settings>

<ng-template #interestRatesOverrideSystemDefaultsSettingsTooltip>
  <lv-tooltip dm="dM-1492"></lv-tooltip>
</ng-template>

