<lv-terms-section-settings
    [systemDefault]="systemDefaults"
    [myDefault]="myDefaults"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="dividendProtectionOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]"
    [dataCy]="'DM-1513'">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-dividend-protection-section-settings
    [settings]="systemSettings">
    </lv-dividend-protection-section-settings>
  </ng-template>

  <ng-template myDefaultsSettings let-settings>
    <lv-dividend-protection-section-settings
      [settings]="settings"
      [overrideDefaults]="settings.overrideSystemDefaults">
    </lv-dividend-protection-section-settings>
  </ng-template>
</lv-terms-section-settings>

<ng-template #dividendProtectionOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideTermsSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1513"></lv-tooltip>
  </ng-container>
</ng-template>
