<lv-flex-box class="lv-quick-terms-entry">
  <lv-flex-box direction="row" data-cy="DM-334">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="instrumentNameSourceTooltip"
      filter="span">
      <span>
        Instrument Name
      </span>
    </div>
    <lv-text-box class="lv-input-field lv-input-field--full lv-input-field-instrument"
      lvId="lv-quick-terms-instrument-name"
      [(ngModel)]="view.quickTerms.issueName"
      (didKeyDownEnter)="doModelChange()"
      (didBlur)="doModelChange()"
      name="instrument-name">
    </lv-text-box>
  </lv-flex-box>
  <lv-flex-box
    direction="row"
    data-cy="DM-335">

    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="convertibleTypeSourceTooltip"
      filter="span">
      <span>
        Convertible Type
      </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        lvId="lv-quick-terms-convertible-type"
        name="underlying-ccy"
        [data]="view.convertibleTypeLookup.items"
        valueField="id"
        textField="text"
        valuePrimitive="true"
        [popupSettings]="{ width: 237 }"
        [(ngModel)]="view.quickTerms.convertibleType"
        (ngModelChange)="onConvertibleTypeChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-379">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="CCYSourceTooltip"
      filter="span">
      <span>
        CCY
      </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        lvId="lv-quick-terms-ccy"
        class="lv-quick-terms-small-input"
        name="ccy"
        [data]="view.currencyLookup.items"
        valueField="id"
        textField="text"
        valuePrimitive="true"
        [(ngModel)]="view.quickTerms.currency"
        (ngModelChange)="onCCYChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-380">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="underlyingCCYSourceTooltip"
      filter="span">
      <span>
        Underlying CCY
      </span>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        lvId="lv-quick-terms-underlying-ccy"
        class="lv-quick-terms-small-input"
        name="underlying-ccy"
        [data]="view.currencyLookup.items"
        valueField="id"
        textField="text"
        valuePrimitive="true"
        [(ngModel)]="view.quickTerms.underlyingCurrency"
        (ngModelChange)="onCCYChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="maturityDateSourceTooltip"
      filter="span">
      <span>
        Maturity
      </span>
    </div>
    <lv-flex-box
      direction="row"
      class="lv-input-field lv-input-field--full"
      data-cy="DM-1404"
      >
      <lv-numeric-text-box
      *ngIf="!view.quickTerms.perpetual"
        lvId="lv-quick-terms-maturity"
        placeholder="in years"
        class="lv-quick-terms-small-input"
        name="maturity-date"
        [disabled]="view.quickTerms.perpetual"
        [format] = "'#.####'"
        [decimals]="4"
        [(ngModel)]="view.quickTerms.maturityDateYears"
        (didBlur)="onMaturityDateChange()"
        (didKeyDownEnter)="onMaturityDateChange()">
      </lv-numeric-text-box>
      <div
        data-cy="DM-990"
        class="lv-field lv-field--checkbox"
        *ngIf="view.quickTerms.perpetual"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="perpetualQTTooltipTemplate"
        filter="label:not(lv-xl-label label)">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="view.maturityDatePerpetualCheckboxId"
          [(ngModel)]="view.quickTerms.perpetual"
          (ngModelChange)="doModelChange('DM-990')"
          name="perpetual">
          <label class="k-checkbox-label"
          lvId="lv-quick-terms-perpetual"
          [attr.for]="view.maturityDatePerpetualCheckboxId">
          Perpetual
        </label>
      </div>
      <div
        data-cy="DM-990"
        class="lv-field lv-field--checkbox lv-quick-terms-checkbox"
        *ngIf="!view.quickTerms.perpetual"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="perpetualQTTooltipTemplate"
        filter="label:not(lv-xl-label label)">
        <input class="k-checkbox"
          type="checkbox"
          [attr.id]="view.maturityDatePerpetualCheckboxId"
          [(ngModel)]="view.quickTerms.perpetual"
          (ngModelChange)="doModelChange('DM-990')"
          name="perpetual">
          <label class="k-checkbox-label"
          lvId="lv-quick-terms-perpetual"
          [attr.for]="view.maturityDatePerpetualCheckboxId">
          Perpetual
        </label>
      </div>
    </lv-flex-box>
  </lv-flex-box>
  <lv-flex-box
    data-cy="DM-361"
    direction="row">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="statusSourceTooltip"
      filter="span">
      <span>
        Setup Status
      </span>
    </div>
    <div class="lv-input-field lv-input-field--auto">
      <kendo-dropdownlist
        lvId="lv-quick-terms-status"
        name="status"
        [data]="view.setupStatusLookup.items"
        valueField="id"
        textField="text"
        valuePrimitive="true"
        [(ngModel)]="view.quickTerms.setupStatus"
        (ngModelChange)="onStatusChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="view.isFixed" class="lv-quick-terms-fieldset" direction="row">
    <lv-fieldset-panel legend="FIXED PRICING">
      <lv-flex-box
        data-cy="DM-363"
        direction="row"
        *ngIf="!view.isPeps && !view.isPercs">
        <div
          class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionPriceFixedPricingSourceTooltip"
          filter="span">
          <span>
            Conversion Price
          </span>
        </div>
        <div class="lv-quick-terms-input">
          <lv-numeric-text-box
            [autoWidth]="true"
            name="conversionPrice"
            [(ngModel)]="view.quickTerms.conversionPrice"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()">
          </lv-numeric-text-box>
        </div>
        <ng-container *ngIf="!view.isUnderlyingConversionPriceCurrency">
          <div class="lv-quick-terms-sufix" lvId="underlyingCPSuffix">{{view.quickTerms.currency}}</div>
        </ng-container>
        <ng-container *ngIf="view.isUnderlyingConversionPriceCurrency">
          <div class="lv-quick-terms-sufix" lvId="underlyingCPSuffix">{{view.quickTerms.underlyingCurrency}}</div>
        </ng-container>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        *ngIf="view.isPeps"
        data-cy="DM-841"
        >
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionLowerPriceSourceTooltip"
          filter="span">
          <span>
            Lower Strike
          </span>
        </div>
        <div class="lv-quick-terms-input">
          <lv-numeric-text-box
            [autoWidth]="true"
            name="lowerStrike"
            [(ngModel)]="view.quickTerms.lowerStrike"
            (didBlur)="onStrikeChange()"
            (didKeyDownEnter)="onStrikeChange()">
          </lv-numeric-text-box>
        </div>
        <div class="lv-quick-terms-sufix" lvId="lowerStrikeSuffix">{{view.quickTerms.currency}}</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        *ngIf="view.isPeps"
        data-cy="DM-839">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionHigherPriceSourceTooltip"
          filter="span">
          <span>
            Higher Strike
          </span>
        </div>
        <div class="lv-quick-terms-input">
          <lv-numeric-text-box
            [autoWidth]="true"
            name="higherStrike"
            [(ngModel)]="view.quickTerms.higherStrike"
            (didBlur)="onStrikeChange()"
            (didKeyDownEnter)="onStrikeChange()">
          </lv-numeric-text-box>
        </div>
        <div class="lv-quick-terms-sufix" lvId="higherStrikeSuffix">{{view.quickTerms.currency}}</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        *ngIf="view.isPercs"
        data-cy="DM-835">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="conversionRatioPercsFixedPricingSourceTooltip"
          filter="span">
          <span>
            Conversion Ratio
          </span>
        </div>
        <div class="lv-quick-terms-input">
          <lv-numeric-text-box
            [autoWidth]="true"
            name="conversionRatio"
            [(ngModel)]="view.quickTerms.conversionRatio"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()">
          </lv-numeric-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        *ngIf="view.isPercs"
        data-cy="DM-836"
        >
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="CRCapFixedPricingSourceTooltip"
          filter="span">
          <span>
            Cap Price
          </span>
        </div>
        <div class="lv-quick-terms-input">
          <lv-numeric-text-box
            [autoWidth]="true"
            name="conversionRatioCap"
            [(ngModel)]="view.quickTerms.conversionRatioCap"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()">
          </lv-numeric-text-box>
        </div>
        <div class="lv-quick-terms-sufix" lvId="crCapSuffix">%</div>
      </lv-flex-box>
      <lv-flex-box
        direction="row"
        data-cy="DM-1371">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="couponFixedPricingSourceTooltip"
          filter="span">
          <span>
            Coupon
          </span>
        </div>
        <div class="lv-input-field lv-input-field--full">
          <lv-text-box
            class="lv-quick-terms-input"
            name="coupon"
            [(ngModel)]="view.quickTerms.coupon"
            (didKeyDownEnter)="doModelChange()"
            (didBlur)="doModelChange()"
            (didClear)="doModelChange()">
          </lv-text-box>
        </div>
      </lv-flex-box>
      <lv-flex-box
        *ngIf="!(view.isPeps || view.isPerpetual)"
        direction="row"
        data-cy="DM-384">
        <div class="lv-label lv-label-field"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="redemptionValueFixedPricingSourceTooltip"
          filter="span">
          <span>
            Redemption Value
          </span>
        </div>
        <div class="lv-quick-terms-input">
          <lv-numeric-text-box
            [autoWidth]="true"
            name="redemptionValue"
            [(ngModel)]="view.quickTerms.redemption"
            (didBlur)="onRedemptionChange()"
            (didKeyDownEnter)="onRedemptionChange()">
          </lv-numeric-text-box>
        </div>
        <div class="lv-quick-terms-sufix" lvId="redemptionValueSuffix">%</div>
      </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box *ngIf="!view.isFixed" class="lv-quick-terms-fieldset lv-pricing-range" direction="row">
    <lv-fieldset-panel legend="PRICING RANGE">
      <lv-flex-box direction="row">
        <lv-flex-box>
          <div class="lv-label lv-label-field">&nbsp;</div>
        </lv-flex-box>
        <lv-flex-box class="lv-quick-terms-small-input">
          <div class="lv-label lv-label-field">Best</div>
        </lv-flex-box>
        <lv-flex-box class="lv-quick-terms-small-input">
          <div class="lv-label lv-label-field">Mid</div>
        </lv-flex-box>
        <lv-flex-box class="lv-quick-terms-small-input">
          <div class="lv-label lv-label-field">Worst</div>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-4348">
        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="premiumPricingRangeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              {{view.premiumLabel}}
            </span>
            <lv-xl-label data-cy="NI_PREM_PRC_RANGE" [field]="'NI_PREM_PRC_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>

        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4351">
          <lv-numeric-text-box
            *ngIf="!view.isPeps"
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="premiumBest"
            [(ngModel)]="view.quickTerms.priceTalk.premiumBest"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>
          <lv-numeric-text-box
            *ngIf="view.isPeps"
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="higherStrikePremiumBest"
            [(ngModel)]="view.quickTerms.priceTalk.higherStrikePremiumBest"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>

        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4352">
          <lv-numeric-text-box
            *ngIf="!view.isPeps"
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            [readonly]="true"
            [disabled]="true"
            name="premiumMid"
            [(ngModel)]="view.premiumMid">
          </lv-numeric-text-box>
          <lv-numeric-text-box
            *ngIf="view.isPeps"
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            [readonly]="true"
            [disabled]="true"
            name="higherStrikePremiumMid"
            [(ngModel)]="view.higherStrikePremiumMid">
          </lv-numeric-text-box>
        </lv-flex-box>

        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4353">
          <lv-numeric-text-box
            *ngIf="!view.isPeps"
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="premiumWorst"
            [(ngModel)]="view.quickTerms.priceTalk.premiumWorst"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>
          <lv-numeric-text-box
            *ngIf="view.isPeps"
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="higherStrikePremiumWorst"
            [(ngModel)]="view.quickTerms.priceTalk.higherStrikePremiumWorst"
            (didBlur)="doPremiumBestOrWorstChange()"
            (didKeyDownEnter)="doPremiumBestOrWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <div class="lv-quick-terms-sufix" lvId="quickTermsPremiumSuffix">%</div>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-1304">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="couponPricingRangeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Coupon
            </span>
            <lv-xl-label data-cy="NI_CPN_RT_RANGE" [field]="'NI_CPN_RT_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>

        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4317">
          <lv-numeric-text-box
            [format]="view.numberFourFormatAssumption"
            [decimals]="view.numberOfFourDecimalPlaces"
            [autoWidth]="true"
            name="couponBest"
            [(ngModel)]="view.quickTerms.priceTalk.couponBest"
            (didBlur)="onCouponBestChange()"
            (didKeyDownEnter)="onCouponBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4318">
          <lv-numeric-text-box
            [format]="view.numberFourFormatAssumption"
            [decimals]="view.numberOfFourDecimalPlaces"
            [autoWidth]="true"
            [readonly]="true"
            [disabled]="true"
            name="couponMid"
            [(ngModel)]="view.couponMid">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4319">
          <lv-numeric-text-box
            [format]="view.numberFourFormatAssumption"
            [decimals]="view.numberOfFourDecimalPlaces"
            [autoWidth]="true"
            name="couponWorst"
            [(ngModel)]="view.quickTerms.priceTalk.couponWorst"
            (didBlur)="onCouponWorstChange()"
            (didKeyDownEnter)="onCouponWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <div class="lv-quick-terms-sufix" lvId="quickTermsCouponSuffix">%</div>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-1305">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="issuePricePricingRangeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Issue Price
            </span>
            <lv-xl-label [field]="'NI_ISSUE_PX_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4322">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="issuePriceBest"
            [(ngModel)]="view.quickTerms.priceTalk.issuePriceBest"
            (didBlur)="onIssuePriceBestChange()"
            (didKeyDownEnter)="onIssuePriceBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4323">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            [readonly]="true"
            [disabled]="true"
            name="issuePriceMid"
            [(ngModel)]="view.issuePriceMid"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4324">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="issuePriceWorst"
            [(ngModel)]="view.quickTerms.priceTalk.issuePriceWorst"
            (didBlur)="onIssuePriceWorstChange()"
            (didKeyDownEnter)="onIssuePriceWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <div class="lv-quick-terms-sufix" lvId="quickTermsIssuePriceSuffix">% of Par</div>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-1306">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="redemptionValuePricingRangeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Redemption Value
            </span>
            <lv-xl-label [field]="'NI_REDEM_PX_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4326">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="redemptionBest"
            [disabled]="view.isPeps || view.isPerpetual || view.isPercs"
            [(ngModel)]="view.quickTerms.priceTalk.redemptionValueBest"
            (didBlur)="onRedemptionBestChange()"
            (didKeyDownEnter)="onRedemptionBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4327">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            [readonly]="true"
            name="redemptionMid"
            [disabled]="true"
            [(ngModel)]="view.redemptionMid">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4328">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="redemptionWorst"
            [disabled]="view.isPeps || view.isPerpetual || view.isPercs"
            [(ngModel)]="view.quickTerms.priceTalk.redemptionValueWorst"
            (didBlur)="onRedemptionWorstChange()"
            (didKeyDownEnter)="onRedemptionWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <div class="lv-quick-terms-sufix" lvId="quickTermsRedemptionValueSuffix">% of Par</div>
      </lv-flex-box>

      <lv-flex-box
        direction="row"
        data-cy="DM-4349">

        <lv-flex-box>
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="issueYieldPricingRangeSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Issue Yield
            </span>
            <lv-xl-label [field]="'NI_YLD_RANGE'"></lv-xl-label>
          </div>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4355">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="issueYieldBest"
            [disabled]="view.isPeps || view.isPerpetual || view.isPercs"
            [(ngModel)]="view.quickTerms.priceTalk.issueYieldBest"
            (didBlur)="onIssueYieldBestChange()"
            (didKeyDownEnter)="onIssueYieldBestChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4356">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            [readonly]="true"
            name="issueYieldMid"
            [disabled]="true"
            [(ngModel)]="view.issueYieldMid">
          </lv-numeric-text-box>
        </lv-flex-box>
        <lv-flex-box
          class="lv-quick-terms-small-input"
          data-cy="DM-4357">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="issueYieldWorst"
            [disabled]="view.isPeps || view.isPerpetual || view.isPercs"
            [(ngModel)]="view.quickTerms.priceTalk.issueYieldWorst"
            (didBlur)="onIssueYieldWorstChange()"
            (didKeyDownEnter)="onIssueYieldWorstChange()">
          </lv-numeric-text-box>
        </lv-flex-box>
        <div class="lv-quick-terms-sufix" lvId="quickTermsIssueYieldSuffix">% </div>
      </lv-flex-box>

      <lv-flex-box
        *ngIf="view.quickTerms.fixedStockRef"
        direction="row">
        <lv-flex-box>
          <div class="lv-label lv-label-field">&nbsp;</div>
        </lv-flex-box>
        <lv-flex-box class="lv-quick-terms-small-input">
          <div class="lv-label lv-label-field lv-label-field-left">Stock ({{view.quickTerms.currency}})</div>
        </lv-flex-box>
        <lv-flex-box 
          *ngIf="view.isCrossFx"
          class="lv-quick-terms-small-input">
          <div class="lv-label lv-label-field lv-label-field-left">Stock ({{view.quickTerms.underlyingCurrency}})</div>
        </lv-flex-box>
        <lv-flex-box 
          *ngIf="view.isCrossFx"
          class="lv-quick-terms-small-input">
          <div class="lv-label lv-label-field lv-label-field-left">FX</div>
        </lv-flex-box>
      </lv-flex-box>

      <lv-flex-box
        class="lv-quick-terms-stock-ref"
        direction="row"
        data-cy="DM-4618">
        <div class="lv-label lv-label-field lv-label-before-checkbox"
          showAfter="1500"
          kendoTooltip
          [tooltipTemplate]="stockRefPricingRangeSourceTooltip"
          filter="span:not(lv-xl-label span)">
          <span>
            Fixed Reference
          </span>
          <lv-xl-label data-cy="FIXED_STK_REF_CBCCY" [field]="'FIXED_STK_REF_CBCCY'"></lv-xl-label>
        </div>
        <div class="lv-field lv-field--checkbox lv-quick-terms-checkbox">
          <input
            class="k-checkbox"
            type="checkbox"
            [attr.id]="view.stockRefCheckBoxId"
            name="stockRefCheckBox"
            [(ngModel)]="view.quickTerms.fixedStockRef"
            (ngModelChange)="onStockPriceReferenceChange('DM-4618')">
          <label class="k-checkbox-label"
            lvId="lv-quick-terms-stock-ref"
            [attr.for]="view.stockRefCheckBoxId">
          </label>
        </div>

        <lv-flex-box
          *ngIf="view.quickTerms.fixedStockRef"
          data-cy="DM-4618"
          class="lv-quick-terms-small-input">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="stockRef"
            [ngModel]="stockReferenceSignalStore.stockRef()"
            (ngModelChange)="onStockRefChange($event)">
          </lv-numeric-text-box>
        </lv-flex-box>

        <lv-flex-box
          *ngIf="view.quickTerms.fixedStockRef && view.isCrossFx"
          data-cy="DM-4636"
          class="lv-quick-terms-small-input">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="stockRefEquity"
            [ngModel]="stockReferenceSignalStore.stockRefEquity()"
            (ngModelChange)="onStockRefEquityChange($event)">
          </lv-numeric-text-box>
        </lv-flex-box>

        <lv-flex-box
          *ngIf="view.quickTerms.fixedStockRef && view.isCrossFx"
          data-cy="DM-355"
          class="lv-quick-terms-small-input">
          <lv-numeric-text-box
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            name="stockRefFx"
            [ngModel]="stockReferenceSignalStore.fx()"
            (ngModelChange)="onStockRefFxChange($event)">
          </lv-numeric-text-box>
        </lv-flex-box>
      </lv-flex-box>

    </lv-fieldset-panel>
  </lv-flex-box>
  <lv-flex-box direction="row" class="lv-quick-terms-fieldset">
    <lv-fieldset-panel legend="OPTIONAL TERMS">
      <lv-flex-box
        direction="row">
      <lv-flex-box>
        <lv-flex-box direction="row" data-cy="DM-390">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="putsOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Put(s)
            </span>
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder='in years'
              class="lv-quick-terms-input"
              [(ngModel)]="view.quickTerms.puts"
              (didBlur)="doModelChange()"
              (didKeyDownEnter)="doModelChange()"
              (didClear)="doModelChange()"
              name="puts">
            </lv-text-box>
          </div>
        </lv-flex-box>
        <lv-flex-box
          direction="row"
          data-cy="DM-391">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="putPricesOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Put Price(s)
            </span>
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="% of nominal"
              class="lv-quick-terms-big-input"
              [(ngModel)]="view.quickTerms.putPrice"
              (didBlur)="doModelChange()"
              (didKeyDownEnter)="doModelChange()"
              (didClear)="doModelChange()"
              name="put-prices">
            </lv-text-box>
          </div>
          <div class="lv-quick-terms-sufix" lvId="putPricesSuffix">%</div>
        </lv-flex-box>
        <lv-flex-box
          direction="row"
          data-cy="DM-395">

          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="callProtectionOptionalTermsSourceTooltip"
            filter="span:not(lv-xl-label span)"
            >
            <span>
              Call Protection
            </span>
            <!-- <lv-xl-label [field]="'CALL_PROTECTION'"></lv-xl-label> -->
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="in years"
              class="lv-quick-terms-input"
              [(ngModel)]="view.quickTerms.callProtection"
              (didKeyDownEnter)="doModelChange()"
              (didBlur)="doModelChange()"
              (didClear)="doModelChange()"
              name="call-protection">
            </lv-text-box>
          </div>
        </lv-flex-box>
        <lv-flex-box
          direction="row"
          data-cy="DM-396">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="softCallsOptionalTermsSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Soft Call(s)
            </span>
            <!-- <lv-xl-label [field]="'CALL_SOFT_DATES'"></lv-xl-label> -->
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="in years"
              class="lv-quick-terms-input"
              [(ngModel)]="view.quickTerms.softCall"
              (didKeyDownEnter)="doModelChange()"
              (didBlur)="doModelChange()"
              (didClear)="doModelChange()"
              name="soft-call">
            </lv-text-box>
          </div>
        </lv-flex-box>
        <lv-flex-box
          direction="row"
          data-cy="DM-397">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="softCallTriggersOptionalTermsSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Soft Call Trigger(s)
            </span>
            <!-- <lv-xl-label [field]="'CALL_SOFT_TRIGGERS'"></lv-xl-label> -->
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="% of nominal"
              class="lv-quick-terms-big-input"
              [(ngModel)]="view.quickTerms.softCallTriggers"
              (didKeyDownEnter)="doModelChange()"
              (didBlur)="doModelChange()"
              (didClear)="doModelChange()"
              name="soft-call-trigger">
            </lv-text-box>
          </div>
          <div class="lv-quick-terms-sufix" lvId="softCallTriggerSuffix">%</div>
        </lv-flex-box>
        <lv-flex-box
          direction="row"
          data-cy="DM-398">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="callMakeWholeOptionalTermsSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Call Make-Whole
            </span>
            <!-- <lv-xl-label [field]="'CALL_MW_TYPE'"></lv-xl-label> -->
          </div>
          <div class="lv-input-field">
            <kendo-dropdownlist
              lvId="lv-quick-terms-call-make-whole"
              class="lv-quick-terms-input"
              name="makeWholeType"
              [data]="view.makeWholeTypeLookup.items"
              valueField="id"
              textField="text"
              [valuePrimitive]="true"
              [(ngModel)]="view.quickTerms.callMakeWholeType"
              (ngModelChange)="onCallMWTypeChanged()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box
          data-cy="DM-399"
          *ngIf="view.fieldsVisibilityState.discountFieldsVisible"
          direction="row">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="callMWSpreadOptionalTermsSourceTooltip"
            filter="span:not(lv-xl-label span)">
            <span>
              Call MW Spread
            </span>
            <!-- <lv-xl-label [field]="'CALL_MW_SPREAD'"></lv-xl-label> -->
          </div>
          <div class="lv-quick-terms-input">
            <lv-numeric-text-box
              name="discountSpread"
              [format]="view.numberFormatBps"
              [decimals]="view.numberOfDecimalsBps"
              [autoWidth]="true"
              [(ngModel)]="view.quickTerms.callMakeWholeSpread"
              (didBlur)="doModelChange()"
              (didKeyDownEnter)="doModelChange()">
            </lv-numeric-text-box>
          </div>
          <div class="lv-quick-terms-sufix" lvId="callMWSpreadSuffix">bps</div>
        </lv-flex-box>
        <lv-flex-box
          data-cy="DM-412"
          *ngIf="view.fieldsVisibilityState.isCocoEndDateVisible"
          direction="row">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="coCoEndDatesOptionalTermsSourceTooltip"
            filter="span">
            <span>
              CoCo End Date(s)
            </span>
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="in years"
              class="lv-quick-terms-input"
              [(ngModel)]="view.quickTerms.coCoEndDate"
              (didKeyDownEnter)="onCoCoChange()"
              (didBlur)="onCoCoChange()"
              (didClear)="doModelChange()"
              name="coco-end-dates">
            </lv-text-box>
          </div>
        </lv-flex-box>
        <lv-flex-box
          data-cy="DM-413"
          *ngIf="view.fieldsVisibilityState.isCocoTriggerVisible"
          direction="row">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="coCoTriggersOptionalTermsSourceTooltip"
            filter="span">
            <span>
              CoCo Trigger(s)
            </span>
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="as %"
              class="lv-quick-terms-input"
              [(ngModel)]="view.quickTerms.coCoTrigger"
              (didKeyDownEnter)="onCoCoChange()"
              (didBlur)="onCoCoChange()"
              (didClear)="doModelChange()"
              name="coco-triggers">
            </lv-text-box>
          </div>
          <div class="lv-quick-terms-sufix" lvId="cocoTriggerSuffix">%</div>
        </lv-flex-box>
        <lv-flex-box
          direction="row"
          class="lv-quick-terms-stock-ref"
          data-cy="DM-1370">

          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="exchangeableOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Exchangeable
            </span>
          </div>
          <div class="lv-field lv-field--checkbox lv-quick-terms-checkbox">
            <input
              class="k-checkbox"
              type="checkbox"
              [attr.id]="view.exchangeableCheckBoxId"
              name="Exchangeable"
              [(ngModel)]="view.quickTerms.exchangeable"
              (ngModelChange)="onExchangeableChange()">
            <label class="k-checkbox-label"
              lvId="lv-quick-terms-exchangeable"
              [attr.for]="view.exchangeableCheckBoxId">
            </label>
          </div>
          <div class="lv-quick-terms-input" *ngIf="view.quickTerms.exchangeable">
            <lv-numeric-text-box
              placeholder="% pledged"
              [autoWidth]="true"
              [format]="view.numberFormatAssumption"
              [decimals]="view.numberOfDecimalsPercentageAssumption"
              [disabled]="!view.quickTerms.exchangeable"
              name="pledgedShares"
              [(ngModel)]="view.quickTerms.pledgedShares"
              (didBlur)="doModelChange()"
              (didKeyDownEnter)="doModelChange()">
            </lv-numeric-text-box>
          </div>
          <div *ngIf="view.quickTerms.exchangeable" class="lv-quick-terms-sufix" lvId="pledgedSharesSuffix">%</div>
        </lv-flex-box>
      </lv-flex-box>
      <lv-flex-box class="lv-terms-quick-terms-right-section">
        <lv-flex-box
          *ngIf="view.fieldsVisibilityState.isResetVisible"
          direction="row"
          data-cy="DM-408">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="resetsOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Reset(s)
            </span>
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="in years"
              class="lv-quick-terms-input"
              [(ngModel)]="view.quickTerms.reset"
              (didBlur)="onResetChange()"
              (didKeyDownEnter)="onResetChange()"
              (didClear)="doModelChange()"
              name="reset">
            </lv-text-box>
          </div>
        </lv-flex-box>
        <lv-flex-box
          *ngIf="view.fieldsVisibilityState.isResetFloorVisible"
          direction="row"
          data-cy="DM-409">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="resetFloorsOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Reset Floor(s)
            </span>
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              placeholder="% of initial price"
              class="lv-quick-terms-big-input"
              [(ngModel)]="view.quickTerms.resetFloor"
              (didBlur)="onResetChange()"
              (didKeyDownEnter)="onResetChange()"
              name="reset-floor">
            </lv-text-box>
          </div>
          <div class="lv-quick-terms-sufix" lvId="resetFloorsSuffix">%</div>
        </lv-flex-box>
        <lv-flex-box
          *ngIf="view.fieldsVisibilityState.isResetMultipliersVisible"
          direction="row"
          data-cy="DM-410">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="resetMultipliersOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Reset Multiplier(s)
            </span>
          </div>
          <div class="lv-input-field lv-input-field--full">
            <lv-text-box
              class="lv-quick-terms-input"
              [(ngModel)]="view.quickTerms.resetMultiplier"
              (didKeyDownEnter)="doModelChange()"
              (didBlur)="doModelChange()"
              name="reset-multiplier">
            </lv-text-box>
          </div>
        </lv-flex-box>
        <lv-flex-box
          *ngIf="view.fieldsVisibilityState.isHyperIncrFactorVisible"
          direction="row"
          data-cy="DM-405">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="hyperIncrFactorOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Hyper Incr. Factor
            </span>
          </div>
          <div class="lv-quick-terms-input">
          <lv-numeric-text-box
            placeholder="as % of CR"
            [format]="view.numberFormatAssumption"
            [decimals]="view.numberOfDecimalsPercentageAssumption"
            [autoWidth]="true"
            [(ngModel)]="view.quickTerms.hyperIncrementalFactor"
            (didBlur)="doModelChange()"
            (didKeyDownEnter)="doModelChange()"
            name="hyper-incremental-factor">
          </lv-numeric-text-box>
        </div>
        <div class="lv-quick-terms-sufix" lvId="hyperIncrSuffix">%</div>
        </lv-flex-box>
        <lv-flex-box
          data-cy="DM-406"
          *ngIf="view.fieldsVisibilityState.isHyperStrikeVisible"
          direction="row">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="hyperStrikeOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Hyper Strike
            </span>
          </div>
          <div class="lv-quick-terms-input">
            <lv-numeric-text-box
              placeholder="as % of CP"
              [format]="view.numberFormatAssumption"
              [decimals]="view.numberOfDecimalsPercentageAssumption"
              [autoWidth]="true"
              [(ngModel)]="view.quickTerms.hyperStrike"
              (didBlur)="doModelChange()"
              (didKeyDownEnter)="doModelChange()"
              name="hyper-strike">
            </lv-numeric-text-box>
          </div>
          <div class="lv-quick-terms-sufix" lvId="hyperStrikeSuffix">%</div>
        </lv-flex-box>
        <lv-flex-box
          direction="row"
          class="lv-quick-terms-stock-ref" data-cy="DM-1405">

          <div class="lv-label lv-label-field lv-label-before-checkbox"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="dividendProtectionOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Dividend Protection
            </span>
          </div>
          <div class="lv-field lv-field--checkbox lv-quick-terms-checkbox" >
            <input
              class="k-checkbox"
              type="checkbox"
              [attr.id]="view.dividendProtectionCheckBoxId"
              name="DividendProtection"
              [(ngModel)]="view.quickTerms.useDividendProtection"
              (ngModelChange)="doModelChange()">
            <label class="k-checkbox-label"
              lvId="lv-quick-terms-div-protection-ch"
              [attr.for]="view.dividendProtectionCheckBoxId">
            </label>
          </div>
          <div class="lv-input-field lv-input-field--auto" *ngIf="view.quickTerms.useDividendProtection">
            <kendo-dropdownlist
              lvId="lv-quick-terms-div-protection"
              [data]="view.protectionTypeLookup.items"
              valueField="id"
              textField="text"
              [valuePrimitive]="true"
              [popupSettings]="{ width: 193 }"
              [(ngModel)]="view.quickTerms.dividendProtection"
              (ngModelChange)="doModelChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box
          data-cy="DM-416"
          direction="row"
          *ngIf="view.quickTerms.useDividendProtection">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="dividendThresholdOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Dividend Threshold
            </span>
          </div>
            <lv-flex-box data-cy="DM-416">
              <div class="lv-input-field lv-input-field--full">
              <lv-text-box
                class="lv-quick-terms-input-threshold"
                [(ngModel)]="view.quickTerms.dividendTresholdValue"
                (didBlur)="doModelChange()"
                (didKeyDownEnter)="doModelChange()"
                (didClear)="doModelChange()"
                name="dividend-treshold-value">
              </lv-text-box>
              </div>
            </lv-flex-box>
            <lv-flex-box
              data-cy="DM-773">
              <div class="lv-input-field lv-input-field--auto">
                <kendo-dropdownlist
                  lvId="lv-quick-terms-div-threshold"
                  [data]="view.dividendThresholdLookup.items"
                  valueField="id"
                  textField="text"
                  [valuePrimitive]="true"
                  [popupSettings]="{ width: 119 }"
                  [(ngModel)]="view.quickTerms.dividendTresholdType"
                  (ngModelChange)="doModelChange()">
                </kendo-dropdownlist>
              </div>
            </lv-flex-box>
        </lv-flex-box>
        <lv-flex-box
          data-cy="DM-418"
          direction="row"
          *ngIf="view.quickTerms.useDividendProtection">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="thresholdFrequencyOptionalTermsSourceTooltip"
            filter="span">
            <span>
              Threshold Period Freq.
            </span>
          </div>
          <div class="lv-input-field">
            <kendo-dropdownlist
              lvId="lv-quick-terms-threshold-frequency"
              class="lv-quick-terms-input"
              [data]="view.thresholdFrequencyLookup.items"
              valueField="id"
              textField="text"
              [valuePrimitive]="true"
              [(ngModel)]="view.quickTerms.tresholdFrequency"
              (ngModelChange)="doModelChange()">
            </kendo-dropdownlist>
          </div>
        </lv-flex-box>
        <lv-flex-box
          data-cy="DM-419"
          direction="row"
          *ngIf="view.quickTerms.useDividendProtection">
          <div class="lv-label lv-label-field"
            showAfter="1500"
            kendoTooltip
            [tooltipTemplate]="thresholdGrowthptionalTermsSourceTooltip"
            filter="span">
            <span>
              Threshold Growth
            </span>
          </div>
          <div class="lv-quick-terms-input">
            <lv-numeric-text-box
              [format]="view.numberFormat"
              [decimals]="view.numberOfDecimalsPercetenge"
              [autoWidth]="true"
              [(ngModel)]="view.quickTerms.tresholdGrowth"
              (didBlur)="doModelChange()"
              (didKeyDownEnter)="doModelChange()"
              name="threshold-growth">
            </lv-numeric-text-box>
          </div>
          <div class="lv-quick-terms-sufix" lvId="thresholdGrowthSufix">%</div>
        </lv-flex-box>
      </lv-flex-box>
    </lv-flex-box>
    </lv-fieldset-panel>
  </lv-flex-box>
</lv-flex-box>

<ng-template #instrumentNameSourceTooltip>
  <lv-tooltip dm="dM-334"></lv-tooltip>
</ng-template>
<ng-template #convertibleTypeSourceTooltip>
  <lv-tooltip dm="dM-335"></lv-tooltip>
</ng-template>
<ng-template #CCYSourceTooltip>
  <lv-tooltip dm="dM-379"></lv-tooltip>
</ng-template>
<ng-template #underlyingCCYSourceTooltip>
  <lv-tooltip dm="dM-380"></lv-tooltip>
</ng-template>
<ng-template #maturityDateSourceTooltip>
  <lv-tooltip dm="dM-1404"></lv-tooltip>
</ng-template>
<ng-template #statusSourceTooltip>
  <lv-tooltip dm="dM-361"></lv-tooltip>
</ng-template>
<ng-template #conversionPriceFixedPricingSourceTooltip>
  <lv-tooltip dm="dM-363"></lv-tooltip>
</ng-template>
<ng-template #conversionLowerPriceSourceTooltip>
  <lv-tooltip dm="dM-841"></lv-tooltip>
</ng-template>
<ng-template #conversionHigherPriceSourceTooltip>
  <lv-tooltip dm="dM-839"></lv-tooltip>
</ng-template>
<ng-template #conversionRatioPercsFixedPricingSourceTooltip>
  <lv-tooltip dm="dM-835"></lv-tooltip>
</ng-template>
<ng-template #CRCapFixedPricingSourceTooltip>
  <lv-tooltip dm="dM-836"></lv-tooltip>
</ng-template>
<ng-template #couponFixedPricingSourceTooltip>
  <lv-tooltip dm="dM-1371"></lv-tooltip>
</ng-template>
<ng-template #redemptionValueFixedPricingSourceTooltip>
  <lv-tooltip dm="dM-384"></lv-tooltip>
</ng-template>
<ng-template #premiumPricingRangeSourceTooltip>
  <lv-tooltip dm="dM-4348"></lv-tooltip>
</ng-template>
<ng-template #couponPricingRangeSourceTooltip>
  <lv-tooltip dm="dM-1304"></lv-tooltip>
</ng-template>
<ng-template #issuePricePricingRangeSourceTooltip>
  <lv-tooltip dm="dM-1305"></lv-tooltip>
</ng-template>
<ng-template #redemptionValuePricingRangeSourceTooltip>
  <lv-tooltip dm="dM-1306"></lv-tooltip>
</ng-template>
<ng-template #issueYieldPricingRangeSourceTooltip>
  <lv-tooltip dm="dM-4349"></lv-tooltip>
</ng-template>
<ng-template #stockRefPricingRangeSourceTooltip>
  <lv-tooltip dm="dM-4618"></lv-tooltip>
</ng-template>
<ng-template #putsOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-390"></lv-tooltip>
</ng-template>
<ng-template #putPricesOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-391"></lv-tooltip>
</ng-template>
<ng-template #callProtectionOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-395"></lv-tooltip>
</ng-template>
<ng-template #softCallsOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-396"></lv-tooltip>
</ng-template>
<ng-template #softCallTriggersOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-397"></lv-tooltip>
</ng-template>
<ng-template #callMakeWholeOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-398"></lv-tooltip>
</ng-template>
<ng-template #callMWSpreadOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-399"></lv-tooltip>
</ng-template>
<ng-template #coCoEndDatesOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-412"></lv-tooltip>
</ng-template>
<ng-template #coCoTriggersOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-413"></lv-tooltip>
</ng-template>
<ng-template #exchangeableOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-1370"></lv-tooltip>
</ng-template>
<ng-template #perpetualQTTooltipTemplate>
  <lv-tooltip dm="dM-990"></lv-tooltip>
</ng-template>
<ng-template #resetsOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-408"></lv-tooltip>
</ng-template>
<ng-template #resetFloorsOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-409"></lv-tooltip>
</ng-template>
<ng-template #resetMultipliersOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-410"></lv-tooltip>
</ng-template>
<ng-template #hyperIncrFactorOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-405"></lv-tooltip>
</ng-template>
<ng-template #hyperStrikeOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-406"></lv-tooltip>
</ng-template>
<ng-template #dividendProtectionOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-1405"></lv-tooltip>
</ng-template>
<ng-template #dividendThresholdOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-416"></lv-tooltip>
</ng-template>
<ng-template #thresholdFrequencyOptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-418"></lv-tooltip>
</ng-template>
<ng-template #thresholdGrowthptionalTermsSourceTooltip>
  <lv-tooltip dm="dM-419"></lv-tooltip>
</ng-template>
