<kendo-tabstrip class="lv-tabstrip lv-tabstrip-min-height lv-settings-content"
  [keepTabContent]="true">
  <kendo-tabstrip-tab
    selected="true"
    title="General"
    cssClass="DM-5116">
   <ng-template kendoTabContent>
     <lv-terms-equity-option-general-settings
       class="lv-equity-option-general-settings"
       [systemDefaults]="settings.system.generalSettings"
       [myDefaults]="settings.my.generalSettings">
     </lv-terms-equity-option-general-settings>
   </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
    title="Dividend Protection"
    cssClass="DM-6085">
    <ng-template kendoTabContent>
      <lv-terms-equity-option-dividend-protection-settings
      class="lv-equity-option-dividend-protection-settings"
        [systemDefaults]="settings.system.dividendProtectionSettings"
        [myDefaults]="settings.my.dividendProtectionSettings">
      </lv-terms-equity-option-dividend-protection-settings>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
<lv-flex-box class="lv-settings-footer" direction="row">
  <button kendoButton
    [primary]="true"
    lvId="cancel-equity-terms-settings"
    (click)="onCancel()">
      Cancel
  </button>
  <div class="lv-settings-footer-divider">&nbsp;</div>

  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-equity-terms-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    lvId="save-equity-terms-settings"
    [class.save-settings-btn-disabled]="!isChanged()"
    (click)="onSave()">
      Save
  </button>
</lv-flex-box>
