  <ng-container class="lv-model-customization-bond-settings-container">
    <lv-flex-box class="lv-settings-content">
      <lv-section-settings
        [settings]="settings"
        showAfter="1500"
        kendoTooltip
        [tooltipTemplate]="modelCustomizationBondOverrideSystemDefaultsSettingsTooltip"
        filter="div[data-tooltip-id]"
        [dataCy]="'DM-1485'">
        <ng-template systemDefaultsSettings let-systemSettings>
          <lv-model-customization-bond-section
            [settings]="systemSettings">
          </lv-model-customization-bond-section>
        </ng-template>
  
        <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
          <lv-model-customization-bond-section
            [settings]="settings"
            [overrideSystemDefaults]="overrideSystemDefaults">
          </lv-model-customization-bond-section>
        </ng-template>
      </lv-section-settings>
    </lv-flex-box>
    <lv-flex-box class="lv-settings-footer" direction="row">
      <button kendoButton
        [primary]="true"
        lvId="cancel-model-customization-settings"
        (click)="onCancel()">
        Cancel
      </button>
      <div class="lv-settings-footer-divider">&nbsp;</div>
      <button kendoButton
        class="reset-button"
        [primary]="true"
        lvId="reset-to-default-model-customization-settings"
        (click)="onResetToDefault()">
        Reset To Default
      </button>
      <button kendoButton
        [primary]="true"
        [class.save-settings-btn-disabled]="!isChanged()"
        lvId="save-model-customization-settings"
        (click)="onSave()">
        Save
      </button>
    </lv-flex-box>
  </ng-container>

<ng-template #modelCustomizationBondOverrideSystemDefaultsSettingsTooltip>
  <lv-tooltip dm="dM-1485"></lv-tooltip>
</ng-template>

