/**
 * Settings option items.
 */
export enum SettingsItemType {
  Convertible = 'Convertible',
  Equity = 'Equity',
  Bond = 'Bond',
  EquityOption = 'Equity Option',
  AssetSwap = 'Asset Swap',
  CDS = 'CDS',
  Instruments = 'Instruments',
  Valuation = 'Valuation',
  Notifications = 'Notifications',
  Environments = 'Environments',
  Other = 'Other',
  LeversysDataSyncing = 'Leversys Data Syncing',
}