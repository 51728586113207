<lv-terms-section-settings
  [systemDefault]="systemDefaults"
  [myDefault]="myDefaults"
  showAfter="1500"
  kendoTooltip
  [tooltipTemplate]="equityOverrideSystemSettingsTooltip"
  filter="div[data-tooltip-id]"
  [dataCy]="'DM-4805'">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-equity-terms-section-settings
    [settings]="systemSettings">
    </lv-equity-terms-section-settings>
  </ng-template>

  <ng-template myDefaultsSettings let-settings>
    <lv-equity-terms-section-settings
      [settings]="settings"
      [overrideDefaults]="settings.overrideSystemDefaults">
    </lv-equity-terms-section-settings>
  </ng-template>
</lv-terms-section-settings>

<ng-template #equityOverrideSystemSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideTermsSystemDefaultsLabel')">
    <lv-tooltip dm="dM-4805"></lv-tooltip>
  </ng-container>
</ng-template>

