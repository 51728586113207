import { HttpClient } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder, LogLevel, ILogger,
  HubConnectionState, HttpTransportType } from '@microsoft/signalr';

import { Subject } from 'rxjs';

import { refreshAccessToken } from '../../api/http-client-base.helpers';
import { LvLoggerService } from '../../services/lv-logger/lv-logger-service';
import { LvError } from '../../models/lv-error/base';
import { LvApplicationError } from '../../models/lv-error/application';
import { LvErrorType } from '../../models/lv-error/error-type';
import { LvDataMaster } from '../../models/lv-data-master';

export interface ILvHubOptions {
  url: string;
  logger?: ILogger | LogLevel;
  transport?: HttpTransportType;
  accessTokenFactory?: Promise<string>;
}

export interface ILvHubEvent {
  name: string;
  observer: Subject<any>;
}

export abstract class LvHub {

  public didConnectionStateChange: Subject<HubConnectionState>;
  public didError: Subject<LvError>;

  public get connectionState(): HubConnectionState {
    if (!this._connection) {
      return HubConnectionState.Disconnected;
    }

    return this._connection.state;
  }

  protected _connection: HubConnection;
  private _shouldEmitError: boolean;
  private _hubEvents: ILvHubEvent[];

  constructor(
    private _httpClient: HttpClient,
    private _logger: LvLoggerService,
    private _name: string
  ) {
    this._connection = null;
    this._shouldEmitError = true;
    this._hubEvents = [];

    this.didConnectionStateChange = new Subject<HubConnectionState>();
    this.didError = new Subject<LvError>();
  }

  public async startConnection(options: ILvHubOptions): Promise<void> {
    try {
      this.createConnection(options);
      this.attachEvents();
      await this._connection.start();
    }
    catch (error) {
      throw this.processError(error);
    }
    finally {
      this.publishConnectionState();
    }
  }

  public async stopConnection(): Promise<void> {
    try {
      await this.stopConnectionWithTimeout();
    }
    catch (error) {
      throw this.processError(error);
    }
    finally {
      this.publishConnectionState();
    }
  }

  public subscribeOn<T>(eventName: string, observer: Subject<T>) {
    this._hubEvents.push({
      name: eventName,
      observer: observer
    });
  }

  public setShouldEmitError(emitError: boolean) {
    this._shouldEmitError = emitError;
  }

  private attachEvents() {
    this._hubEvents.forEach(a => {
      this._connection.off(a.name);

      this._connection.on(a.name, evt => {
        a.observer.next(evt);
      });
    });
  }

  private publishConnectionState() {
    this.didConnectionStateChange.next(this._connection.state);
  }

  private processError(e: any): LvError {
    if (typeof(e) === 'string') {
      return new LvApplicationError(e);
    }

    const error = {
      name: this._name,
      message: e.message
    } as LvError;

    if (e instanceof LvError) {
      error.type = e.type;
    }

    if (e.statusCode === 401) {
      error.type = LvErrorType.FORBIDDEN;
    }

    if (e.statusCode === 404) {
      error.type = LvErrorType.NOT_FOUND;
    }

    if (e.statusCode === 500) {
      error.type = LvErrorType.APPLICATION;
    }

    if (e.statusCode === 503) {
      error.type = LvErrorType.CONNECTION;
    }

    this._logger.logError(error);

    if (this._shouldEmitError) {
      this.didError.next(error);
    }

    return error;
  }

  private createConnection(options: ILvHubOptions) {
    this._connection = new HubConnectionBuilder()
      .withUrl(options.url, {
        accessTokenFactory: options.accessTokenFactory || this.defaultAccessTokenFactory.bind(this),
        logger: options.logger || LogLevel.None,
        transport: options.transport || HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this._connection.onclose((error?: Error) => {
      if (!error) {
        error = {
          name: this._name,
          message: LvDataMaster.getError('dM-1917'),
          statusCode: 503
        } as Error;
      }

      this.processError(error);

      this.publishConnectionState();
    });

    this._connection.onreconnected((connectionId?: string) => {
      if (connectionId) {
        this._logger.log(`Client ${connectionId} reconnected`);
      }

      this.publishConnectionState();
    });

    this._connection.onreconnecting((error?: Error) => {
      if (error) {
        this.processError(error);
      }

      this.publishConnectionState();
    });
  }

  private async defaultAccessTokenFactory(): Promise<string> {
    let accessToken = null;

    try {
      const tokenResponse = await refreshAccessToken(this._httpClient);
      accessToken = tokenResponse.accessToken;
    }
    catch (error) {
      this.didError.next(error);
      this.didConnectionStateChange.next(HubConnectionState.Disconnected);
    }

    return accessToken;
  }

  /**
   * If promise still hanging or if connection is subscribed to events, cancel promise after one second and logout user.
   * @param timeout 
   */
  private async stopConnectionWithTimeout(timeout: number = 1000): Promise<void> {
    const stopPromise = this._connection.stop();
    const timeoutPromise = new Promise<void>((_, reject) => 
      setTimeout(() => reject(new Error('Timeout while stopping connection')), timeout)
    );
  
    await Promise.race([stopPromise, timeoutPromise]);
  }
}
