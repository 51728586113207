import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { AccruedOnDefaultDescription, CreditSource, CreditSourceDescription, DateAdjustmentDescription } from '@lv-analytics/models';
import { ICdsCreditSectionSettings } from '@lv-analytics/models/cds/market-data/market-data-cds-credit-settings/market-data-cds-credit-section-settings';
import { LvLookupEnum } from '@lv-core-ui/util';

@Component({
  selector: 'lv-cds-credit-section-settings',
  templateUrl: './lv-cds-credit-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCdsCreditSectionSettingsComponent {
  @Input() settings: ICdsCreditSectionSettings;
  @Input() overrideSystemDefaults: boolean;

  creditSource: LvLookupEnum;
  badDayConvention: LvLookupEnum;
  accruedOnDefault: LvLookupEnum;

  numberOfDecimals = '4';
  numberFormat = '#.####';

  constructor() {
    this.creditSource = new LvLookupEnum(CreditSourceDescription);
    this.badDayConvention = new LvLookupEnum(DateAdjustmentDescription);
    this.accruedOnDefault = new LvLookupEnum(AccruedOnDefaultDescription);

    this.creditSource.setFilterFn(item => {
      if (item.id === CreditSource.TermStructure || item.id === CreditSource.FlatSpread) {
        return false;
      }
      return true;
    });

    this.overrideSystemDefaults = true;
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return false;
  }
}
