import { Injectable } from '@angular/core';
import { IBondInterestRate } from './bond-interest-rates';
import { ITermStructureItem } from '@lv-analytics/models/market-data';
import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';
import { ISaveBondInterestRatesDataRequest } from './save-bond-interest-rates';

/**
 * Credit mapper.
 */
@Injectable()
export class BondInterestRatesMapper {
/**
 * Maps save request to API.
 * @param interestRatesSaveRequest IInterestRatesRequest object.
 * @returns IInterestRatesRequest object.
 */
  mapSaveRequestToApi(interestRatesSaveRequest: ISaveBondInterestRatesDataRequest): IBondInterestRate {
    return this.mapInterstRatesToApi(interestRatesSaveRequest.interestRates);
  }

  mapInterstRatesToApi(interestRates: IBondInterestRate): IBondInterestRate{
    return {
        ... interestRates,
        instrumentTermsStructure: this.mapInstrumentTermsStructureToApi(interestRates.instrumentTermsStructure)
    } as IBondInterestRate
  }

  mapInstrumentTermsStructureToApi(interestRatesTermStructure: ITermStructureItem[]): ITermStructureItem[] {
    const interestRatesScheduleForApi: ITermStructureItem[] = [];

    interestRatesTermStructure?.forEach(item => {
        const scheduleItem = {
            ... item,
            updatedRateDate: DateExtensions.getTimeWithOffset(LvDateUtil.toUtcDate(new Date(item.updatedRateDate)))
        }
        interestRatesScheduleForApi.push(scheduleItem);
    });

    return interestRatesScheduleForApi;
  }

  mapInterstRatesToUI(interestRates: IBondInterestRate): IBondInterestRate{
    return {
        ... interestRates,
        instrumentTermsStructure: this.mapInstrumentTermsStructureToUI(interestRates.instrumentTermsStructure)
    }
  }

  mapInstrumentTermsStructureToUI(interestRatesTermStructure: ITermStructureItem[]): ITermStructureItem[] {
    const interestRatesScheduleForApi: ITermStructureItem[] = [];

    interestRatesTermStructure?.forEach(item => {
        const scheduleItem = {
            ... item,
            updatedRateDate: LvDateUtil.parse(new Date(item.updatedRateDate))
        }
        interestRatesScheduleForApi.push(scheduleItem);
    });

    return interestRatesScheduleForApi;
  }}
