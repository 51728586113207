import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { IInterestRatesSectionSettings } from '@lv-analytics/models/market-data-settings/interest-rates/interest-rates-section-settings';
import { IInterestRatesSettings } from '@lv-analytics/models/market-data-settings/interest-rates/interest-rates-settings';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';

@Component({
  selector: 'lv-cds-market-data-interest-rates-settings',
  templateUrl: './lv-cds-market-data-interest-rates-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCdsMarketDataInterestRatesSettingsComponent {
    @Input() settings: IInterestRatesSettings;
    @Input() systemYieldCurvesSettingsData: ISystemYieldCurvesSettingsView;
  
    constructor() {
      this.settings = {
        systemDefaults: {
          yieldCurveSettings: []
        } as IInterestRatesSectionSettings,
        environments: []
      } as IInterestRatesSettings;
  
      this.systemYieldCurvesSettingsData = {} as ISystemYieldCurvesSettingsView;
    }
  
    /**
     * Retrieves element's id
     * @param element reference to given element
     * @param id element's id
     * @returns falg- true if element with given id is found
     */
     getTootlipId(element: ElementRef<HTMLElement>, id: string): boolean {
      return element.nativeElement.getAttribute('data-tooltip-id') === id;
    }
  
    @HostBinding('class.lv-interest-rates-settings')
    get isLvInterestRatesSettingsComponent() {
      return true;
    }
}
