import { Injectable } from '@angular/core';
import { LvDateUtil } from '@lv-core-ui/util';
import { IBondCallAdjustmentModel, IBondModelCustomization } from './bond-model-customization';

/**
 * Model customization mapper.
 */
@Injectable()
export class BondModelCustomizationMapper {

  /**
   * mapModelCustomizationToApi - helper function for mapping ModelCustomization UI model to API.
   * Using special helper function for Date fields from LvDateUtil library wich convert Date field toISOString.
   * @param item IModelCustomization object.
   */
  mapModelCustomizationToApi(item: IBondModelCustomization) {
    const request = {
      ...item,
      maturityDateOverride: item.maturityDateOverride ? LvDateUtil.toUtcDate(new Date(item.maturityDateOverride)) : null,
      callAdjustmentModelSettings: this.mapCallAdjustmentToApi(item.callAdjustmentModelSettings)
    } as IBondModelCustomization;

    request.callAdjustmentModelSettings = this.mapCallAdjustmentToApi(item.callAdjustmentModelSettings);

    return request;
  }

  /**
   * mapModelCustomizationToUi - helper function for mapping ModelCustomization API model to UI.
   * Using special helper function for Date fields from LvDateUtil library wich parse Date field.
   * @param item IModelCustomization object.
   */
  mapModelCustomizationToUi(item: IBondModelCustomization) {
    const request = {
      ...item,
      maturityDateOverride: item.maturityDateOverride ? LvDateUtil.parse(item.maturityDateOverride) : null,
    } as IBondModelCustomization;

    request.callAdjustmentModelSettings = this.mapCallAdjustmentToUi(item.callAdjustmentModelSettings);

    return request;
  }

  /**
   * mapCallAdjustmentToApi - helper function for mapping CallAdjustmentModel API model to UI.
   * Using special helper function for Date fields from LvDateUtil library wich parse Date field.
   * @param item ICallAdjustmentModel object.
   */
  private mapCallAdjustmentToApi(item: IBondCallAdjustmentModel): IBondCallAdjustmentModel {
    const request = {
      ...item,
      notCallableDate: item.notCallableDate ? LvDateUtil.toUtcDate(new Date(item.notCallableDate)) : null
    } as IBondCallAdjustmentModel;

    return request;
  }

  /**
   * mapCallAdjustmentToUi - helper function for mapping CallAdjustmentModel API model to UI.
   * Using special helper function for Date fields from LvDateUtil library wich parse Date field.
   * @param item ICallAdjustmentModel object.
   */
  private mapCallAdjustmentToUi(item: IBondCallAdjustmentModel): IBondCallAdjustmentModel {
    const response = {
      ...item,
      notCallableDate: item.notCallableDate ? LvDateUtil.parse(item.notCallableDate) : null
    } as IBondCallAdjustmentModel;

    return response;
  }
}