import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDiscardable } from '@lv-common/common-data/models/discardable';
import { DiscardChangesService } from '@lv-common/common-data/services/discard-changes.service';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService } from '@lv-core-ui/services';
import { CDSTermsSettingsService } from '@lv-custom-instruments/services/cds-terms-settings/cds-terms-settings-service';
import _ from 'lodash';
import { ICDSTermsSettings } from 'src/app/modules/instrument/cds-terms/models/cds-terms-settings/cds-terms-settings';

@Component({
  selector: 'lv-terms-cds-settings',
  templateUrl: './lv-terms-cds-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvTermsCdsSettingsComponent implements OnInit, IDiscardable {
  @Output() startLoading: EventEmitter<void>;
  @Output() endLoading: EventEmitter<void>;
  @Output() didCancel: EventEmitter<void>;

  
  settings: ICDSTermsSettings;
  originalSettings: ICDSTermsSettings;
  constructor(private _errorService: LvErrorService,
              private _cdsTermsSettingsService: CDSTermsSettingsService, 
              private _changeDetectorRef: ChangeDetectorRef,               
              private _discardChangesService: DiscardChangesService)
  {
    this.startLoading = new EventEmitter<void>();
    this.endLoading = new EventEmitter<void>();
    this.didCancel = new EventEmitter<void>();

    this.settings = {
      my: {},
      system: {},
    } as ICDSTermsSettings

    this.originalSettings = {
      my: {},
      system: {},
    } as ICDSTermsSettings
  }

  @HostBinding('class.lv-flex-box')
  get isFlexComponent() {
    return true;
  }

  @HostBinding('class.lv-flex-box--column')
  get isFlexColumnComponent() {
    return true;
  }

  @HostBinding('class.lv-terms-cds-settings')
  get isLvTermsCdsSettingsComponent() {
    return true;
  }

  ngOnInit(): void 
  { 
    this.setSettings();

    this._discardChangesService.register('Terms', this);
  }
  
  /**
   * Gets Real CDS terms settings from backend.
   */
  async setSettings()
  {
    try{
      this.startLoading.next();
      this.settings = (await this._cdsTermsSettingsService.getCDSTermsSettings());
      this.originalSettings = _.cloneDeep(this.settings);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on save and saves CDS term settings.
   */
  async onSave()
  {
    if (!this.isChanged()) {
      return;
    }

    try {
      this.startLoading.next()
      await this._cdsTermsSettingsService.saveCDSTermsSettings(this.settings);
      this.originalSettings = _.cloneDeep(this.settings);
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-4751',
      {'settings_section': 'Terms'}));
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on cancel.
   */
  async onCancel()
  {
    this.didCancel.next();
  }

  /**
   * 
   * @returns Method checks if any changes in state happened.
   */
  isChanged(): boolean {
    return !_.isEqual(this.settings, this.originalSettings);
  }

  /**
   * Resets CDS settings to default.
   */
  async onResetToDefault()
  {
    try {
      this.startLoading.next();
      this.settings = (await this._cdsTermsSettingsService.resetCDSTermsSettings());
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1816',
      {'settings_section': 'Terms'}));
    } 
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.endLoading.next();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Method discards current changes in state that are not saved.
   */
  discardChanges(): void {
    this.settings = _.cloneDeep(this.originalSettings);
    this._changeDetectorRef.detectChanges();
  }
}
