import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IInterestRatesSectionSettings, InterestRateSource, InterestRateSourceDescription, YieldCurveSource } from '@lv-analytics/models';
import { ISystemYieldCurvesSettingsView } from '@lv-analytics/models/market-data-settings/interest-rates/system-yield-curves-settings-view';
import { IYieldCurveSettings } from '@lv-analytics/models/market-data-settings/interest-rates/yield-curve-settings';
import { LvAdvancedGridColumn, LvAdvancedGridListColumn } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid';
import { LvAdvancedGridComponent } from '@lv-core-ui/components/lv-advanced-grid/lv-advanced-grid.component';
import { ILookupItem } from '@lv-core-ui/models/lookup-item';
import { LvError } from '@lv-core-ui/models/lv-error/base';
import { LvErrorService } from '@lv-core-ui/services/lv-error/lv-error.service';
import { LvLookupEnum } from '@lv-core-ui/util/lv-lookup-enum';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid/editing-directives/create-form-group-args.interface';

@Component({
  selector: 'lv-cds-interest-rates-section-settings',
  templateUrl: './lv-cds-interest-rates-section-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCdsInterestRatesSectionSettingsComponent implements OnInit, OnChanges {
   @ViewChild(LvAdvancedGridComponent, { static: true }) advancedGrid: LvAdvancedGridComponent;
  
    @Input() settings?: IInterestRatesSectionSettings;
    @Input() overrideSystemDefaults?: boolean;
    @Input() systemYieldCurvesSettingsData: ISystemYieldCurvesSettingsView;
  
    get isYieldCurveSourceDisabled(): boolean {
      return this.settings.interestRateSource === InterestRateSource.Flat || !this.overrideSystemDefaults;
    }
  
    interestRateSource: LvLookupEnum;
    yieldCurveSource: LvLookupEnum;
  
    currencies: ILookupItem<string>[];
    yieldCurves: ILookupItem<number>[];
  
    columns: LvAdvancedGridColumn[];
    parseFn: any;
  
    currencyFilterDict: {
      [code: string]: boolean
    };
  
    private _selectedCurrency: string;
  
    constructor(
      private _errorService: LvErrorService,
      private _changeDetectorRef: ChangeDetectorRef,
        private _destroyRef: DestroyRef,
    ) {
      this.interestRateSource = new LvLookupEnum(InterestRateSourceDescription);
      this.yieldCurveSource = new LvLookupEnum(YieldCurveSource);
      this.settings = {
        yieldCurveSettings: []
      } as IInterestRatesSectionSettings;
  
      this.overrideSystemDefaults = true;
  
      this.currencies = [];
      this.yieldCurves = [];
  
      this.initColumns();
    }
  
    @HostBinding('class.lv-flex-box')
    get isFlexComponent() {
      return true;
    }
  
    @HostBinding('class.lv-flex-box--column')
    get isFlexColumnComponent() {
      return true;
    }
  
    /**
     * Handles any additional initialization tasks.
     */
    ngOnInit() {
      this.advancedGrid.didDataChange
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((records: IYieldCurveSettings[]) => this.onDataChange(records));

      this.advancedGrid.doReload
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.onReload());

      this.advancedGrid.didError
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((error: LvError) => this.onError(error));

      this.advancedGrid.didDropdownItemSelected
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((event: string) => this.onAdvancedGridDropdownChange(event));

      this.advancedGrid.rowEditing.didCloseRow
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.onRowEditingFinish());
    }
   /**
    *  Method that handles changes - fires whenever any data-bound property changes
    */
    ngOnChanges(): void {
      if (this.systemYieldCurvesSettingsData?.yieldCurveSettingsData) {
        this.yieldCurves = [];
  
        Object.values(this.systemYieldCurvesSettingsData.yieldCurveSettingsData).map((x: any) => {
          x.forEach(element => {
            this.yieldCurves.push({
              id: element.id,
              text: element.name + ' - ' + element.leversysId
            } as ILookupItem<number>);
          });
        });
        this.currencies = Object.keys(this.systemYieldCurvesSettingsData.yieldCurveSettingsData).map(x => {
            return {
              id: x.toUpperCase(),
              text: x.toUpperCase()
            } as ILookupItem<string>;
          });
  
        if (this.yieldCurves.findIndex(x => x.id === -1) < 0) {
            this.yieldCurves.push({
              id: -1,
              text: 'Custom'
            } as ILookupItem<number>);
          }
        this.initColumns();
        this.setCurrencyFilterDictionary(this.settings.yieldCurveSettings);
        this._changeDetectorRef.detectChanges();
      }
    }
  
    /**
     * Occurs on data change.
     * @param records List of IYieldCurveSettings objects.
     */
    onDataChange(records: IYieldCurveSettings[]) {
      this.applyRecords(records);
      this.setCurrencyFilterDictionary(records);
    }
  
    /**
     * Occurs on advance grid dropdown change.
     * @param event Event.
     */
    onAdvancedGridDropdownChange(event: string) {
      if (this.currencies.findIndex(x => x.id === event) > -1) {
        this._selectedCurrency = event;
      }
    }
  
    /**
     * Occurs on row editing finish.
     */
    onRowEditingFinish() {
      this._selectedCurrency = null;
    }
  
    /**
     * Applies advanced grid changes.
     */
    applyAdvancedGridChanges() {
      this.advancedGrid.applyChanges(records => this.applyRecords(records));
    }
  
    /**
     * Occurs on reload.
     */
    onReload() {
      this.settings.yieldCurveSettings = this.settings.yieldCurveSettings.map(x => ({ ...x }));
    }
  
    /**
     * Handles error.
     * @param error LvError object.
     */
    onError(error: LvError) {
      this._errorService.handleError(error);
    }
  
    /**
     * Create form group.
     * @param args CreateFormGroupArgs object.
     * @returns FormGroup object.
     */
    createFormGroup(args: CreateFormGroupArgs): FormGroup {
      return new FormGroup({
        'currencyCode': new FormControl(args.dataItem.currencyCode, Validators.required),
        'yieldCurveId': new FormControl(args.dataItem.yieldCurveId, Validators.required)
      });
    }
  
    /**
     * Applies records.
     * @param records List of records.
     */
    private applyRecords(records: any[]) {
      if (!this.settings.yieldCurveSettings) {
        this.settings.yieldCurveSettings = [];
      }
      this.settings.yieldCurveSettings.splice(0, this.settings.yieldCurveSettings.length);
      this.settings.yieldCurveSettings.push(...records);
      this._selectedCurrency = null;
    }
  
    /**
     * Columns initialization.
     */
    private initColumns() {
      this.columns = [];
      const currencyCodeColumn = new LvAdvancedGridListColumn();
      currencyCodeColumn.title = '';
      currencyCodeColumn.field = 'currencyCode';
      currencyCodeColumn.width = 80;
      currencyCodeColumn.displayField = 'text',
      currencyCodeColumn.valueField = 'id',
      currencyCodeColumn.valuePrimitive = true,
      currencyCodeColumn.editable = this.overrideSystemDefaults;
      currencyCodeColumn.data = this.currencies;
      currencyCodeColumn.dmKey = 'DM-2255';
      currencyCodeColumn.setFilterFn((b: ILookupItem<string>) => {
  
        const rowIndex = (this.advancedGrid.grid as any).editService.editedIndices[0]?.index ?? 0;
  
        if (rowIndex < this.settings.yieldCurveSettings.length) {
          return b.id === this.settings.yieldCurveSettings[rowIndex].currencyCode;
        }
  
        if (this.currencyFilterDict) {
          return !this.currencyFilterDict[b.id];
        }
  
        return true;
      });
  
      const yieldCurveNameColumn = new LvAdvancedGridListColumn();
      yieldCurveNameColumn.title = '';
      yieldCurveNameColumn.field = 'yieldCurveId';
      yieldCurveNameColumn.width = 250;
      yieldCurveNameColumn.displayField = 'text',
      yieldCurveNameColumn.valueField = 'id',
      yieldCurveNameColumn.valuePrimitive = true,
      yieldCurveNameColumn.editable = this.overrideSystemDefaults;
      yieldCurveNameColumn.data = this.yieldCurves;
      yieldCurveNameColumn.dmKey = 'DM-2256';
      yieldCurveNameColumn.setFilterFn((b: ILookupItem<number>) => {
        let selectedCurrency = null;
  
        const rowIndex = (this.advancedGrid.grid as any).editService.editedIndices[0]?.index ?? 0;
  
        if (rowIndex !== null && rowIndex !== undefined && this.settings.yieldCurveSettings[rowIndex]) {
          selectedCurrency = this.settings.yieldCurveSettings[rowIndex].currencyCode;
        }
        else {
          selectedCurrency = this._selectedCurrency;
        }
  
        if (selectedCurrency) {
          if (b.id === -1) {
            return true;
          }
  
          if (this.systemYieldCurvesSettingsData.
            yieldCurveSettingsData[selectedCurrency.toLowerCase()].
            findIndex(x => x.name + ' - ' + x.leversysId === b.text) > -1) {
              return true;
          }
        }
  
        return false;
      });
      this.columns.push(currencyCodeColumn);
      this.columns.push(yieldCurveNameColumn);
    }
  
    /**
     * Sets currency filter dictrionary.
     * @param currencies List of currencies.
     */
    private setCurrencyFilterDictionary(currencies) {
      this.currencyFilterDict = {};
      currencies.forEach(a => this.currencyFilterDict[a.currencyCode] = true);
    }
}
