import { inject, Injectable } from '@angular/core';
import { LvLocalization } from '@lv-core-ui/util';
import { DatePipe } from '@progress/kendo-angular-intl';

/**
 * Service for date manipulation.
 */
@Injectable({
  providedIn: 'root'
})
export class LvDateService {

  private _datePipe = inject(DatePipe);

  constructor() { }

  /**
   * Create UTC date from regular date.
   * @param date Date value.
   * @returns Date value in UTC format.
   */
  getUtcDateValue(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const dateUtcValue = new Date(Date.UTC(year, month, day));
  
    return dateUtcValue;
  }

  /**
   * Converts a given date to UTC.
   * @param date The date to convert to UTC.
   * @returns The date in UTC format.
   */
  getUtcDate(date: Date): Date {
    const utcTimestamp = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    );

    return new Date(utcTimestamp);
  }

  /**
  * Formats a given date to locale date and time based on provided options.
  * @param withTime Specifies whether to include time in the formatted string.
  * @param value The date to format.
  * @returns The formatted date string.
  */
  formatToLocaleDateTime(withTime: boolean, value: any): string {
    const date = new Date(value);

    if (isNaN(date.getTime())) {
        // Handle invalid date input as needed
        return '';
    }

    return this._datePipe.transform(date, null, LvLocalization.getBrowserLocale());
  }

  /**
  * Calculates the difference in days between two dates.
  * @param firstDate The first date.
  * @param secondDate The second date.
  * @returns The difference in days.
  */
  getDifferenceInDaysBetweenTwoDates(firstDate: number | Date, secondDate: number | Date): number {
    const fromDate = new Date(firstDate);
    const toDate = new Date(secondDate);

    // Convert both dates to UTC to ensure consistent comparison
    const utcFrom = Date.UTC(
        fromDate.getUTCFullYear(),
        fromDate.getUTCMonth(),
        fromDate.getUTCDate(),
        0, 0, 0, 0
    );
    const utcTo = Date.UTC(
        toDate.getUTCFullYear(),
        toDate.getUTCMonth(),
        toDate.getUTCDate(),
        0, 0, 0, 0
    );

    // Calculate the difference in days
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    return Math.floor((utcTo - utcFrom) / millisecondsInDay);
  }

  /**
  * Gets the end of the day for a given date.
  * @param date The date.
  * @returns The end of the day for the given date.
  */
  getEndOfDate(date: Date): Date {
    const endOfDay = new Date(date);
    
    // Set the time to the end of the day (23:59:59.999)
    endOfDay.setHours(23, 59, 59, 999);
    
    return endOfDay;
  }

  /**
  * Adds specified number of days to a given date.
  * @param date The base date.
  * @param addDays The number of days to add.
  * @returns The new date after adding days.
  */
  addDatesToDate(date: Date, addDays: number): Date {
    const resultDate = new Date(date);
    
    // Add the specified number of days
    resultDate.setDate(resultDate.getDate() + addDays);

    return resultDate;
  }

  /**
  * Gets the start of the day for a given date.
  * @param date The date.
  * @returns The start of the day for the given date.
  */
  getStartOfDay(date: Date): Date {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    return startOfDay;
  }

  /**
  * Checks if the first date is before the second date.
  * @param date1 The first date.
  * @param date2 The second date.
  * @returns True if the first date is before the second date, false otherwise.
  */
  isFirstDateBeforeSecond(date1: Date, date2: Date): boolean {
    return date1 < date2;
  }

  /**
  * Checks if the first date is after the second date.
  * @param date1 The first date.
  * @param date2 The second date.
  * @returns True if the first date is after the second date, false otherwise.
  */
  isFirstDateAfterSecond(date1: Date, date2: Date) : boolean {
    return date1 > date2;
  }

  /**
  * Gets hours and minutes in HH:mm format from a given date.
  * @param date The date.
  * @returns The hours and minutes in HH:mm format.
  */
  getHoursAndMinutes(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  /**
  * Checks if a value is a Date object.
  * @param value The value to check.
  * @returns True if the value is a Date object, false otherwise.
  */
  isData(value: any): boolean {
    return value instanceof Date;
  }
}
