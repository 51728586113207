<lv-section-settings [settings]="settings"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="estimatesOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]"
    [dataCy]="'DM-1155'">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-estimates-section-settings [settings]="systemSettings"></lv-estimates-section-settings>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-estimates-section-settings
      [settings]="settings"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-estimates-section-settings>
  </ng-template>
</lv-section-settings>
<lv-flex-box class="lv-settings-footer" direction="row">
  <button kendoButton
    [primary]="true"
    lvId="cancel-estimates-settings"
    (click)="onCancel()">
    Cancel
  </button>
  <div class="lv-settings-footer-divider">&nbsp;</div>
  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-estimates-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    [class.save-settings-btn-disabled]="!isChanged()"
    lvId="save-estimates-settings"
    (click)="onSave()">
    Save
  </button>
</lv-flex-box>

<ng-template #estimatesOverrideSystemDefaultsSettingsTooltip>
    <lv-tooltip dm="dM-1155"></lv-tooltip>
</ng-template>