import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild,
  ElementRef, ViewRef, Optional, 
  DestroyRef} from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgForm } from '@angular/forms';

import { filter, Subscription } from 'rxjs';

import { DialogService } from '@progress/kendo-angular-dialog';

import { LvPricingView } from './lv-pricing.view';

import { LvEstimatesHistoryDialogComponent } from './lv-estimates-history-dialog/lv-estimates-history-dialog.component';
import { LvEnvironmentSettingsComponent, IEnvironmentSettingsItem } from '../lv-environment-settings/lv-environment-settings.component';
import { LvPricingUtil } from './lv-pricing.util';
import { LvPricingSidePanelComponent } from './lv-pricing-side-panel/lv-pricing-side-panel.component';
import { LvNewIssueSettingsDialogComponent } from './lv-new-issue-settings-dialog/lv-new-issue-settings-dialog.component';
import { LvEstimatesSettingsDialogComponent } from './lv-estimates-settings-dialog/lv-estimates-settings-dialog.component';
import { LvBaseWidgetComponent } from '../../lv-base-widget.component';
import { LvSettingsWidgetComponent } from '@lv-core-ui/components';
import { LvDateService, LvErrorService, ResizeHandlerService } from '@lv-core-ui/services';
import { LvFormUtil, LvUtil, constants } from '@lv-core-ui/util';
import {
  IPricingWidgetState, ILoadPricingResponse, ConvertibleSubType, AnalyticsCommands, AnalyticsEvents, AnalyticsSettingsEvents,
  CrEstimateCurrency, IAnalyticsSettings, PricingEnvironmentSections, ConvertibleSetupStatus
} from '@lv-analytics/models';
import { PricingService, ValuationSessionService } from '@lv-analytics/services';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { LvExcelSaveModalComponent } from '@lv-excel/components/lv-excel-save-modal/lv-excel-save-modal.component';
import { PricingExcelMapper } from '@lv-analytics/helpers/pricing-mapper';
import { FlatMarketDataExcelMapper } from '@lv-analytics/helpers/flat-market-data-mapper';
import { LvExcelService } from '@lv-excel/services';
import { IConstants, LvDataMaster } from '@lv-core-ui/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';

/**
 * Pricing section event source.
 */
export enum PricingSectionEventSource {
  Analytics = 'Analytics',
  SensitivityAnalysis = 'SensitivityAnalysis'
}

export type Direction = 'row' | 'column';

/**
 * This component is responsible for manipulating pricing data.
 */
@Component({
  selector: 'lv-pricing',
  templateUrl: './lv-pricing.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvPricingComponent extends LvBaseWidgetComponent<IPricingWidgetState> {

  @ViewChild('pricingWidget', { static: true }) pricingWidget: ElementRef;
  @ViewChild('valuationInputsForm', { static: true }) valuationInputsForm: NgForm;
  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild(LvSettingsWidgetComponent, { static: true }) settings: LvSettingsWidgetComponent;

  @Input() eventSource: PricingSectionEventSource;

  get currentEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  get isWarrningVisible(): boolean {
    return this.currentWarrnings && this.currentWarrnings.length > 0 ? true : false;
  }

  get dateFormated(): string {
    if (!(this.view && this.view.viView && this.view.viView.model && this.view.viView.model.valuationDate)) {
      return '';
    }

    return new Date(this.view.viView.model.valuationDate).toLocaleDateString('en-GB');
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  get isCrossFxPopulated(): boolean {
    if (this.view
      && this.view.viView
      && this.view.viView.model
      && this.view.asHelper
      && this.view.asHelper.convertible) {
      return !!this.view.viView.model.crossFx && this.view.asHelper.convertible.isCrossFx;
    }

    return false;
  }

  get isCrossFx(): boolean {
    return !!this.view.asHelper?.convertible?.isCrossFx;
  }

  get valuationInputsPrice(): string {
    return  `valuationInputsPrice${this.eventSource}`;
  }

  get valuationInputsStockPriceCbCcy(): string {
    return `valuationInputsStockPriceCbCcy${this.eventSource}`;
  }

  get valuationInputsStockPriceUndCcy(): string {
    return `valuationInputsStockPriceUndCcy${this.eventSource}`;
  }

  get valuationInputsParity(): string {
    return `valuationInputsParity${this.eventSource}`;
  }

  get valuationInputsValuationDate(): string {
    return  `valuationInputsValuationDate${this.eventSource}`;
  }

  get valuationInputsCrossFX(): string {
    return  `valuationInputsCrossFX${this.eventSource}`;
  }

  get newIssueAssumptionButton(): string {
    return  `newIssueAssumptionButton${this.eventSource}`;
  }

  get lvPricingEnvironmentSettings(): string {
    return `lvPricingEnvironmentSettings${this.eventSource}`;
  }

  get valuationInputsUpsideVol(): string {
    return `valuationInputsUpsideVol${this.eventSource}`;
  }

  get valuationInputsDownsideVol(): string {
    return `valuationInputsDownsideVol${this.eventSource}`;
  }

  view: LvPricingView;
  resizeHandlerView: LvPricingUtil;
  isLoading: boolean;
  crEstimateCurrencyEnum = CrEstimateCurrency;
  pricingSection = PricingEnvironmentSections.Pricing;
  currentWarrnings: string[];
  constants: IConstants;

  private _subscriptions: Subscription[];

  public pricingMapper: PricingExcelMapper;
  public flatMarketDataMapper: FlatMarketDataExcelMapper;

  constructor(
    public decimalPipe: DecimalPipe,
    public datePipe: DatePipe,
    public widgetStateManagerService: WidgetStateManagerService,
    public changeDetectorRef: ChangeDetectorRef,
    private _dialogService: DialogService,
    private _errorService: LvErrorService,
    private _service: ValuationSessionService,
    private _presenter: LvAnalyticsPresenter,
    private _resizeHandlerService: ResizeHandlerService,
    private _pricingService: PricingService,
    private _lvDateService: LvDateService,
    private _destroyRef: DestroyRef,
    @Optional() private _excelSvc: LvExcelService
  ) {

    // tslint:disable-next-line: max-line-length
    super(changeDetectorRef, widgetStateManagerService,  getPricingInitialState(!!_excelSvc?.isInitialized()), DefaultWidgetType.Pricing);

    this.eventSource = PricingSectionEventSource.Analytics;
    this.view = new LvPricingView(decimalPipe, datePipe, this._excelSvc, _lvDateService);
    this.currentWarrnings = null;

    this.pricingMapper = new PricingExcelMapper();
    this.flatMarketDataMapper = new FlatMarketDataExcelMapper();
    this.constants = constants;
  }

  /**
   * Initializes pricing component. Subscribes to all events relevant for pricing component.
   *
   * One of the subscribed events is used to trigger calucaltion and send log request XMl flag.
   */
  onInit() {
    this.resizeHandlerView = new LvPricingUtil(this._resizeHandlerService,
      this._changeDetectorRef,
      this.pricingWidget,
      !!this._excelSvc?.isInitialized());

    if (this._presenter.isConvertibleModelLoaded()) {
      this.init(this._presenter.getModelData());
    }
    else {
      this.resizeHandlerView.init(true);
    }

    this._subscriptions = [
      this._presenter.onModelLoading.subscribe(isLoading => this.setLoadingState(isLoading)),

      this._presenter.onAnalyticsSettingsUpdated.subscribe(evt => {
        if (evt) {
          this.view.updateConvertible(this._presenter.cHelper.convertible);
          this.init(this._presenter.getModelData());
          this.resizeHandlerViewCallInit();
        }
        else {
          this.init(null);
        }

        this.currentWarrnings = null;
        this._changeDetectorRef.detectChanges();
      }),

      this._presenter.onModelUpdated.subscribe(evt => {
        if (evt) {

          if (evt.isModelCustomizationEvent()) {
            this.recalculateParity(evt.data.valuationSession.modelCustomization.includeCashRebateInParity);
          }

          if (evt.isMarketDataEvent() && evt.data.marketData) {
            this.view.applyMarketData(evt.data.marketData);
          }

          this.resizeHandlerViewCallInit();

          if (!(this._changeDetectorRef as ViewRef).destroyed) {
            this._changeDetectorRef.detectChanges();
          }
        }
      }),

      this._presenter.onPricingModelUpdated.subscribe(evt => {
        if (evt && evt.source !== this.eventSource) {
          this.view.applyPricing(
            evt.data.pricing.crEstimateSettings,
            evt.data.viInputs,
            evt.data.pricing.newIssueAssumptions);
          this.envSettings.setItems(evt.data.environments);
        }

        this.resizeHandlerViewCallInit();

        if (!(this._changeDetectorRef as ViewRef).destroyed) {
          this._changeDetectorRef.detectChanges();
        }
      }),

      this._presenter.onEventPublished.subscribe(evt => {
        if (evt.eventId === AnalyticsEvents.SendValuationQueryStarted) {
          this.calculateValuation(true, evt.data);
        }

        this._changeDetectorRef.detectChanges();
      }),
    ];

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event !== TermsChangedEvent.Other))
      .subscribe(async event => {
        this.view.updateConvertible(this._presenter.cHelper.convertible);
        this.init(this._presenter.getModelData());
        this.resizeHandlerViewCallInit();
        this._changeDetectorRef.detectChanges();
      });

    this.checkCrEffectiveDate();

    if (this._presenter.isExcelDataLoaded && this.eventSource === PricingSectionEventSource.Analytics) {
      this.updatePricingModel();
      this.valuationInputsForm.control.markAsDirty();
      setTimeout(() => {
        this.performCalculation();
      }, 1);
    }

    this.currentWarrnings = null;
  }

  /**
   * Performs calculation.
   */
  async performCalculation() {
    if (!this._presenter.isPrivateInstrument) {
      if (!!this._excelSvc?.containsField('PRICE_TALK')) {
        await this._service.overrideNewIssueAssumptions(this.view.getNiaRequest(this.currentEnvironmentId));
      }
      if (!!this._excelSvc?.getMappedFields('EST_USE')) {
        await this._service.overrideCrEstimate(this.view.getCpeRequest(this.currentEnvironmentId, this._pricingService.instanceId));
      }
    }

    await this.calculateValuation(false, '');
  }

  /**
   * Checks if valuation inputs are valid.
   * @returns A flag indicating if valuation inputs are valid.
   */
  isValid(): boolean {
    let valid = false;

    if (this.valuationInputsForm.valid) {
      valid = true;
    }
    else {
      LvFormUtil.markAllControlsAsTouched(this.valuationInputsForm);
    }

    this._changeDetectorRef.detectChanges();
    return valid;
  }

  /**
   * Executes calculation if all required conditions are meet.
   * @param {boolean} logRequestXml true if user triggered Send Valuation Query, false otherwise
   * @param {string} valuationQueryNotes notes that user entered on triggering Send Valuation Query
   */
  async calculateValuation(logRequestXml: boolean, valuationQueryNotes: string) {
    try {
      if (!this.view.asHelper.instrumentLoaded || this.eventSource === PricingSectionEventSource.SensitivityAnalysis) {
        return;
      }

      if (this.isValid()) {
        this.setLoadingState(true);

        this._presenter.publishEvent({
          eventId: AnalyticsEvents.ValuationStarted,
          data: null
        });

        const result = await
          this._service.calculateValuation(this.view.getConvertibleValuation(logRequestXml,
            valuationQueryNotes,
            this._presenter.draftId));

        this._presenter.publishEvent({
          eventId: AnalyticsEvents.ValuationCompleted,
          data: result
        });

        if (result.warning) {
          this.currentWarrnings = result.warning;
        }
        else {
          this.currentWarrnings = null;
        }
      }
    }
    catch (error) {
      this._errorService.handleError(error);
      this.currentWarrnings = null;
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Cheks if field is from Excel.
   * @param alias Alias.
   * @returns A flag indicating if field is from Excel.
   */
  isFromExcel(alias: string) {
    return this._presenter.IsFieldFromExcel(alias);
  }

  /**
   * Loads pricing section.
   */
  async onReloadSection() {
    await this.loadPricing(this.envSettings.getSelectedEnvironment());
  }

  /**
   * Occurs on environment change and loads pricing.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangedEnvironment(environment: IEnvironmentSettingsItem) {
    this.loadPricing(environment);
  }

  /**
   * Occurs on environments reorder and updates pricing model.
   * @param environments List of IEnvironmentSettingsItem object.
   */
  onReorderEnvironments(environments: IEnvironmentSettingsItem[]) {
    this.updatePricingModel();
  }

  /**
   * Loads pricing data
   * @param {IEnvironmentSettingsItem} environment pass environment settings
   */
  async loadPricing(environment: IEnvironmentSettingsItem) {
    try {
      if (!this.view.asHelper.valuationSession) {
        return;
      }

      this.setLoadingState(true);

      const response = await this._service.loadPricing(
        this.view.asHelper.valuationSession.sessionId,
        this.view.asHelper.valuationSession.lwsIdentifier,
        environment.id,
        this._presenter.draftId
      ) as ILoadPricingResponse;

      // tslint:disable-next-line: max-line-length
      /* TODO there is a duplicated code in analytics preseneter so that code could probably be moved in this component and private method created */

      if (!!this._excelSvc?.isInitialized()) {

        this.flatMarketDataMapper.init(response.marketDataBase);
        this.flatMarketDataMapper.mapp(this._excelSvc.getFlatMarketDataFields());

        this.pricingMapper.init(response.pricingSettings);
        this.pricingMapper.setIsCrossFx(this.view.asHelper.convertible.isCrossFx);
        this.pricingMapper.setIsPeps(this.view.asHelper.convertible.subType === ConvertibleSubType.PEPS);
        this.pricingMapper.setIsCrossFxOverridden(this._excelSvc.getPricesDataFields())
        this.pricingMapper.setIsConvertableDeltaNeutral(!!this.view.asHelper.convertible?.isDeltaNeutral);
        this.pricingMapper.setIsConvertableDetachable(!!this.view.asHelper.convertible
          && this.view.asHelper.convertible.subType === ConvertibleSubType.ConvertibleWithDetachableWarrant);
        this.pricingMapper.mapp(this._excelSvc.getPricesDataFields());
      }


      this._presenter.updateModel({
        eventId: AnalyticsSettingsEvents.PricingEnvironmentUpdated,
        data: {
          section: PricingEnvironmentSections.Pricing,
          environmentId: environment.id
        }
      });

      this.view.loadPricing(response);

      this.updatePricingModel();

    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Saves pricing.
   */
  async onSaveSection() {
    try {
      this._presenter.validateBeforeSave();
      this.setLoadingState(true);

      if (this.valuationInputsForm.valid) {
        const savePricingReq = this.view.getSavePricingRequest(this.currentEnvironmentId);
        savePricingReq.leversysLocalId = this._presenter.leversysLocalId;
        await this._pricingService.savePricing(savePricingReq);
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388', {'value': 'Pricing'}));
      }
      else {
        LvFormUtil.markAllControlsAsTouched(this.valuationInputsForm);
      }
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  // Conversion Price Estimate
  /**
   * Occurs on estimated ratio change.
   */
  async onEstimatedRatioChange() {
    try {
      this.setLoadingState(true);

      this.view.viView.onEsimateRatioChanged(this.view.cpeView.model);
      await this._service.overrideCrEstimate(this.view.getCpeRequest(this.currentEnvironmentId, this._pricingService.instanceId));

      this.updateModel();
      this.updatePricingModel();

      this.calculateValuation(false, '');
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on show conversion price or ratio.
   * @param showConversionPrice A flag indicating if conversion price should be shown.
   */
  async onShowConversionPriceOrRatio(showConversionPrice: boolean) {
    try {
      this.setLoadingState(true);

      this.view.cpeView.setShowConversionPrice(showConversionPrice);
      await this._service.overrideCrEstimate(this.view.getCpeRequest(this.currentEnvironmentId, this._pricingService.instanceId));
      this.updatePricingModel();
      this.updateModel();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on set CR estimate currency.
   * @param currency CR estimate currency.
   */
  async onSetCrEstimateCurrency(currency: CrEstimateCurrency) {
    try {
      this.setLoadingState(true);

      this.view.cpeView.setSelectedCurrency(currency);
      await this._service.overrideCrEstimate(this.view.getCpeRequest(this.currentEnvironmentId, this._pricingService.instanceId));
      this.updatePricingModel();
      this.updateModel();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on use expected change.
   */
  async onUseExpected() {
    try {
      this.view.cpeView.toggleUseEstimatedRatio();
      await this._service.overrideCrEstimate(this.view.getCpeRequest(this.currentEnvironmentId, this._pricingService.instanceId));
      this.updatePricingModel();
      this.updateModel();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on show estimates history.
   */
  onShowEstimatesHistory() {
    const dialogRef = this._dialogService.open({
      title: 'Estimates History',
      content: LvEstimatesHistoryDialogComponent,
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-estimates-history-dialog');
    const instance = dialogRef.content.instance as LvEstimatesHistoryDialogComponent;
    instance.identifer = this.view.asHelper.valuationSession.leversysLocalId;
    instance.sessionId = this._presenter.sessionId;
  }
  // Conversion Price Estimate

  // Valuation Inputs
  /**
   * Occurs on valuation input changed and updates pricing model.
   */
  onValuationInputChanged() {
    this.updatePricingModel();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Handles stock slippage change.
   */
  onStockSlippageChanged() {
    this.onValuationInputChanged();
    this.view.init(this._presenter.getModelData(), this.widgetState);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on valuation date blur.
   */
  onValuationDateBlur() {
    if (this.view.viView.model.valuationDate) {
      this.view.viView.resetValuatonDateToSettlementDateIfOlder();
      this.onValuationDateInputChanged(this.view.viView.model.valuationDate);
      this.checkCrEffectiveDate();
    }
  }

  /**
   * Occurs on valuation date input change, updates pricing model and calculates parity for Delta neutral.
   * @param value Date value.
   */
  onValuationDateInputChanged(value: Date) {
    if (value) {
      this.updatePricingModel();
      this.view.viView.calculateParityForDeltaNeutral();
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Triggers calculation on change of relevant pricing fields.
   */
  onValuationInputKeyDown() {
    this.calculateValuation(false, '');
  }

  /**
   * Occurs on cross FX rate change and updates Stock Ref and pricing model.
   */
  onCrossFxRateChanged() {
    this.view.viView.onCrossFxChange();
    this.updatePricingModel();

    this._changeDetectorRef.detectChanges();

    this.view.init(this._presenter.getModelData(), this.widgetState);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on convertible stock price change.
   */
  onConvertibleStockPriceChanged() {
    this.view.viView.onStockPriceChange(true);
    this.updatePricingModel();

    this._changeDetectorRef.detectChanges();

    this.view.init(this._presenter.getModelData(), this.widgetState);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on underlying stock price change.
   */
  onUnderlyingStockPriceChanged() {
    this.view.viView.onStockPriceChange(false);
    this.updatePricingModel();

    this._changeDetectorRef.detectChanges();

    this.view.init(this._presenter.getModelData(), this.widgetState);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on parity change.
   */
  onParityChanged() {
    this.view.viView.onParityChange();
    this.updatePricingModel();

    this._changeDetectorRef.detectChanges();
  }

  /**
   * method is called when Incl Cash Rebate In Parity in MC is changed
   * @param includeCashRebateInParity flag for Incl Cash Rebate In Parity checkbox in MC
   */
  recalculateParity(includeCashRebateInParity: boolean) {
    this.view.viView.includeCashRebateInParity = includeCashRebateInParity;
    this.view.viView.calculateParity();
    this.updatePricingModel();

    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on radio button new issue assumption change.
   */
  async onRadioButtonNewIssueAssumptionChange(type: string) {
    await this.onNewIssueAssumptionChange();
  }

  // Valuation Inputs

  /**
   * On stock reference change.
   */
  async onNewIssueAssumptionStockRefChange() {    
    if (this.view.niaView.model.stockRefCBCcy){
      this.view.niaView.model.stockRef = null;
    }

    await this.onNewIssueAssumptionChange('stockRef');  
  }

  /**
   * On stock reference equity change.
   */
  async onNewIssueAssumptionStockRefEquityChange() {   
    await this.onNewIssueAssumptionChange('stockRefEq');     
  }

  /**
   * On fx change.
   */
  async onNewIssueAssumptionFxChange() {
    await this.onNewIssueAssumptionChange('stockRefEq');
  }

  /**
   * Executes calculation after new issue assumption changes.
   * @param fieldName name of the field that is changed
   */
  async onNewIssueAssumptionChange(fieldName?: string,  isDeleteEvent: boolean = false) {
    try {
      this.setLoadingState(true);

      if (fieldName) {
        let calculatedValue;

        if ((fieldName === 'coupon' || fieldName === 'redemptionValue' || fieldName === 'issuePrice')) {
          calculatedValue = this.view.asHelper.convertible.subType !== ConvertibleSubType.PEPS ?
            await this._service.setYieldFromRedemption(this.view.getCalculateYieldOrRedemptionRequest(this.currentEnvironmentId)) : 0;
          this.view.setUserAssumptionsIssueYield(calculatedValue);
        }
        if (fieldName === 'issueYield') {
          calculatedValue =
            await this._service.setRedemptionFromYield(this.view.getCalculateYieldOrRedemptionRequest(this.currentEnvironmentId));
          this.view.setUserAssumptionsRedemption(calculatedValue);
        }
      }

      this._changeDetectorRef.detectChanges();

      this.updatePricingModel();

      /**
       * when premium is changed effective premium should be recalculated.
       */
      if (fieldName === 'premium' || fieldName === 'stockRef' || fieldName === 'stockRefEq') {
        this.view.init(this._presenter.getModelData(), this.widgetState, fieldName);
        this._changeDetectorRef.detectChanges();
      }

      if (fieldName === 'premium') {
        this.view.niaView.model.higherStrikePremium = this.view.niaView.model.premium;
      }

      if (fieldName === 'higherStrikePremium') {
        this.view.niaView.model.premium = this.view.niaView.model.higherStrikePremium;
      }

      //TODO we need to update pricing model here as stock ref will be changed so new issue assumptions in the presenter can be outdated..

      this.updatePricingModel();

      await this._service.overrideNewIssueAssumptions(this.view.getNiaRequest(this.currentEnvironmentId));
      await this.calculateValuation(false, '');
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on show new issue settings dialog.
   */
  onShowNewIssueSettingsDialog() {
    const dialogRef = this._dialogService.open({
      title: 'New Issue Settings',
      content: LvNewIssueSettingsDialogComponent,
      width: 451
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-new-issue-settings-dialog');
  }

  /**
   * Occurs on estimates settings dialog.
   */
  onShowEstimatesSettingsDialog() {
    const dialogRef = this._dialogService.open({
      title: 'Estimate Settings',
      content: LvEstimatesSettingsDialogComponent,
      width: 493
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-side-panel-window');
    const dialog = dialogRef.content.instance as LvEstimatesSettingsDialogComponent;
    dialog.leversysLocalId = this.view.asHelper.convertible.leversysId;
  }

  /**
   * Occurs on show edit custom view.
   */
  onShowEditCustomView() {
    const dialogRef = this._dialogService.open({
      title: 'Pricing Custom Selection',
      content: LvPricingSidePanelComponent,
    });

    const dialog = dialogRef.content.instance as LvPricingSidePanelComponent;
    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-side-panel-window');

    const pricingWidgetState = LvUtil.jsonParse(LvUtil.jsonStringify(this.widgetState)) as IPricingWidgetState;
    dialog.pricingWidgetState = pricingWidgetState;

    dialog.didCancelEditing.subscribe(() => {
      dialogRef.close();
    });

    dialog.didSaveChanges.subscribe((result: IPricingWidgetState) => {
      this.widgetState = result;
      this.widgetState.useDefaultView = false;
      this._widgetStateManagerService.saveWidgetState(this.widgetType, this.widgetState);
      this._widgetStateManagerService.useCustomView(this.widgetType);
      this.resizeHandlerViewCallInit();
      this._changeDetectorRef.detectChanges();
      dialogRef.close();
    });
  }

  /**
   * Shows estimates settings.
   * @param sender Sender buttor.
   */
  showEstimatesSettings(sender: string) {

    this.view.setIsEstimateButtonClick(sender === 'estimatesButton');
    this.view.setIsNewIssueAssumptionsButtonClick(sender === 'newIssueAssumptionButton');

    const width = sender === 'newIssueAssumptionButton' ?
      this.resizeHandlerView.baseNiaGridWidth : this.resizeHandlerView.estimatesSettingsWidth;

    this.settings.onShowSettings(width);
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Occurs on panel closed.
   */
  onPanelClosed() {
    this.view.onPanelClosed();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on panel slide.
   * @param isExpanded A flag indicating if panel is expanded.
   */
  onPanelSlide(isExpanded: boolean) {
    this.view.onPanelSlide(isExpanded);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on warrning clicked.
   */
  onWarrningClicked() {
    const timeout = 5000 + this.currentWarrnings.length * 2000;
    this.currentWarrnings.forEach(warning => {
      this._errorService.toastrService.warning(warning, null, {
        timeOut: timeout
      });
    });
  }

  /**
   * Occurs on view changed.
   */
  viewChanged() {
    this.resizeHandlerViewCallInit();
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Open modal with options to save to application, excel or to booth places
   */
  onSaveSectionToExcel() {
    const dialogRef = this._dialogService.open({
      title: 'Save Destination',
      content: LvExcelSaveModalComponent,
      width: 240,
      height: this._presenter.isInstrumentDraft ? 90 : 180
    });

    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-save-excel');

    const dialog = dialogRef.content.instance as LvExcelSaveModalComponent;
    dialog.isTermsSave = this._presenter.isInstrumentDraft;

    dialog.doSaveToApplication.subscribe(() => {
      this.onSaveSection();
    });

    dialog.doSaveToAll.subscribe(() => {
      this.onSaveSection();
      this.saveDataToExcel();
    });

    dialog.doSaveToExcel.subscribe(() => {
      this.saveDataToExcel();
    });
  }

  /**
   * Check if field name equals stockRef.
   * Called from html.
   * @param fieldName Field name to check.
   * @returns Flag that describes if field name equals stockRef.
   */
  isStockRef(fieldName: string): boolean {
    return fieldName === 'stockRef';
  }
 
  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  onDestroy(): void {
    this.settings.settingsPanel.forceClose();
    this._subscriptions.forEach(a => a.unsubscribe());
  }

   /**
   * Retrieves element's id
   * @param element reference to given element
   * @param id element's id
   * @returns flag- true if element with given id is found
   */
   getTooltipId(element: ElementRef<HTMLElement>, id: string): boolean {
    return element.nativeElement.getAttribute('data-tooltip-id') === id;
  }

  /**
   * Saves data to Excel.
   */
  private saveDataToExcel() {

    const model = this.view.getPricing();
    model.valuationDate = new Date(this.view.viView.model.valuationDate);
    this._presenter.pricingMapper.init(model);

    const fields = this._excelSvc.getPricesDataFields();
    this._presenter.pricingMapper.setIsNewIssue(this.view.asHelper.convertible.status === ConvertibleSetupStatus.NewIssue);
    this._presenter.pricingMapper.setIsCrossFxOverridden(fields);
    let mappedFields = this._presenter.pricingMapper.reverseMap(fields, 'Pricing');

    if (!(this.view.cpeView.isVisible && this.view.isExpectedRatioVisible)) {
      mappedFields = mappedFields.filter(x => x.alias !== 'EST_USE');
    }

    this._excelSvc.saveDataToExcel(mappedFields, this._excelSvc.isAnyV3version(), true, this.isCrossFx);

    this._presenter.flatMarketDataMapper.init(this.view.getPricing());

    this._excelSvc.saveDataToExcel(this._presenter.flatMarketDataMapper.reverseMap(
      this._excelSvc.getFlatMarketDataFields(),
      'Market Data'
    ), this._excelSvc.isAnyV3version(), true, this.isCrossFx);
  }

  /**
   * Fields initialization.
   * @param analyticsSettings IAnalyticsSettings object.
   */
  private init(analyticsSettings?: IAnalyticsSettings) {
    this.view.init(analyticsSettings, this.widgetState);

    LvFormUtil.markAllControlsAsUntouched(this.valuationInputsForm);

    this.resizeHandlerViewCallInit();
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Resizes handler view call init.
   */
  private resizeHandlerViewCallInit() {
    this.resizeHandlerView.init(
      false,
      this.view.viView.modelFields.crossFx,
      this.view.niaView.isNewIssue,
      this.view.viView.modelFields.downsideVol,
      this.view.viView.modelFields.parity,
      this.widgetState.displayNewIssueAssumptionButton,
      this.widgetState.displayMarketDataOverrides
    );
  }

  /**
   * Updates model.
   */
  private updateModel() {
    this._presenter.updateModel({
      eventId: AnalyticsSettingsEvents.PricingEnvironmentUpdated,
      data: this.widgetState
    });
  }

  /**
   * Update pricing model.
   */
  private updatePricingModel() {
    this._presenter.updatePricingModel({
      source: this.eventSource,
      data: {
        viInputs: this.view.viView.model,
        pricing: this.view.getPricing(),
        environments: this.envSettings.items
      }
    });
  }

  /**
   * Sets loading state.
   * @param isLoading A flag indicating if state is loading.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.envSettings.setLoadingState(isLoading);
    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Checks CR effective date.
   */
  private checkCrEffectiveDate() {
    if (this.view.cpeView.model && !this.view.isExpectedRatioVisible && this.view.asHelper.convertible &&
      !!this.view.asHelper.convertible.expectedCrEffectiveDate) {
      this.view.cpeView.model.useEstimatedRatio = false;
      this.calculateValuation(false, '');
    }
  }
}
/**
 * Gets pricing initial state.
 * @param isOpenedFromExcel A flag indicating if component is opened from Excel.
 * @returns IPricingWidgetState object.
 */
function getPricingInitialState(isOpenedFromExcel: boolean): IPricingWidgetState {
  return {
    useDefaultView: true,
    displayEstimatesButton: true,
    displayNewIssueAssumptionButton: true,
    displayMarketDataOverrides: true,
    isOpenedFromExcel: isOpenedFromExcel
  } as IPricingWidgetState;
}

declare global {
  interface Window {
    chrome: any;
  }
}
