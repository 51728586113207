import {
	CallMakeWholeTypeDescription, DiscountRateType, RatchetMatrixCompensationValueTypeDescription,
	RatchetMatrixOfferValueTypeDescription, TakeoverProtectionRatchetMatrixItem
} from '@lv-instrument-common/index';
import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { getDateValue } from './date-time-helper';
import { QuickAndFullTermsDocument } from '@lv-convertible-bond/models';

export function mapMakeWhole(fieldDictionary: any,
                             model: QuickAndFullTermsDocument,
                             initialModel: QuickAndFullTermsDocument,
                             isPrivateInstrument: boolean) {

	fieldDictionary['MW_TYPE'] = {
		mapFn: (value: any) => {
      if (!model.fullTerms.call.callMakeWhole) {
        model.fullTerms.call.callMakeWhole = initialModel.fullTerms.call.callMakeWhole;
      }

      model.fullTerms.call.callMakeWhole.type = value;
      model.fullTerms.conversion.callMakeWhole.type = value;
		},
		reverseMapFn: () => {
			return CallMakeWholeTypeDescription[model.fullTerms.conversion.recieveMakeWholeConversion ?
        model.fullTerms.conversion.callMakeWhole.type : model.fullTerms.call.callMakeWhole.type];
		},
		type: ExcelFieldDataTypes.Enum,
		enumType: CallMakeWholeTypeDescription
	};

	fieldDictionary['MW_CPN_DSC_VALUE'] = {
		mapFn: (value: any) => {
			model.fullTerms.call.callMakeWhole.discountSpread = value;
			model.fullTerms.conversion.callMakeWhole.discountSpread = value;
		},
		reverseMapFn: () => {
			return model.fullTerms.conversion.recieveMakeWholeConversion ?
        model.fullTerms.conversion.callMakeWhole.discountSpread : model.fullTerms.call.callMakeWhole.discountSpread;
		},
		type: ExcelFieldDataTypes.Number
	};

	fieldDictionary['MW_INTL_PRM'] = {
		mapFn: (value: any) => {
			model.fullTerms.call.callMakeWhole.initialPremium = value;
			model.fullTerms.conversion.callMakeWhole.initialPremium = value;
		},
		reverseMapFn: () => {
      return model.fullTerms.conversion.recieveMakeWholeConversion ?
        model.fullTerms.conversion.callMakeWhole.initialPremium : model.fullTerms.call.callMakeWhole.initialPremium;
		},
		type: ExcelFieldDataTypes.Number
	};

	fieldDictionary['MW_ROLLING_PERIOD'] = {
		mapFn: (value: any) => {
			model.fullTerms.conversion.callMakeWhole.remainingCouponMWPeriod = value;
			model.fullTerms.conversion.callMakeWhole.rollingMWPeriod = true;
		},
		reverseMapFn: () => {
       if (model.fullTerms.conversion.recieveMakeWholeConversion && model.fullTerms.conversion.callMakeWhole.rollingMWPeriod) {
				return model.fullTerms.conversion.callMakeWhole.remainingCouponMWPeriod ;
			}        
		},
		type: ExcelFieldDataTypes.Number
	};

	fieldDictionary['MW_CPN_END_DATE'] = {
		mapFn: (value: any) => {
			model.fullTerms.call.callMakeWhole.couponsIncludedUntil = value;
			model.fullTerms.conversion.callMakeWhole.couponsIncludedUntil = value;
		},
		reverseMapFn: () => {
			return model.fullTerms.conversion.recieveMakeWholeConversion ?
			getDateValue(model.fullTerms.conversion.callMakeWhole.couponsIncludedUntil) :
			getDateValue(model.fullTerms.call.callMakeWhole.couponsIncludedUntil);
		},
		type: ExcelFieldDataTypes.Date
	};

	fieldDictionary['MW_CPN_DSC_TYPE'] = {
		mapFn: (value: any) => {
      if (!model.fullTerms.conversion.callMakeWhole) {
        model.fullTerms.conversion.callMakeWhole = initialModel.fullTerms.conversion.callMakeWhole;
      }

      model.fullTerms.conversion.callMakeWhole.discountRateType = value;
      
      if (!model.fullTerms.call.callMakeWhole) {
        model.fullTerms.call.callMakeWhole = initialModel.fullTerms.call.callMakeWhole;
      }
      
      model.fullTerms.call.callMakeWhole.discountRateType = value;
		},
		reverseMapFn: () => {
			return DiscountRateType[model.fullTerms.conversion.recieveMakeWholeConversion ?
        model.fullTerms.conversion.callMakeWhole.discountRateType : model.fullTerms.call.callMakeWhole.discountRateType];
		},
		type: ExcelFieldDataTypes.Enum,
		enumType: DiscountRateType
	};

	fieldDictionary['MW_RTCH_COMP_TYPE'] = {
		mapFn: (value: any) => {
			model.fullTerms.call.callMakeWhole.ratchetMatrixCompensationValueType = value;
			model.fullTerms.conversion.callMakeWhole.ratchetMatrixCompensationValueType = value;
		},
		reverseMapFn: () => {
			return RatchetMatrixCompensationValueTypeDescription[model.fullTerms.conversion.recieveMakeWholeConversion ?
        model.fullTerms.conversion.callMakeWhole.ratchetMatrixCompensationValueType :
        model.fullTerms.call.callMakeWhole.ratchetMatrixCompensationValueType];
		},
		type: ExcelFieldDataTypes.Enum,
		enumType: RatchetMatrixCompensationValueTypeDescription
	};

	fieldDictionary['MW_RTCH_VALUE_TYPE'] = {
		mapFn: (value: any) => {
			model.fullTerms.call.callMakeWhole.ratchetMatrixOfferValueType = value;
			model.fullTerms.conversion.callMakeWhole.ratchetMatrixOfferValueType = value;
		},
		reverseMapFn: () => {
			return RatchetMatrixOfferValueTypeDescription[model.fullTerms.conversion.recieveMakeWholeConversion ?
        model.fullTerms.conversion.callMakeWhole.ratchetMatrixCompensationValueType :
        model.fullTerms.call.callMakeWhole.ratchetMatrixOfferValueType];
		},
		type: ExcelFieldDataTypes.Enum,
		enumType: RatchetMatrixOfferValueTypeDescription
	};

	fieldDictionary['MW_RTCH_SCHED_RANGE'] = {
		mapFn: (value: any) => {
      if (!model.fullTerms.call) {
        model.fullTerms.call = initialModel.fullTerms.call;
      }

      if (!model.fullTerms.call.callMakeWhole) {
        model.fullTerms.call.callMakeWhole = initialModel.fullTerms.call.callMakeWhole;
      }

      model.fullTerms.call.callMakeWhole.ratchetMatrixMembers = value.map((el: TakeoverProtectionRatchetMatrixItem) => {
        el.startDate = LvDateUtil.parse(el.startDate);
        return el;
      });

      model.fullTerms.takeoverProtection.ratchetMatrixMembers = value.map((el: TakeoverProtectionRatchetMatrixItem) => {
        el.startDate = LvDateUtil.parse(el.startDate);
        return el;
      });

      model.fullTerms.conversion.callMakeWhole.ratchetMatrixMembers = value.map((el: TakeoverProtectionRatchetMatrixItem) => {
        el.startDate = LvDateUtil.parse(el.startDate);
        return el;
      });
		},
		reverseMapFn: () => {
      let value = [];

      if (model.fullTerms.takeoverProtection
        && model.fullTerms.takeoverProtection.ratchetMatrixMembers
        && model.fullTerms.takeoverProtection.ratchetMatrixMembers.length > 0) {
          value = model.fullTerms.takeoverProtection.ratchetMatrixMembers.map(el => ({
            ...el,
            startDate: DateExtensions.toOADate(el.startDate)
          }));
      }

      return !!value ? value.filter(x => x.offerValue !== null && x.offerValue !== undefined) : value;
		},
		type: ExcelFieldDataTypes.Schedule
	};
}