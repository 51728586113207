/**
 * Referance Entity Type description.
 */
export enum ReferanceEntityTypeDescription {
    SingleName = 'Single Name',
    Index = 'Index',
  }
  
/**
 * Referance Entity Type.
 */
export enum ReferanceEntityType {
    SingleName = 'SingleName',
    Index = 'Index',
}
