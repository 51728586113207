import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnChanges,
  SimpleChanges, Output, TemplateRef, ElementRef, HostListener, OnDestroy, ViewRef } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { GridDataResult, PageChangeEvent, GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';

// TODO: InstrumentMonitor menu items should be an input
// This way we do not need to reference other modules
import { Permissions as PrivateInstrumentPermissions } from './../../../../custom-instruments/custom-instrument.permissions';
import { ILvGridContextMenuItem } from '@lv-core-ui/components';
import { IInstrumentSelectedEvent } from '@lv-core-ui/models';
import { LvDateService, LvErrorService, LvPermissionService } from '@lv-core-ui/services';
import { LvDateUtil, LvUtil } from '@lv-core-ui/util';
import { InstrumentMonitorHubService } from '@lv-instrument-monitor/hubs';
import { IConvertibleBondInfo, IWatchList, IInstrumentInfoWatchList, InstrumentMonitorMapper, IWidgetState } from '@lv-instrument-monitor/models';
import { ConvertibleInfoService, WatchListService } from '@lv-instrument-monitor/services';
import { WatchListView } from '@lv-instrument-monitor/views';
import { AuthorizationService } from '@lv-core-ui/services/authorization/authorization.service';
import { IResource } from '@lv-core-ui/models/authorization/resource';
import { RestrictedResourceType } from '@lv-core-ui/models/authorization/restricted-resource-type-enum';

// TODO InstrumentMonitor region should be Region enum type in the database
// We have Regions in Common.Data, so same Region util should exist on client
export enum Region {
  AMERICA = 'America',
  APAC = 'APAC',
  EMEA = 'EMEA',
  JAPAN = 'Japan',
  SOUTH_AMERICA = 'SouthAmerica'
}

export interface ILvInstrumentMonitorGridResult extends GridDataResult {
  data: IConvertibleBondInfo[];
}

@Component({
  selector: 'lv-instrument-monitor',
  templateUrl: './lv-instrument-monitor.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvInstrumentMonitorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('grid', { static: true }) grid: GridComponent;
  @ViewChild('instrumentWatchListsTemplate', { static: true }) instrumentWatchListsTemplate: TemplateRef<any>;
  @ViewChild('textAnchor') textAnchorElement: ElementRef<HTMLElement>;
  @ViewChild('watchListsAnchor') watchListsAnchorElement: ElementRef<HTMLElement>;

  @Input() filter: IWidgetState;

  @Output() didSelectInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doOpenInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doOpenDocuments: Subject<IInstrumentSelectedEvent>;
  @Output() doOpenBasicTerms: Subject<IInstrumentSelectedEvent>;
  @Output() doCreateNewIssueFromExistingInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doCopyInstrument: Subject<IInstrumentSelectedEvent>;
  @Output() doDeleteInstrument: Subject<IInstrumentSelectedEvent>;


  recordSelectClick: Subject<any>;

  recordsView: ILvInstrumentMonitorGridResult;

  pageSize: number;
  skip: number;

  contextMenuItems: ILvGridContextMenuItem[];

  selectableSettings: SelectableSettings;
  selectedKeys: number[];

  selectedRecord: IConvertibleBondInfo;

  rightClickSelect: boolean;

  isLoading: boolean;

  // Instrument watch lists
  get watchListsEmpty(): boolean {
    return this.watchLists.length === 0;
  }

  get scrollable(): string {
    return this.isLoading ? 'none' : 'virtual';
  }

  get rowHeight(): number {
    return this.isLoading ? 0 : 24;
  }

  autoHideContextMenu: boolean;
  watchListView: WatchListView;
  watchListsLoading: boolean;
  watchListsDisabled: boolean;
  watchListsConfig: {
    visible: boolean;
    width: number;
    height: number;
    top: number;
    left: number;
  };
  watchLists: IWatchList[];
  instrumentWatchListsDict: {
    [id: number]: IInstrumentInfoWatchList
  };

  private _subscriptions: Subscription[];
  padlockTitle = 'This Instrument is custom created and can only be seen by company user';

  constructor(
    private authorizationService: AuthorizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private errorService: LvErrorService,
    private permissionService: LvPermissionService,
    private service: ConvertibleInfoService,
    private watchListService: WatchListService,
    private hubService: InstrumentMonitorHubService,
    private _lvDateService: LvDateService
  ) {
    this.selectedKeys = [];

    this.isLoading = false;

    this.rightClickSelect = false;
    this.initPagging();
    this.initGridView();

    this.autoHideContextMenu = true;
    this.watchListsLoading = true;
    this.watchListsConfig = {
      visible: false,
      width: 180,
      height: 220,
      top: 0,
      left: 0
    };
    this.watchLists = [];
    this.instrumentWatchListsDict = {};

    this.selectableSettings = {
      mode: 'single'
    };

    this.didSelectInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doOpenInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doOpenDocuments = new Subject<IInstrumentSelectedEvent>();
    this.doOpenBasicTerms = new Subject<IInstrumentSelectedEvent>();
    this.doCreateNewIssueFromExistingInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doCopyInstrument = new Subject<IInstrumentSelectedEvent>();
    this.doDeleteInstrument = new Subject<IInstrumentSelectedEvent>();
    this.recordSelectClick = new Subject<any>();

    this.watchListView = new WatchListView(this.authorizationService, this.permissionService, this.watchListService);
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onDocumentClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (this.watchListsConfig.visible) {
      const clickedInsideContainer = this.textAnchorElement.nativeElement.contains(targetElement);
      const clickedInside = this.watchListsAnchorElement.nativeElement.contains(targetElement);
      if (!clickedInsideContainer && !clickedInside) {
        this.autoHideContextMenu = true;
        this.hideWatchLists();

        if (this.rightClickSelect) {
          this.selectedKeys = [];
          this.selectedRecord = null;
          this.rightClickSelect = false;
        }

        this.changeDetectorRef.detectChanges();
      }
    }
  }

  ngOnInit() {
    this.grid.pageChange
      .pipe(debounceTime(500))
      .subscribe(evt => this.onPageChanged(evt));

    this._subscriptions = [
      this.hubService.privateInstrumentCreatedEvent.subscribe(e => {
         this.refresh(null, false);
      }),

      this.recordSelectClick.pipe(
        debounceTime(500) // Adjust time based on user behavior
      ).subscribe(id => this.doSelectRecord(id))
    
    ];
    this.contextMenuItems = [{
      text: 'Open Instrument',
      action: this.onOpenInstrument.bind(this),
      mouseEnterAction: this.closeSubMenu.bind(this),
    }];

    if (this.permissionService.hasPermission(
      PrivateInstrumentPermissions.MODULE,
      PrivateInstrumentPermissions.CREATE_PRIVATE_INSTRUMENT)
    ) {
      const canCopy = () => {
        if (!this.selectedRecord) {
          return false;
        }

        if (this.selectedRecord.isPrivateInstrument) {
          return true;
        }

        let region;
        switch (this.selectedRecord.region as Region) {
          case Region.AMERICA:
          case Region.SOUTH_AMERICA:
            region = 'AMERICA';
            break;
          case Region.APAC:
          case Region.JAPAN:
            region = 'ASIA';
            break;
          case Region.EMEA:
          region = 'EMEA';
          break;
        }

        return this.authorizationService.authorize('TERMS', 'COPY_TERMS',
        {
          restrictedResourceType: RestrictedResourceType.Instrument,
          region: region,
          identifier: this.selectedRecord.lwsIdentifier,
          restrictionGroup: 'DATA_SERVICE_TRIAL'
        } as IResource);
      };

      const canCreateNewIssue = () => {
        if (this.selectedRecord?.instrumentType === 'Bond' || this.selectedRecord?.instrumentType === 'Equity') {
          return false;
        }

        return canCopy();
      }

      const canDeleteInstrument = () => {
        if (this.selectedRecord?.instrumentType === 'Equity') {
          return false;
        }

        return this.selectedRecord && this.selectedRecord.isPrivateInstrument;
      }

      this.contextMenuItems = this.contextMenuItems.concat([{
        text: 'Copy Instrument',
        action: this.onCopyInstrument.bind(this),
        mouseEnterAction: this.closeSubMenu.bind(this),
        isVisible: canCopy
      }, {
        text: 'Create New Issue from Existing Instrument',
        action: this.onCreateNewIssueFromExistingInstrument.bind(this),
        mouseEnterAction: this.closeSubMenu.bind(this),
        isVisible: canCreateNewIssue
      }, {
        text: 'Delete Instrument',
        action: this.onDeleteInstrument.bind(this),
        mouseEnterAction: this.closeSubMenu.bind(this),
        isVisible: canDeleteInstrument 
      }]);
    }

    this.contextMenuItems.push({
      text: 'Add/Remove from Watchlist',
      template: this.instrumentWatchListsTemplate
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    await this.authorizationService.getUserAccessRightsData();

    if (changes.filter.previousValue !== changes.filter.currentValue) {
      this.refresh(true);
    }
  }

  async refresh(init?: boolean, showLoader = true) {
    try {
      if (init) {
        this.initPagging();
        this.initGridView();
      }

      if (showLoader) {
        this.isLoading = true;
        this.changeDetectorRef.detectChanges();
      }

      const selectedIds = this.filter.selected.map(a => a.id);
      const quickIds = this.filter.quick.filter(a => a.isSelected).map(a => a.id);
      const wlIds = [...selectedIds, ...quickIds]; // union with unique elements

      const result = await this.service.queryAvailableConvertibles(this.filter.query, wlIds, this.skip, this.pageSize);

      const instrumentMonitorMapper = new InstrumentMonitorMapper();
      result.records = instrumentMonitorMapper.mapConvertibleBondInfoArrayToUI(result.records);

      this.recordsView = {
        data: result.records,
        total: result.totalRecords
      };
    }
    catch (error) {
      this.recordsView = {
        data: [],
        total: 0
      };
      this.errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  doSelectRecord(recordId: number) {
    const record = this.recordsView.data.find(a => a.id === recordId);

    if (record) {
      this.selectedRecord = record;
      this.didSelectInstrument.next({ ... record});
    }
  }

  onSelectedKeysChange(selectedIds: any[]) {
    const id = selectedIds[0];
    this.recordSelectClick.next(id);   
  }

  onPageChanged(event: PageChangeEvent) {
    this.skip = event.skip;
    this.refresh();
  }

  onContextMenuOpen(record: IConvertibleBondInfo) {
    this.selectedKeys = [record.id];
    this.selectedRecord = { ...record };
    this.rightClickSelect = true;

    this.hideWatchLists();

    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }

  onOpenInstrument(record: IConvertibleBondInfo) {
    this.doOpenInstrument.next({ ... record});
  }

  onOpenDocuments(record: IConvertibleBondInfo) {
    this.doOpenDocuments.next({ ... record});
  }

  onCreateNewIssueFromExistingInstrument(record: IConvertibleBondInfo) {
    this.doCreateNewIssueFromExistingInstrument.next({ ... record});
  }

  onDeleteInstrument(record: IConvertibleBondInfo) {
    this.doDeleteInstrument.next({ ... record});
  }

  onCopyInstrument(record: IConvertibleBondInfo) {
    this.doCopyInstrument.next({ ... record});
  }

  // Instrument watch lists
  onOpenSubMenu(textAnchor: HTMLElement) {
    this.autoHideContextMenu = false;
    this.changeDetectorRef.detectChanges();

    this.loadInstrumentWatchLists();

    const textParentRect = textAnchor.parentElement.getBoundingClientRect();
    const documentRect = document.body.getBoundingClientRect();
    this.watchListsConfig.top = textParentRect.top - 1;

    if (documentRect.height < this.watchListsConfig.top + this.watchListsConfig.height) {
      this.watchListsConfig.top = textParentRect.bottom - this.watchListsConfig.height + 1;
    }

    this.watchListsConfig.left = textParentRect.left + textParentRect.width;
    this.watchListsConfig.visible = true;

    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }

  isWatchListSelected(wl: IWatchList) {
    return this.instrumentWatchListsDict[wl.id];
  }

  async onChooseWatchList(wl: IWatchList) {
    try {
      this.watchListsDisabled = true;
      this.changeDetectorRef.detectChanges();

      const exists = this.instrumentWatchListsDict[wl.id];

      await this.watchListService.updateUserInstrumentWatchList(this.selectedRecord.id, wl.id, !exists);

      if (!exists) {
        this.instrumentWatchListsDict[wl.id] = {
          id: wl.id,
          instrumentInfoId: this.selectedRecord.id,
          listName: wl.listName,
          ownerId: wl.ownerId,
          subjectId: wl.subjectId
        };
      }
      else {
        delete this.instrumentWatchListsDict[wl.id];
      }

      this.refresh();
    }
    catch (error) {
      this.errorService.handleError(error);
    }
    finally {
      this.watchListsDisabled = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  async loadInstrumentWatchLists() {
    try {
      this.watchListsLoading = true;
      this.changeDetectorRef.detectChanges();

      const instrumentWL = await this.watchListService.getUserInstrumentWatchLists(this.selectedRecord.id);
      this.instrumentWatchListsDict = LvUtil.toDictionary(instrumentWL.records, 'id');

      this.watchLists = await this.watchListView.getView({
        excludeSystemWatchLists: true,
        excludeSharedWatchLists: true
      }, true);
    }
    catch (error) {
      this.instrumentWatchListsDict = {};
      this.watchLists = [];
      this.errorService.handleError(error);
    }
    finally {
      this.watchListsLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  getMaturityDateValue(instrumentInfo: IConvertibleBondInfo): string {
    if (instrumentInfo.instrumentType === 'Equity') {
      return '';
    }

    return instrumentInfo?.maturityDate ? this._lvDateService.formatToLocaleDateTime(false, LvDateUtil.parse(instrumentInfo.maturityDate)) : 'Perpetual'
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private closeSubMenu() {
    if (this.watchListsConfig.visible) {
      this.autoHideContextMenu = true;
      this.hideWatchLists();
      this.changeDetectorRef.detectChanges();
    }
  }

  private hideWatchLists() {
    this.watchLists = [];
    this.instrumentWatchListsDict = {};
    this.watchListsConfig.visible = false;
  }

  private initPagging() {
    this.pageSize = 200;
    this.skip = 0;
  }

  private initGridView() {
    this.recordsView = {
      data: [],
      total: 0
    };
  }
}
