<lv-flex-box class="lv-market-data-settings-container"> 
  <kendo-tabstrip class="lv-tabstrip marketData-settings-tabstrip lv-settings-content"
    [keepTabContent]="true">
      <kendo-tabstrip-tab
        selected="true"
        title="Credit"
        cssClass="DM-5049">
        <ng-template kendoTabContent>
            <lv-cds-market-data-credit-settings
            data-cy="DM-5049"
            [settings]="settings.creditSettings">
            </lv-cds-market-data-credit-settings>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        title="Interest Rates"
        cssClass="DM-5050">
        <ng-template kendoTabContent>
          <lv-cds-market-data-interest-rates-settings
          data-cy="DM-5050"
            [settings]="settings.interestRatesSettings"
            [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData">
          </lv-cds-market-data-interest-rates-settings>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>

  <lv-flex-box class="lv-settings-footer" direction="row">
      <button kendoButton 
        class= "cancel-btn"
        [primary]="true"
        lvId="cancel-market-data-cds-settings"
        (click)="onCancel()">
        Cancel
      </button>
      <div class="lv-settings-footer-divider">&nbsp;</div>
      <button kendoButton
        class="reset-button"
        [primary]="true"
        lvId="reset-to-default-market-cds-settings"
        (click)="onResetToDefault()">
        Reset To Default
      </button>
      <button kendoButton
        [primary]="true"
        [class.save-settings-btn-disabled]="!isChanged()"
        lvId="save-market-data-cds-settings"
        (click)="onSave()">
        Save
      </button>
  </lv-flex-box>
</lv-flex-box>


