import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit, Optional, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { AccrualMethodDescription, CouponType, CouponTypeDescription, DayCountDescription, ExCouponPeriodType,  FrequencyDescription, PikCouponType } from '@lv-instrument-common/index';
import { BondTermsDocument } from '../../../models/bond-terms/BondTermsDocument';
import { BondTermsSectionEvent } from '../../../models/bond-terms/enums/bond-terms-section-events';
import { LvBondTermsPresenter } from '../lv-bond-terms.presenter';
import { Subscription, filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { v4 } from 'uuid';
import { BondTermsService } from '../../../services/bond-terms.services';
import { LvExcelService } from '@lv-excel/services';
import { BondGeneral } from '../../../models';
import { BondCoupon, CustomCouponData, FixedCouponData } from '../../../models/bond-terms/BondCoupon';
import { LvBondFloatingCouponComponent } from './lv-bond-floating-coupon/lv-bond-floating-coupon.component';
import { LvBondPikCouponComponent } from './lv-bond-pik-coupon/lv-bond-pik-coupon.component';

@Component({
  selector: 'lv-bond-coupon',
  templateUrl: './lv-bond-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LvBondCouponComponent implements OnInit {
  @Input() model: BondCoupon;
  @ViewChild('floatingComponent', { static: false }) floatingComponent: LvBondFloatingCouponComponent;
  @ViewChild('pikComponent', { static: false }) pikComponent: LvBondPikCouponComponent;

  //#region get:set
  get isZeroCouponSelected(): boolean {
    return this.model.type === CouponType.ZeroCoupon;
  }

  get isFixedTypeVisible(): boolean {
    return this.model.type === CouponType.Fixed
      || this.model.type === CouponType.FixToFloating
      || this.model.type === CouponType.FloatingToFix;
  }

  get isCustomTypeVisible(): boolean {
    return this.model.type === CouponType.Custom;
  }

  get isPikTypeVisible(): boolean {
    return this.model.type === CouponType.PIK;
  }

  get isFloatingTypeVisible(): boolean {
    return this.model.type === CouponType.Floating
      || this.model.type === CouponType.FixToFloating
      || this.model.type === CouponType.FloatingToFix
      || this.isPikCouponTypePikOrFloatingOrPikPlusFloating;
  }

  get pikEndDate(): Date {
    return this._pikEndDate;
  }

  set pikEndDate(value: Date) {
    this._pikEndDate = value;

    if (this.model.pik) {
      this.model.pik.pikEndDate = value
    }
  }
  get accrualEndDate(): Date {
    return this._accrualEndDate;
  }

  set accrualEndDate(value: Date) {
    this._accrualEndDate = value;

    if (this.model && this.accrualEndDateEnabled) {
      this.model.accrualEndDate = value;
    }
  }

  get accrualStartDate(): Date {
    return this._accrualStartDate;
  }

  set accrualStartDate(value: Date) {
    this._accrualStartDate = value;

    if (this.model && this.accrualStartDateEnabled) {
      this.model.accrualStartDate = value;
    }
  }

  get firstCouponPaymentDate(): Date {
    return this._firstCouponPaymentDate;
  }

  set firstCouponPaymentDate(value: Date) {
    this._firstCouponPaymentDate = value;

    if (this.model && this.firstCouponPaymentDateEnabled) {
      this.model.firstCouponPaymentDate = value;
    }
  }

  get penultimateCouponPaymentDate(): Date {
    return this._penultimateCouponPaymentDate;
  }

  set penultimateCouponPaymentDate(value: Date) {
    this._penultimateCouponPaymentDate = value;

    if (this.model && this.penultimateCouponPaymentDateEnabled) {
      this.model.penultimateCouponPaymentDate = value;
    }
  }

  get couponChangedDate(): Date {
    return this._couponChangedDate;
  }

  set couponChangedDate(value: Date) {
    this._couponChangedDate = value;

    if (this.model && this.couponChangedDateEnabled) {
      this.model.typeChangeDate = value;
    }
  }

  get guaranteedStartDate(): Date {
    return this._guaranteedStartDate;
  }
  set guaranteedStartDate(value: Date) {
    this._guaranteedStartDate = value;

    if (this.model && this.guaranteedStartDateEnabled) {
      this.model.guaranteedStartDate = value;
    }
  }

  get guaranteedEndDate(): Date {
    return this._guaranteedEndDate;
  }
  set guaranteedEndDate(value: Date) {
    this._guaranteedEndDate = value;

    if (this.model && this.guaranteedEndDateEnabled) {
      this.model.guaranteedStartEndDate = value;
    }
  }

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get isPikCouponTypePikOrFloatingOrPikPlusFloating () {
    return this.model.type === CouponType.PIK &&
      (this.model.pik.pikCouponType === PikCouponType.PIKPlusCashOrCash || this.model.pik.pikCouponType === PikCouponType.PIKPlusCashOrPIK);
  }

  get addAdditionalFloatingClass() {
    return this.isPikCouponTypePikOrFloatingOrPikPlusFloating ? 'lv-coupon-floating-container'  : '';
 }
  //#endregion
  
  private _accrualEndDate: Date;
  private _accrualStartDate: Date;
  private _firstCouponPaymentDate: Date;
  private _penultimateCouponPaymentDate: Date;
  private _guaranteedStartDate: Date;
  private _guaranteedEndDate: Date;
  private _couponChangedDate: Date;
  private _modelSubscription: Subscription;
  private _pikEndDate: Date;
  private _destroyRef = inject(DestroyRef)

  couponTypeLookup: LvLookupEnum;
  couponDayCountLookup: LvLookupEnum;
  accrualMethodLookup: LvLookupEnum;
  couponFrequencyLookup: LvLookupEnum;
  exCouponPerLookup: LvLookupEnum;

  dpAccStartDateCheckBoxId: string;
  dpAccStartDatePickerId: string;
  dpAccEndDateCheckBoxId: string;
  dpAccEndDatePickerId: string;
  dpFirstPaymentDateCheckBoxId: string;
  dpFirstPaymentDatePickerId: string;
  dpPenPaymentDateCheckBoxId: string;
  dpPenPaymentDatePickerId: string;
  dpCouponChangedDateCheckBoxId: string;
  dpCouponChangedDatePickerId: string;
  dpGuaranteedStartDateId: string;
  dpGuaranteedEndDateId: string;
  couponDatesEomCheckboxId: string;
  guaranteedCouponsCheckboxId: string;

  numberOfDecimals = '0';
  numberFormat = 'n0';
  accrualEndDateEnabled: boolean;
  accrualStartDateEnabled: boolean;
  firstCouponPaymentDateEnabled: boolean;
  penultimateCouponPaymentDateEnabled: boolean;
  typeChangeDateEnabled: boolean;
  couponChangedDateEnabled: boolean;
  guaranteedStartDateEnabled: boolean;
  guaranteedEndDateEnabled: boolean;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,    
    private _lvBondTermsService: BondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.couponTypeLookup = new LvLookupEnum(CouponTypeDescription);
    this.couponDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.accrualMethodLookup = new LvLookupEnum(AccrualMethodDescription);
    this.couponFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.exCouponPerLookup = new LvLookupEnum(ExCouponPeriodType);
    this.accrualStartDateEnabled = false;
    this.accrualEndDateEnabled = false;
    this.firstCouponPaymentDateEnabled = false;
    this.penultimateCouponPaymentDateEnabled = false;
    this.typeChangeDateEnabled = false;
    this.couponChangedDateEnabled = false;
    this.guaranteedStartDateEnabled = false;
    this.guaranteedEndDateEnabled = false;
    this.couponDatesEomCheckboxId = v4();
    this.guaranteedCouponsCheckboxId = v4();
    this.dpAccEndDateCheckBoxId = 'accEndDateCheckBoxId';
    this.dpAccEndDatePickerId = 'accEndDatePickerId';
    this.dpAccStartDateCheckBoxId = 'accStartDateCheckBoxId';
    this.dpAccStartDatePickerId = 'accStartDatePickerId';
    this.dpFirstPaymentDateCheckBoxId = 'firstPaymentDateCheckBoxId';
    this.dpFirstPaymentDatePickerId = 'firstPaymentDatePickerId';
    this.dpPenPaymentDateCheckBoxId = 'penPaymentDateCheckBoxId';
    this.dpPenPaymentDatePickerId = 'penPaymentDatePickerId';
    this.dpCouponChangedDatePickerId = 'couponChangedDatePickerId';
    this.dpCouponChangedDateCheckBoxId = 'couponChangedDateCheckBoxId';
    this.dpGuaranteedStartDateId = 'guaranteedStartDateId';
    this.dpGuaranteedEndDateId = 'guaranteedEndDateId';
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef)).pipe(
     filter(event => event === BondTermsSectionEvent.TermsGeneralDatesEvent || 
                     event === BondTermsSectionEvent.ModelLoadedEvent || 
                     event === BondTermsSectionEvent.TermsGeneralEvent)
   ).subscribe(event => {
       this.model = this._presenter.getModel().coupon;
       this.setDefaultFields(this._presenter.getModel().general);
       this._changeDetectorRef.detectChanges();
   });

   this.setDefaultFields(this._presenter.getModel().general);
 }

   /**
   * Fired when coupon type is changed
   */
  couponTypeChange() {
    this.typeChangeDateEnabled = false;
    if (this.model.type === CouponType.Custom) {
      if (!this.model.custom) {
        this.model.custom = new CustomCouponData();
        this.model.custom.schedule = [];
      }
    } else if (this.model.type === CouponType.FixToFloating
      || this.model.type === CouponType.FloatingToFix) {
        this.typeChangeDateEnabled = true;
    } else if (this.model.type === CouponType.Fixed) {
      if (!this.model.fixed) {
        this.model.fixed = new FixedCouponData();
        this.model.fixed.stepUpCouponSchedule = [];
      }
    }
    this.onModelChange('DM-24');
  }

  /**
   * Fired when coupon Days or Frequency
   */
  onCouponDaysOrFrequencyChanged() {
    this.onModelChange();
  }

  /**
   * Fired when PenultimateCouponPaymentDate changed
   */
  onPenultimateCouponPaymentDateChaged() {
    this.penultimatePaymentDateChanged();
    this.onModelChange();
  }

  /**
   * Fired when penultimatePaymentDate changed
   */
  penultimatePaymentDateChanged() {
    const today = new Date();
    const numberOfDays = new Date(today.getFullYear(), today.getMonth(), 0).getDate();

    if (this.model.penultimateCouponPaymentDate && this.model.penultimateCouponPaymentDate.getDate() === numberOfDays) {
      this.model.couponDatesEOM = true;
    } else {
      this.model.couponDatesEOM = false;
    }
  }

  /**
   * Fired when accrualStartDateCheckbox changed
   * @param value
   */
  accrualStartDateCheckboxChanged(value: boolean): void {
    this.accrualStartDateEnabled = value;

    if (this.model) {
      this.model.accrualStartDate = value ? this._accrualStartDate : null;
    }

    this.onModelChange();
  }

  /**
   * Fired when accrualEndDateCheckbox changed
   * @param value
   */
  accrualEndDateCheckboxChanged(value: boolean): void {
    this.accrualEndDateEnabled = value;

    if (this.model) {
      this.model.accrualEndDate = value ? this._accrualEndDate : null;
    }

    this.onModelChange();
  }

  /**
   * Fired when firstCouponPaymentDateCheckbox changed
   * @param value
   */
  firstCouponPaymentDateCheckboxChanged(value: boolean): void {
    this.firstCouponPaymentDateEnabled = value;

    if (this.model) {
      this.model.firstCouponPaymentDate = value ? this.firstCouponPaymentDate : null;
    }

    this.onModelChange();
  }

    /**
   * Fired when penultimateCouponPaymentDateCheckbox changed
   * @param value
   */
  penultimateCouponPaymentDateCheckboxChanged(value: boolean): void {
    this.penultimateCouponPaymentDateEnabled = value;

    if (this.model) {
      this.model.penultimateCouponPaymentDate = value ? this.penultimateCouponPaymentDate : null;
    }

    this.onModelChange();
  }

  
  /**
   * Fired when couponChangedDateCheckbox changed
   * @param value
   */
  couponChangedDateCheckboxChanged(value: boolean) {
    this.couponChangedDateEnabled = value;

    if (this.model) {
      this.model.typeChangeDate = value ? this._couponChangedDate : null;
    }

    this.onModelChange();
  }

  /**
   * Fired when guaranteedStartCheckBox changed
   * @param value
   */
  guaranteedStartCheckBoxChange(value: boolean) {
    this.guaranteedStartDateEnabled = value;

    if (this.model) {
      this.model.guaranteedStartDate = value ? this._guaranteedStartDate : null;
    }

    this.onModelChange();
  }

  
  /**
   * Fired when guarantee guaranteedEndCheckBox changed
   * @param value
   */
  guaranteedEndCheckBoxChange(value: boolean) {
    this.guaranteedEndDateEnabled = value;

    if (this.model) {
      this.model.guaranteedStartEndDate = value ? this._guaranteedEndDate : null;
    }

    this.onModelChange();
  }

    
  /**
   * Set default values based on FirstSettlement and Maturity dates
   * @param issueAndRedemption
   */
  setDefaultFields(issueAndRedemption: BondGeneral) {
    const couponSettings = this._lvBondTermsService.couponSettings;

    if (!this.model.accrualStartDate) {
      this._accrualStartDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, couponSettings?.accrualStartDate);
      this.accrualStartDateEnabled = false;
    } else {
      this._accrualStartDate = this.model.accrualStartDate;
      this.accrualStartDateEnabled = true;
    }

    if (!this.model.accrualEndDate) {
      this._accrualEndDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -couponSettings?.accrualEndDate);
      this.accrualEndDateEnabled = !!couponSettings?.accrualEndDate;
    } else {
      this._accrualEndDate = this.model.accrualEndDate;
      this.accrualEndDateEnabled = true;
    }

    if (!this.model.firstCouponPaymentDate) {
      this.firstCouponPaymentDate = issueAndRedemption.firstSettlementDate;
      this.firstCouponPaymentDateEnabled = false;
    } else {
      this.firstCouponPaymentDate = this.model.firstCouponPaymentDate;
      this.firstCouponPaymentDateEnabled = true;
    }

    if (!this.model.penultimateCouponPaymentDate) {
      this.penultimateCouponPaymentDate = issueAndRedemption.maturityDate;
      this.penultimateCouponPaymentDateEnabled = false;
    } else {
      this.penultimateCouponPaymentDate = this.model.penultimateCouponPaymentDate;
      this.penultimateCouponPaymentDateEnabled = true;
    }

    if (!this.model.typeChangeDate) {
      this.couponChangedDate = new Date();
      this.couponChangedDateEnabled = false;
    } else {
      this.couponChangedDate = this.model.typeChangeDate;
      this.couponChangedDateEnabled = true;
    }

    if (!this.model.guaranteedStartDate) {
      this.guaranteedStartDate = DateExtensions.addDays(issueAndRedemption.firstSettlementDate, couponSettings?.guaranteedStartDate);
      this.guaranteedStartDateEnabled = !!couponSettings?.guaranteedStartDate;
    } else {
      this.guaranteedStartDate = this.model.guaranteedStartDate;
      this.guaranteedStartDateEnabled = true;
    }

    if (!this.model.guaranteedStartEndDate) {
      this.guaranteedEndDate = DateExtensions.addDays(issueAndRedemption.maturityDate, -couponSettings?.guaranteedStartEndDate);
      this.guaranteedEndDateEnabled = !!couponSettings?.guaranteedStartEndDate;
    } else {
      this.guaranteedEndDate = this.model.guaranteedStartEndDate;
      this.guaranteedEndDateEnabled = true;
    }

    if (!this.model.pik?.pikEndDate) {
        if (!!issueAndRedemption.maturityDate && !issueAndRedemption.isPerpetual) {
          this.pikEndDate = issueAndRedemption.maturityDate;
        }
        else if(!!issueAndRedemption.isPerpetual) {
          this.pikEndDate = issueAndRedemption.firstSettlementDate;
        }
      }
    
    this.typeChangeDateEnabled = this.model.type === CouponType.FloatingToFix || this.model.type === CouponType.FixToFloating;
  }
  
  onModelChange(sourceOfUpdate: string = 'other') {
    this._presenter.updateModel((terms: BondTermsDocument) => {
      terms.coupon = this.model;
    }, BondTermsSectionEvent.TermsCouponEvent,
      sourceOfUpdate);
  }

  applyScheduleChanges() {
    if (this.model && this.floatingComponent) {
      if (this.floatingComponent.floatingFixingHistorySchedule) {
        this.floatingComponent.floatingFixingHistorySchedule.applyAdvancedGridChanges();
      }

      if (this.floatingComponent.floatingSpreadSchedule) {
        this.floatingComponent.floatingSpreadSchedule.applyAdvancedGridChanges();
      }

      if (this.floatingComponent.floatingCustomDatesSchedule) {
        this.floatingComponent.floatingCustomDatesSchedule.applyAdvancedGridChanges();
      }
    }

    if (this.model && this.pikComponent && this.pikComponent.pikSchedule) {
      this.pikComponent.pikSchedule.applyAdvancedGridChanges();
    }
  }
}
