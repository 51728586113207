import { IModelCustomization } from './../models/model-customization/model-customization';
import { HardCallModelType, DividendProtectionModel, CreditModelType, SoftCallModelType, EquityModelType, StochasticCreditModelType,
  StochasticCreditBCType, PepsParityConvention, PepsParityConventionDescription, DividendProtectionModelDescription,
  CreditModelTypeDescription, EquityModelTypeDescription, StochasticCreditModelTypeDescription,
  HardCallModelTypeDescription, SoftCallModelTypeDescription, PricerVersion } from './../models/model-customization/model-customization-enum';

import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';
import { FieldMapper } from '@lv-excel/helpers/field-mapper';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { IField } from '@lv-excel/models/field';
import { getDateValue } from '@lv-convertible-bond/helpers/date-time-helper';

/**
 * Model customization Excel mapper.
 */
export class ModelCustomizationExcelMapper extends FieldMapper<IModelCustomization> {
  constructor() {
    super();
  }

  /**
   * Creates map.
   */
  createMap() {
    // exclude terms
    this._fieldDictionary['MC_CALL'] = {
      mapFn: (value: any) => {
        this._model.excludeCall = value;
      },
      reverseMapFn: () => {
        return this._model.excludeCall;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_PUT'] = {
      mapFn: (value: any) => {
        this._model.excludePut = value;
      },
      reverseMapFn: () => {
        return this._model.excludePut;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_DVD_PROT'] = {
      mapFn: (value: any) => {
        this._model.excludeDividendProtection = value;
      },
      reverseMapFn: () => {
        return this._model.excludeDividendProtection;
      },
      type: ExcelFieldDataTypes.String,
    };

    this._fieldDictionary['MC_COV_CONTG'] = {
      mapFn: (value: any) => {
        this._model.excludeContigentConversion = value;
      },
      reverseMapFn: () => {
        return this._model.excludeContigentConversion;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_COV_RESET'] = {
      mapFn: (value: any) => {
        this._model.excludeConversionPriceReset = value;
      },
      reverseMapFn: () => {
        return this._model.excludeConversionPriceReset;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_CON_VAR'] = {
      mapFn: (value: any) => {
        this._model.excludeVariableConversion = value;
      },
      reverseMapFn: () => {
        return this._model.excludeVariableConversion;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_CURR_CONV_AVG'] = {
      mapFn: (value: any) => {
        this._model.currentConversionAveraging = value;
      },
      reverseMapFn: () => {
        return !this._model.currentConversionAveraging;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_CURR_RESET_AVG'] = {
      mapFn: (value: any) => {
        this._model.currentResetAveraging = value;
      },
      reverseMapFn: () => {
        return !this._model.currentResetAveraging;
      },
      type: ExcelFieldDataTypes.String,
    };
    // include terms
    this._fieldDictionary['MC_FWD_CONV_AVG'] = {
      mapFn: (value: any) => {
        this._model.forwardConversionAveraging = value;
      },
      reverseMapFn: () => {
        return this._model.forwardConversionAveraging;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_FWD_RESET_AVG'] = {
      mapFn: (value: any) => {
        this._model.forwardResetAveraging = value;
      },
      reverseMapFn: () => {
        return this._model.forwardResetAveraging;
      },
      type: ExcelFieldDataTypes.String,
    };
    // model type
    this._fieldDictionary['MC_CREDIT_MOD'] = {
      mapFn: (value: any) => {
        this._model.creditModelSettings.creditModelType = value;
      },
      reverseMapFn: () => {
        return CreditModelTypeDescription[this._model.creditModelSettings.creditModelType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CreditModelTypeDescription,
    };
    this._fieldDictionary['MC_EQUITY_MD'] = {
      mapFn: (value: any) => {
        this._model.creditModelSettings.equityModelType = value;
      },
      reverseMapFn: () => {
        return EquityModelTypeDescription[this._model.creditModelSettings.equityModelType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: EquityModelTypeDescription
    };
    this._fieldDictionary['MC_UND_CREDIT_MOD'] = {
      mapFn: (value: any) => {
        this._model.creditModelSettings.underlyingCreditModelType = value;
      },
      reverseMapFn: () => {
        return CreditModelTypeDescription[this._model.creditModelSettings.underlyingCreditModelType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CreditModelTypeDescription
    };
    this._fieldDictionary['MC_STOCH_CREDIT'] = {
      mapFn: (value: any) => {
        this._model.creditModelSettings.stochasticCreditModelType = value;
      },
      reverseMapFn: () => {
        return StochasticCreditModelTypeDescription[this._model.creditModelSettings.stochasticCreditModelType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: StochasticCreditModelTypeDescription
    };
    this._fieldDictionary['MC_CREDIT_POINTS'] = {
      mapFn: (value: any) => {
        this._model.creditModelSettings.numPointsStochasticCredit = value;
      },
      reverseMapFn: () => {
        return this._model.creditModelSettings.numPointsStochasticCredit;
      },
      type: ExcelFieldDataTypes.Number,
    };
    this._fieldDictionary['MC_STOCH_CREDIT_BC'] = {
      mapFn: (value: any) => {
        this._model.creditModelSettings.stochasticCreditBCType = value;
      },
      reverseMapFn: () => {
        return StochasticCreditBCType[this._model.creditModelSettings.stochasticCreditBCType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: StochasticCreditBCType
    };
    // call adjustments
    this._fieldDictionary['MC_HC_MODEL'] = {
      mapFn: (value: any) => {
        this._model.callAdjustmentModelSettings.hardCallModelType = value;
      },
      reverseMapFn: () => {
        return HardCallModelTypeDescription[this._model.callAdjustmentModelSettings.hardCallModelType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: HardCallModelTypeDescription
    };
    this._fieldDictionary['MC_HC_ADJUST'] = {
      mapFn: (value: any) => {
        this._model.callAdjustmentModelSettings.hardCallAdjustmentFactor = value;
      },
      reverseMapFn: () => {
        return this._model.callAdjustmentModelSettings.hardCallAdjustmentFactor;
      },
      type: ExcelFieldDataTypes.Number,
    };
    this._fieldDictionary['MC_SC_MODEL'] = {
      mapFn: (value: any) => {
        this._model.callAdjustmentModelSettings.softCallModelType = value;
      },
      reverseMapFn: () => {
        return SoftCallModelTypeDescription[this._model.callAdjustmentModelSettings.softCallModelType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: SoftCallModelTypeDescription
    };
    this._fieldDictionary['MC_SC_ADJUST'] = {
      mapFn: (value: any) => {
        this._model.callAdjustmentModelSettings.softCallAdjustmentFactor = value;
      },
      reverseMapFn: () => {
        return this._model.callAdjustmentModelSettings.softCallAdjustmentFactor;
      },
      type: ExcelFieldDataTypes.Number,
    };
    this._fieldDictionary['MC_NOT_CALLABLE_DT'] = {
      mapFn: (value: any) => {
        this._model.callAdjustmentModelSettings.notCallableDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.callAdjustmentModelSettings.notCallableDate);
      },
      type: ExcelFieldDataTypes.Date,
    };
    this._fieldDictionary['MC_NOT_CALLABLE_PTY'] = {
      mapFn: (value: any) => {
        this._model.callAdjustmentModelSettings.notCallableParity = value;
      },
      reverseMapFn: () => {
        return this._model.callAdjustmentModelSettings.notCallableParity;
      },
      type: ExcelFieldDataTypes.Number,
    };
    // other parameters
    this._fieldDictionary['MC_DVD_PROT_MODEL'] = {
      mapFn: (value: any) => {
        this._model.dividendProtectionModel = value;
      },
      reverseMapFn: () => {
        return DividendProtectionModelDescription[this._model.dividendProtectionModel];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DividendProtectionModelDescription
    };
    this._fieldDictionary['MC_PEPS_PARITY'] = {
      mapFn: (value: any) => {
        this._model.pepsParityConvention = value;
      },
      reverseMapFn: () => {
        return PepsParityConventionDescription[this._model.pepsParityConvention];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: PepsParityConventionDescription
    };
    this._fieldDictionary['MC_EXP_LIFE'] = {
      mapFn: (value: any) => {
        this._model.expLifeExcludesDefault = value;
      },
      reverseMapFn: () => {
        return this._model.expLifeExcludesDefault;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_EXCH_AS_CONVERT'] = {
      mapFn: (value: any) => {
        this._model.priceExchangeableAsConvertible = value;
      },
      reverseMapFn: () => {
        return this._model.priceExchangeableAsConvertible;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_DFLT_LOW_PARITY'] = {
      mapFn: (value: any) => {
        this._model.defaultOnLowParity = value;
      },
      reverseMapFn: () => {
        return this._model.defaultOnLowParity;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_CASH_REBATE'] = {
      mapFn: (value: any) => {
        this._model.includeCashRebateInParity = value;
      },
      reverseMapFn: () => {
        return this._model.includeCashRebateInParity;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_FORCE_OPT_RESET'] = {
      mapFn: (value: any) => {
        this._model.forceOptionalReset = value;
      },
      reverseMapFn: () => {
        return this._model.forceOptionalReset;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_FWD_DIV_PROT_ADJUST'] = {
      mapFn: (value: any) => {
        this._model.fwdDividProtectionAdjst = value;
      },
      reverseMapFn: () => {
        return this._model.fwdDividProtectionAdjst;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_MAT_OV'] = {
      mapFn: (value: any) => {
        this._model.maturityDateOverride = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.maturityDateOverride);
      },
      type: ExcelFieldDataTypes.Date,
    };
    this._fieldDictionary['MC_EARLY_CONV_DT'] = {
      mapFn: (value: any) => {
        this._model.conversionNoticeGiven = true;
        this._model.conversionNoticeDate = LvDateUtil.parse(value);
      },
      reverseMapFn: () => {
        return DateExtensions.toOADate(this._model.conversionNoticeDate);
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_DVD_EXTRAP'] = {
      mapFn: (value: any) => {
        this._model.assumeDividendYieldUntilMaturity = value;
      },
      reverseMapFn: () => {
        return this._model.assumeDividendYieldUntilMaturity;
      },
      type: ExcelFieldDataTypes.String,
    };
    this._fieldDictionary['MC_PRICER_VERSION'] = {
      mapFn: (value: any) => {
        this._model.pricerVersion = value;
      },
      reverseMapFn: () => {
        return PricerVersion[this._model.pricerVersion];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: PricerVersion
    };
    /*   MC_CALL
      MC_DVD_PROT
      MC_COV_CONTG
      MC_DFLT_LOW_PARITY
      MC_CASH_REBATE
      MC_FORCE_OPT_RESET
      MC_FWD_DIV_PROT_ADJUST
      MC_COV_RESET
      MC_CON_VAR
*/
  }

  /**
   * Maps enum type.
   * @param value Value.
   * @param enumType Enum type.
   * @returns Mapped value.
   */
  mappEnumType(value: any, enumType: any) {
    return value;

    // This code is behind return because the specific way of data
    // is returned for enums from market data section
    // When we change logic on excel app we should remove line 280

    switch (enumType) {
      case CreditModelTypeDescription:
        return CreditModelType[this.getEnumKeyByEnumValue(enumType, value)];
      case EquityModelTypeDescription:
        return EquityModelType[this.getEnumKeyByEnumValue(enumType, value)];
      case StochasticCreditModelTypeDescription:
        return StochasticCreditModelType[this.getEnumKeyByEnumValue(enumType, value)];
      case StochasticCreditBCType:
        return StochasticCreditBCType[this.getEnumKeyByEnumValue(enumType, value)];
      case HardCallModelTypeDescription:
        return HardCallModelType[this.getEnumKeyByEnumValue(enumType, value)];
      case SoftCallModelTypeDescription:
        return SoftCallModelType[this.getEnumKeyByEnumValue(enumType, value)];
      case DividendProtectionModelDescription:
        return DividendProtectionModel[this.getEnumKeyByEnumValue(enumType, value)];
      case PepsParityConventionDescription:
        return PepsParityConvention[this.getEnumKeyByEnumValue(enumType, value)];
    }
  }

  /**
   * Maps enum type V3.
   */
  mappEnumTypeV3(value: any, enumType: any) {
    return value;
  }

  /**
   * Checks if value is not null or undefine.
   * @param value Value.
   * @returns True if value is not null and undefinef and false vice versa.
   */
     isNotNullOrUndefined(value: any): boolean {
      return value !== null && value !== undefined;
    }

  /**
   * Custom mapping.
   * @param inputData Input data.
   */
  customMapp(inputData: any[]) {

    const currentConversionAveraging = inputData.find(x => x.key === 'MC_CURR_CONV_AVG');
    const currentResetAveraging = inputData.find(x => x.key === 'MC_CURR_RESET_AVG');
    const notCallableDate = inputData.find(x => x.key === 'MC_NOT_CALLABLE_DT');
    const maturityDateOverride = inputData.find(x => x.key === 'MC_MAT_OV')

    if (this._model) {
      if (notCallableDate) {
        this._model.isNotCallableDate = !!notCallableDate.value;
      }

      if (maturityDateOverride) {
        this._model.isMaturityDateOverride = !!maturityDateOverride.value;
      }
    }

  /**
   * task- SYSTEM-3409-Current conversion/reset averaging lacks proper mapping
   * 'MC_CURR_CONV_AVG'(Current Conv. Averaging) and 'MC_CURR_RESET_AVG'(Current Reset Averaging)- fields in MC Exclude Terms section
   *  when overriden in excel and opened in excel form,
   *  these fields values in model are setted to opposite flags from those sent from excel
   *  e.g. in excel 'MC_CURR_CONV_AVG' is overriden with 'Yes', in form this means 'uncheck this field and include it into calculation'
   *
   */
    if (currentConversionAveraging && this.isNotNullOrUndefined(currentConversionAveraging.value)) {
      this._model.currentConversionAveraging = currentConversionAveraging.value;
    }

    if (currentResetAveraging && this.isNotNullOrUndefined(currentResetAveraging.value)) {
      this._model.currentResetAveraging = currentResetAveraging.value;
    }
  }
}
