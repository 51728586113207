import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { CouponReferenceTenorDescription, CouponType, DayCountDescription, FrequencyDescription,  IndexType, PikCouponType } from '@lv-instrument-common/index';
import { v4 } from 'uuid';
import { BondCoupon, FloatingCouponData, Index } from '../../../../models/bond-terms/BondCoupon';
import { LvBondFloatingFixingHistoryScheduleComponent } from './lv-bond-floating-fixing-history-schedule/lv-bond-floating-fixing-history-schedule.component';
import { LvBondFloatingCustomDatesScheduleComponent } from './lv-bond-floating-custom-dates-schedule/lv-bond-floating-custom-dates-schedule.component';
import { LvBondFloatingSpreadScheduleComponent } from './lv-bond-floating-spread-schedule/lv-bond-floating-spread-schedule.component';

@Component({
  selector: 'lv-bond-floating-coupon',
  templateUrl: './lv-bond-floating-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvBondFloatingCouponComponent implements OnInit {
  @ViewChild('floatingFixingHistorySchedule', { static: false }) floatingFixingHistorySchedule: LvBondFloatingFixingHistoryScheduleComponent;
  @ViewChild('floatingCustomDatesSchedule', { static: false }) floatingCustomDatesSchedule: LvBondFloatingCustomDatesScheduleComponent;
  @ViewChild('floatingSpreadSchedule', { static: false }) floatingSpreadSchedule: LvBondFloatingSpreadScheduleComponent;

  @Input() model: FloatingCouponData;
  @Input() couponModel: BondCoupon;
  @Input() couponType: CouponType;
  @Output() didFloatingCouponChange: EventEmitter<FloatingCouponData>;

  get isfloatingToFixOrFixToFloatingCouponType(): boolean {
    return (this.couponType === CouponType.FixToFloating || this.couponType === CouponType.FloatingToFix);
  }

  get isPikCouponTypePikOrFloatingOrPikPlusFloating() {
    return this.couponType === CouponType.PIK && (this.couponModel.pik.pikCouponType === PikCouponType.PIKPlusCashOrCash ||
      this.couponModel.pik.pikCouponType ===PikCouponType.PIKPlusCashOrPIK);
  }

  floatingSpreadScheduleId: string;
  floatingCustomDatesId: string;

  couponDayCount: LvLookupEnum;
  couponFrequency: LvLookupEnum;
  couponIndex: LvLookupEnum;
  referenceTenor: LvLookupEnum;

  decimalsZero = '0';
  formatZero = 'n0';
  formatFour = '#,###.####';
  decimalsFour = '4';

  constructor() {
    this.floatingCustomDatesId = v4();
    this.floatingSpreadScheduleId = v4();

    this.couponDayCount = new LvLookupEnum(DayCountDescription);
    this.couponFrequency = new LvLookupEnum(FrequencyDescription);
    this.couponIndex = new LvLookupEnum(IndexType);
    this.referenceTenor = new LvLookupEnum(CouponReferenceTenorDescription);

    this.didFloatingCouponChange = new EventEmitter<FloatingCouponData>();
  }

  ngOnInit() {
    if (!this.model.index) {
      this.model.index = new Index();
    }
  }

  onFloatingCouponChange() {
    this.didFloatingCouponChange.next(this.model);
  }

  getFloatingCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
}
