<lv-flex-box class="lv-market-data-settings-container"> 
  <kendo-tabstrip class="lv-tabstrip marketData-settings-tabstrip lv-settings-content"
    [keepTabContent]="true">
      <kendo-tabstrip-tab
        selected="true"
        title="Credit"
        cssClass="DM-5119">
        <ng-template kendoTabContent>
            <lv-equity-option-credit-settings
            [settings]="settings.creditSettings"
            data-cy="DM-5119">
            </lv-equity-option-credit-settings>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        title="Volatility"
        cssClass="DM-5120">
        <ng-template kendoTabContent>
          <lv-equity-option-volatility-settings
            [settings]="settings.volatilitySettings"
            data-cy="DM-5120">
          </lv-equity-option-volatility-settings>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        title="Borrow"
        cssClass="DM-5121">
        <ng-template kendoTabContent>
          <lv-equity-option-borrow-settings
            [settings]="settings.borrowSettings"
            data-cy="DM-5121">
          </lv-equity-option-borrow-settings>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        title="Dividends"
        cssClass="DM-5122">
        <ng-template kendoTabContent>
          <lv-equity-option-dividends-settings
            [settings]="settings.dividendsSettings"
            data-cy="DM-5122">
          </lv-equity-option-dividends-settings>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab
        title="Interest Rates"
        cssClass="DM-5123">
        <ng-template kendoTabContent>
          <lv-equity-option-interest-rates-settings
            [settings]="settings.interestRatesSettings"
            [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData"
            data-cy="DM-5123">
          </lv-equity-option-interest-rates-settings>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>

  <lv-flex-box class="lv-settings-footer" direction="row">
      <button kendoButton 
        class= "cancel-btn"
        [primary]="true"
        lvId="cancel-market-data-equity-option-settings"
        (click)="onCancel()">
        Cancel
      </button>
      <div class="lv-settings-footer-divider">&nbsp;</div>
      <button kendoButton
        class="reset-button"
        [primary]="true"
        lvId="reset-to-default-market-equity-option-settings"
        (click)="onResetToDefault()">
        Reset To Default
      </button>
      <button kendoButton
        [primary]="true"
        [class.save-settings-btn-disabled]="!isChanged()"
        lvId="save-market-data-equity-option-settings"
        (click)="onSave()">
        Save
      </button>
  </lv-flex-box>
</lv-flex-box>

