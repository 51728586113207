import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';

import { Subject, Observable } from 'rxjs';

import { IJwtUser } from '../models/jwt-user';
import { LvUtil } from './util';
import { LvApplicationError } from '../models/lv-error/application';
import { CookieStorage } from './cookie-storage';
import { ITokenResponse } from '../models/token-response';
import { IUserAccessRightsData } from '../models/user-permissions-and-policies';
import { LvDataMaster } from '../models/lv-data-master';

const jwt = new JwtHelperService();

let jwtUserCached: IJwtUser = null;

const clearLocalStorage: Subject<void> = new Subject<void>();

export class LocalStorage {

  static get doClearLocalStorage(): Observable<void> {
    return clearLocalStorage.asObservable();
  }

  static setLocalStorageItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    }
    catch (error) {
      throw new LvApplicationError(LvDataMaster.getError('dM-1917'), 'Local Storage');
    }
  }

  static clear() {
    localStorage.removeItem(environment.userKey);
    localStorage.removeItem(environment.stateKey);
    localStorage.removeItem(environment.permissionsKey);
    CookieStorage.clearCookie(environment.cookieKeys.session);

    jwtUserCached = null;

    clearLocalStorage.next();
  }

  static clearApplicationState() {
    localStorage.removeItem(environment.stateKey);

    clearLocalStorage.next();
  }

  static getTokenResponse(): ITokenResponse | null {
    return LvUtil.jsonParse(localStorage.getItem(environment.userKey));
  }

  static getJwtUser(): IJwtUser {
    if (!jwtUserCached) {
      const tokenResponse = LocalStorage.getTokenResponse();
      if (tokenResponse) {
        jwtUserCached = LocalStorage.parseJwtUserToken(tokenResponse.accessToken);
      }
    }

    return jwtUserCached;
  }

  // Throws error
  static setTokenResponse(tokenResponse: ITokenResponse): void {
    LocalStorage.setLocalStorageItem(environment.userKey, LvUtil.jsonStringify(tokenResponse));

    // Cache the jwtToken
    jwtUserCached = LocalStorage.parseJwtUserToken(tokenResponse.accessToken);
  }

  // Throws error
  static updateTokenResponse(update: (tokenResponse: ITokenResponse) => void): void {
    const tokenResponse = LocalStorage.getTokenResponse();

    update(tokenResponse);

    LocalStorage.setLocalStorageItem(environment.userKey, LvUtil.jsonStringify(tokenResponse));
  }

  static isAccessTokenExpired(): boolean {
    const tokenResponse = LocalStorage.getTokenResponse();

    if (!tokenResponse) {
      return true
    }

    if (!tokenResponse.accessToken || !tokenResponse.refreshToken || jwt.isTokenExpired(tokenResponse.accessToken, 5)) {
      return true;
    }

    return false;
  }

  static mustChangePassword(): boolean {
    const tokenResponse = LocalStorage.getTokenResponse();

    if (!tokenResponse) {
      return false;
    }

    return tokenResponse.changePasswordOnNextLogin;
  }

  static mustSetSecurityQuestion(): boolean {
    const tokenResponse = LocalStorage.getTokenResponse();

    if (!tokenResponse) {
      return false;
    }

    return tokenResponse.setSecurityQuestion;
  }

  static parseJwtUserToken(token: string): IJwtUser {
    let jwtUser = null;

    try {
      const result: any = jwt.decodeToken(token);

      jwtUser = {
        UserId: Number.parseInt(result.UserId, 10),
        SubjectId: Number.parseInt(result.SubjectId, 10),
        Username: result.Username,
        CountryCode: result.CountryCode,
        Restriction: result.Restriction,
        IsAdmin: (result.IsAdmin || '').toLowerCase() === 'true',
        Name: result.Name,
        Permissions: LvUtil.jsonParse(result.Permissions) || []
      } as IJwtUser;
    }
    catch (error) {
      jwtUser = {
        Permissions: []
      } as IJwtUser;
    }

    return jwtUser;
  }

  static setPermissions(permissons: string) {
    LocalStorage.setLocalStorageItem(environment.permissionsKey, permissons);
  }

  static getPermissions() {
    return localStorage.getItem(environment.permissionsKey);
  }

  static getRestrictedResources() {
    return LvUtil.jsonParse<IUserAccessRightsData>(localStorage.getItem(environment.permissionsKey)).restrictedResources;
  }
}
