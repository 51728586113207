import { CallTriggerPeriodTypeDescription, QuickAndFullTermsDocument } from '@lv-convertible-bond/models';
import '@lv-core-ui/util/extensions/array/array';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { getDateValue } from './date-time-helper';
import { CallScheduleItem, CallTriggerTypeDescription, CallValueTypeDescription, CurrencyType, DayCountDescription, FrequencyDescription, NoticePeriod } from '@lv-instrument-common/index';
import { LvDateUtil } from '@lv-core-ui/util';

export class CallMapper {

  mapCall(fieldDictionary: any, model: QuickAndFullTermsDocument, initialModel: QuickAndFullTermsDocument, isPrivateInstrument: boolean) {

    fieldDictionary['CALLABLE'] = {
      mapFn: (value: any) => {
        if (!model.fullTerms.call) {
          model.fullTerms.call = initialModel.fullTerms.call;
        }
        model.fullTerms.issueAndRedemption.isCallable = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.issueAndRedemption.isCallable;
      },
      type: ExcelFieldDataTypes.String,
    };

    fieldDictionary['CALL_VALUE_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.valueType = value;
      },
      reverseMapFn: () => {
        return CallValueTypeDescription[model.fullTerms.call.valueType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CallValueTypeDescription
    };

    fieldDictionary['CALL_YLD_FRQ'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.yieldFrequency = value;
      },
      reverseMapFn: () => {
        return FrequencyDescription[model.fullTerms.call.yieldFrequency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: FrequencyDescription
    };

    fieldDictionary['CALL_DAY_COUNT'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.yieldDayCount = value;
      },
      reverseMapFn: () => {
        return DayCountDescription[model.fullTerms.call.yieldDayCount];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DayCountDescription
    };

    fieldDictionary['CALL_KEEP_ACCRD_CONV'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.keepAccruedConversion = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.keepAccruedConversion;
      },
      type: ExcelFieldDataTypes.String,
    };

    fieldDictionary['CALL_KEEP_ACCRD_RDMP'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.keepAccruedRedemption = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.keepAccruedRedemption;
      },
      type: ExcelFieldDataTypes.String,
    };

    fieldDictionary['CALL_FRFT_CPN'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.forfeitCoupon = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.forfeitCoupon;
      },
      type: ExcelFieldDataTypes.String,
    };

    //region Notice

    fieldDictionary['CALL_NTC_MIN_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.notice = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.notice;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CALL_NTC_MAX_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.callNoticeDaysMax = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.callNoticeDaysMax;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CALL_NTC_CNSDR_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.considerationDaysMax = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.considerationDaysMax;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CALL_NTC_DAYS_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.noticePeriod = value;
      },
      reverseMapFn: () => {
        return NoticePeriod[model.fullTerms.call.noticePeriod];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: NoticePeriod
    };

    fieldDictionary['CALL_NTC_CNSDR_DAYS_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.considerationDaysBusinessCalendar = value;
      },
      reverseMapFn: () => {
        return NoticePeriod[model.fullTerms.call.considerationDaysBusinessCalendar];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: NoticePeriod
    };

    fieldDictionary['CALL_NTC_GVN'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.callNoticeGiven = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.callNoticeGiven;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CALL_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.callDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.call.callDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    //endregion

    //region Trigger

    fieldDictionary['CALL_TRGGR_TYPE'] = {
      mapFn: (value: any) => {
        if (!model.fullTerms.call.softCall) {
          model.fullTerms.call.softCall = initialModel.fullTerms.call.softCall;
        }
        model.fullTerms.call.softCall.triggerType = value;
      },
      reverseMapFn: () => {
        if (model.fullTerms.call.softCall) {
          return CallTriggerTypeDescription[model.fullTerms.call.softCall.triggerType];
        }
        return null;
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CallTriggerTypeDescription
    };

    fieldDictionary['CALL_TRGGR_CCY'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.softCall.currencyOfTrigger = value;
      },
      reverseMapFn: () => {
        if (model.fullTerms.call.softCall) {
          return CurrencyType[model.fullTerms.call.softCall.currencyOfTrigger];
        }
        return null;
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CurrencyType
    };

    fieldDictionary['CALL_TRGGR_PERIOD_TYPE'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.softCall.triggerPeriodType = value;
      },
      reverseMapFn: () => {
        if (model.fullTerms.call.softCall) {
          return CallTriggerPeriodTypeDescription[model.fullTerms.call.softCall.triggerPeriodType];
        }
        return null;
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CallTriggerPeriodTypeDescription
    };

    fieldDictionary['CALL_TRGGR_EXCG_PROP'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.softCall.callTriggerBasedOnFullExchgProperty = value;
      },
      reverseMapFn: () => {
        if (model.fullTerms.call.softCall) {
          return model.fullTerms.call.softCall.callTriggerBasedOnFullExchgProperty;
        }
        return null;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CALL_TRGGR_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.softCall.triggerPeriodDays = value;
      },
      reverseMapFn: () => {
        if (model.fullTerms.call.softCall) {
          return model.fullTerms.call.softCall.triggerPeriodDays;
        }
        return null;
      },
      type: ExcelFieldDataTypes.Number
    };

    fieldDictionary['CALL_TRGGR_OUT_OF_DAYS'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.softCall.outOfDays = value;
      },
      reverseMapFn: () => {
        if (model.fullTerms.call.softCall) {
          return model.fullTerms.call.softCall.outOfDays;
        }
        return null;
      },
      type: ExcelFieldDataTypes.Number
    };

    //endregion

    //region MakeWhole

    fieldDictionary['CALL_MW'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.recieveMakeWhole = value;
      },
      reverseMapFn: () => {
        return model.fullTerms.call.recieveMakeWhole;
      },
      type: ExcelFieldDataTypes.String
    };

    fieldDictionary['CALL_MW_START_DATE'] = {
      mapFn: (value: any) => {
        if (!model.fullTerms.call.callMakeWhole) {
          model.fullTerms.call.callMakeWhole = initialModel.fullTerms.call.callMakeWhole;
        }

        model.fullTerms.call.callMakeWhole.startDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.call.callMakeWhole.startDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    fieldDictionary['CALL_MW_END_DATE'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.callMakeWhole.endDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(model.fullTerms.call.callMakeWhole.endDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    //endregion

    //region call schedule
    fieldDictionary['CALL_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        model.fullTerms.call.scheduleItems = value.map((el: CallScheduleItem) => {
          el.startDate = LvDateUtil.parse(el.startDate);
          el.endDate = el.endDate ? LvDateUtil.parse(el.endDate) : null;
          return el;
        });
      },
      reverseMapFn: () => {
        if (!model.fullTerms.call.scheduleItems) {
          return null;
        }
        const value = model.fullTerms.call.scheduleItems.map(el => ({
          startDate: getDateValue(el.startDate),
          endDate: getDateValue(el.endDate),
          price: el.price,
          trigger: el.trigger,
          interpolation: el.interpolation
        })).sortBy('startDate');

        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    //endregion
  }
}
