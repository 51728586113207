import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { LvConvertibleBondTermsError } from "@lv-convertible-bond/models";
import { HttpClientBase } from "@lv-core-ui/api";
import { LvDateService } from "@lv-core-ui/services";
import { ICDSTermsSettings } from "src/app/modules/instrument/cds-terms/models/cds-terms-settings/cds-terms-settings";

@Injectable({
    providedIn: 'root'
  })
  export class CDSTermsSettingsService extends HttpClientBase {

    constructor(
        @Inject(HttpClient) http: HttpClient,
        lvDateService: LvDateService,
      ) {
        super(lvDateService, http, '/instruments/cdsTermsSettings');
      }

  /**
   * Gets terms settings.
   * @returns ICDSTermsSettings object.
   */
  async getCDSTermsSettings(): Promise<ICDSTermsSettings> {
    try {
        const result = await this.getAsync<ICDSTermsSettings>(null, '');

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }

  /**
   * Resets terms settings.
   * @returns ICDSTermsSectionSettings object.
   */
  async resetCDSTermsSettings(): Promise<ICDSTermsSettings> {
      try {
          const result = await this.getAsync<ICDSTermsSettings>(null, '/default');
          return result;
      }
      catch (error) {
        throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
      }
  }

  /**
   * Saves terms settings.
   * @param request ICDSTermsSectionSettings object.
   */
  async saveCDSTermsSettings(request: ICDSTermsSettings): Promise<void> {
    try {
      await this.postAsync(request, '/save');
    }
    catch (error) {
      throw this.handleError(error, e => new LvConvertibleBondTermsError(e.message));
    }
  }
}
