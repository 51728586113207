<lv-section-settings
    [settings]="settings"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="newIssueSettingsOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]"
    [dataCy]="'DM-1136'">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-new-issue-section-settings [settings]="systemSettings"></lv-new-issue-section-settings>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-new-issue-section-settings
      [settings]="settings"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-new-issue-section-settings>
  </ng-template>
</lv-section-settings>
<lv-flex-box class="lv-settings-footer" direction="row">
  <button kendoButton
    [primary]="true"
    lvId="cancel-new-issue-settings"
    (click)="onCancel()">
    Cancel
  </button>
  <div class="lv-settings-footer-divider">&nbsp;</div>
  <button kendoButton
    class="reset-button"
    [primary]="true"
    lvId="reset-to-default-new-issue-settings"
    (click)="onResetToDefault()">
    Reset To Default
  </button>
  <button kendoButton
    [primary]="true"
    [class.save-settings-btn-disabled]="!isChanged()"
    lvId="save-new-issue-settings"
    (click)="onSave()">
    Save
  </button>
</lv-flex-box>

<ng-template #newIssueSettingsOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1136"></lv-tooltip>
  </ng-container>
</ng-template>