import { BondCreditModelTypeDescription, HardCallModelType, HardCallModelTypeDescription, PricerVersion } from "@lv-analytics/models";
import { IBondModelCustomization } from "@lv-analytics/models/model-customization/bond/bond-model-customization";
import { IBondModelCustomizationState } from "@lv-analytics/models/model-customization/bond/bond-model-customization-state";
import { LvDateService } from "@lv-core-ui/services";
import { LvDateUtil, LvLookupEnum } from "@lv-core-ui/util";
import { v4 } from "uuid";
import { ModelCustomizationViewConfig } from "../../lv-model-customization/lv-model-customization.view";
import { IModelCustomizationState } from "../../lv-model-customization-custom-selection/lv-model-customization-custom-selection.component";
import * as _ from 'lodash';
import { LvExcelService } from "@lv-excel/services";

export class LvBondModelCustomizationView {
  
  get shouldShowModelType(): boolean {
    return this.modelCustomizationState.modelType.isCreditModelType ||
      this.modelCustomizationState.modelType.isPricerVersion;
  }

  get shouldShowCallAdjustments(): boolean {
    return this.modelCustomizationState.callAdjustments.isHardCallModelType ||
      this.modelCustomizationState.callAdjustments.isHardCallAdjustFactor ||
      this.modelCustomizationState.callAdjustments.isNotCallableDate;
  }

  get shouldShowExcludeTerms(): boolean {
    return this.modelCustomizationState.excludeTerms.isCall ||
      this.modelCustomizationState.excludeTerms.isPut;
  }

  get isBarrierModel(): boolean {
    if (!this.model.callAdjustmentModelSettings) {
      return false;
    }

    return this.model.callAdjustmentModelSettings.hardCallModelType === HardCallModelType.BarrierModel;
  }

  get shouldShowOtherParams(): boolean {
    return this.modelCustomizationState.otherParameters.isExpectedLifeExcludingDefault ||
      this.modelCustomizationState.otherParameters.isMaturityDateOverride;
  }

  chExpectedLifeId: string;
  chPriceExchId: string;
  chDefaultOnLowParityId: string;
  chIncCashRebateId: string;
  chCallId: string;
  notCallableId: string;
  maturityDateOverrideId: string;
  chPutId: string;
  chDividendProtectionId: string;
  chContigentConversionId: string;
  chConversionPriceResetId: string;
  chVariableConversionId: string;
  chCurrentConversionAveragingId: string;
  chCurrentResetAveragingId: string;
  chUseNoticeCallId: string;
  chForceOptionalResetId: string;
  chApplyConversionAveraging: string;
  chFwdDividProtectionAdjst: string;
  chForwardConversionAveragingId: string;
  chForwardResetAveragingId: string;
  conversionNoticeGivenId: string;
  assumeDvdUntilMaturityId: string;

  creditModelTypeLookup: LvLookupEnum;
  equityModelTypeLookup: LvLookupEnum;
  underlyingModelTypeLookup: LvLookupEnum;
  stochasticCredit: LvLookupEnum;
  stochasticCreditBC: LvLookupEnum;
  dividendProtectionMode: LvLookupEnum;
  pepsParityConvention: LvLookupEnum;
  hardCallModelType: LvLookupEnum;
  softCallModelType: LvLookupEnum;
  triggerConditionMonitoring: LvLookupEnum;
  pricerVersion: LvLookupEnum;

  model: IBondModelCustomization;

  excludeTermsModelTypeDirection: 'column' | 'row';
  callAveragingOtherDirection: 'column' | 'row';
  excludeTermsModelTypeMinWidth: number;
  callAveragingOtherMinWidth: number;
  sectionRightPadding: number;

  baseColumnWidth: number;
  excludeTermsSectionWidth: number;
  defaultLeftSectionMargin: number;
  defaultColumnPadding: number;
  isNotCallableEnabled: boolean;

  constructor(
    private modelCustomizationState: IBondModelCustomizationState
  ) {
    this.creditModelTypeLookup = new LvLookupEnum(BondCreditModelTypeDescription);
    this.hardCallModelType = new LvLookupEnum(HardCallModelTypeDescription);
    this.pricerVersion = new LvLookupEnum(PricerVersion);

    // checkbox ids
    this.notCallableId = v4();
    this.chExpectedLifeId = v4();
    this.chCallId = v4();
    this.chPutId = v4();
    this.maturityDateOverrideId = v4();
    this.baseColumnWidth = 318;
    this.excludeTermsSectionWidth = 185;
    this.defaultColumnPadding = 9;
    this.isNotCallableEnabled = true;
    this.setDefaultColumnsMode(new ModelCustomizationViewConfig(this.modelCustomizationState as IModelCustomizationState));
  }

  /**
   * Does initialization.
   * @param as IAnalyticsSettings object.
   */
  init(modelCustomization?: IBondModelCustomization) {
    this.model = this.getDefaultModel(modelCustomization);
  }

  getDefaultModel(settings?: IBondModelCustomization): IBondModelCustomization {
    let model = {
      creditModelSettings: {},
      callAdjustmentModelSettings: {}
    } as IBondModelCustomization;
  
    const settingsForSave = _.cloneDeep(settings);

    if (settingsForSave) {
      model = settingsForSave;

        if (!settings.isMaturityDateOverride) {
          settingsForSave.maturityDateOverride = null;
        } else {
          settingsForSave.maturityDateOverride = settings.maturityDateOverride; 
        }
  
        if(settings.isNotCallableDate && !!settings.callAdjustmentModelSettings.notCallableDate) {
          settingsForSave.callAdjustmentModelSettings.notCallableDate = settings.callAdjustmentModelSettings.notCallableDate;
        }
        else {
          settingsForSave.callAdjustmentModelSettings.notCallableDate = null;
        }
        model = settingsForSave;

    }
      
    return model;
  }

  /**
   * Updates model customization state.
   * @param modelCustomizationState IModelCustomizationState object.
   */
  updateModelCustomizationState(modelCustomizationState: IBondModelCustomizationState): void {
    this.modelCustomizationState = modelCustomizationState;
    this.setDefaultColumnsMode(new ModelCustomizationViewConfig(modelCustomizationState));
  }

  /**
   * Function for set columns mode (wide or default)
   * @param currentWidth current Width
   * @param modelCustomizationState modelCustomizationState
   */
  onResize(currentWidth: number, modelCustomizationState: | IBondModelCustomizationState) {
    const viewConfig = new ModelCustomizationViewConfig(modelCustomizationState as IModelCustomizationState);
    if (viewConfig.isWideMode(currentWidth)) {
      this.setWideColumnsMode(viewConfig);
    } else {
      this.setDefaultColumnsMode(viewConfig);
    }
  }

  /**
   * Function for set default columns mode
   * @param viewConfig ModelCustomizationViewConfig
   */
  private setDefaultColumnsMode(viewConfig: ModelCustomizationViewConfig) {
    this.excludeTermsModelTypeDirection = 'column';
    this.callAveragingOtherDirection = 'column';
    this.excludeTermsModelTypeMinWidth = viewConfig.excludeTermsModelTypeSectionMinWidth;
    this.callAveragingOtherMinWidth = viewConfig.callAveragingOtherMinSectionMinWidth;
  }

  /**
   * Function for set wide columns mode
   * @param viewConfig ModelCustomizationViewConfig
   */
  private setWideColumnsMode(viewConfig: ModelCustomizationViewConfig) {
    this.excludeTermsModelTypeDirection = 'row';
    this.callAveragingOtherDirection = 'row';
    this.excludeTermsModelTypeMinWidth = viewConfig.excludeTermsModelTypeSectionMinWidth;
    this.callAveragingOtherMinWidth = viewConfig.callAveragingOtherMinSectionMinWidth;
  }
}