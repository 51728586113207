<lv-flex-box class="lv-peps-conversion">
  <lv-flex-box direction="row" data-cy="DM-841" *ngIf="!isNewIssue">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="lowerStrikeSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
       Lower Strike
      </span>
      <lv-xl-label [field]="'PEPS_LWR_CP'"></lv-xl-label>
    </div>
    <div class="lv-input-field ">
      <lv-numeric-text-box
        name="peps-lower-strike"
        [format]="formatSix"
        [decimals]="decimalsSix"
        [(ngModel)]="model.lowerStrike"
        (didBlur)="onStrikeFieldsChange()"
        (didKeyDownEnter)="onStrikeFieldsChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">{{lowerStrikeSufix}}</span>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-4443" *ngIf="isNewIssue">
    <div class="lv-label lv-label-field"
      showAfter="1500"     
      filter="span:not(lv-xl-label span)">
      <span>
        Lower Strike Premium
      </span>
      <lv-xl-label [field]="'PEPS_LWR_CP'"></lv-xl-label>    
    </div>
    <div class="lv-input-field ">
      <lv-numeric-text-box
        name="peps-lower-strike-premium"
        [format]="formatSix"
        [decimals]="decimalsSix"
        [(ngModel)]="model.lowerStrikePremium"
        (didBlur)="onStrikeFieldsChange()"
        (didKeyDownEnter)="onStrikeFieldsChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">{{lowerStrikeSufix}}</span>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="!isNewIssue" direction="row" data-cy="DM-839">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="higherStrikeSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Higher Strike
      </span>
      <lv-xl-label [field]="'PEPS_HGR_CP'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="peps-higher-strike"
        [format]="formatSix"
        [decimals]="decimalsSix"
        [(ngModel)]="model.higherStrike"
        (didBlur)="onStrikeFieldsChange()"
        (didKeyDownEnter)="onStrikeFieldsChange()">
      </lv-numeric-text-box>
      <span class="lv-sufix-label">{{conversionCustomData.currency}}</span>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="!isNewIssue" direction="row" data-cy="DM-842">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="maxRatioLowerStrikeSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span [attr.data-tooltip-id]="'maxRatioLowerStrikeSourceTooltip'">
         Max Ratio (lower Strike)
      </span>
      <lv-xl-label [field]="'PEPS_MAX_CR'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="peps-max-ratio"
        [format]="formatSix"
        [decimals]="decimalsSix"
        [(ngModel)]="model.maxRatio"
        (didBlur)="onRatioFieldsChange()"
        (didKeyDownEnter)="onRatioFieldsChange()">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box *ngIf="!isNewIssue" direction="row" data-cy="DM-840">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="minRatioHigherStrikeSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Min Ratio (higher Strike)
      </span>
      <lv-xl-label [field]="'PEPS_MIN_CR'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="peps-min-ratio"
        [format]="formatSix"
        [decimals]="decimalsSix"
        [(ngModel)]="model.customMinRatio"
        (didBlur)="onRatioFieldsChange()"
        (didKeyDownEnter)="onRatioFieldsChange()">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-843">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="parallelPEPSSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Parallel PEPS
      </span>
      <lv-xl-label [field]="'PEPS_PARALEL'"></lv-xl-label>
    </div>
    <div class="lv-field lv-field--checkbox">
      <input class="k-checkbox"
        type="checkbox"
        [attr.id]="paralelPepsId"
        name="peps-parallel-peps"
        [(ngModel)]="model.parallelPeps"
        (ngModelChange)="onPepsConversionChange()">
      <label class="k-checkbox-label"
        lvId="parallel-peps-lv-id"
        [attr.for]="paralelPepsId"></label>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-844">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionRebateSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Conversion Rebate
      </span>
      <lv-xl-label [field]="'PEPS_REBATE'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <lv-numeric-text-box
        name="peps-conversion-rebate"
        [format]="formatTwo"
        [decimals]="decimalsTwo"
        [(ngModel)]="model.conversionRebate"
        (didBlur)="onPepsConversionChange()"
        (didKeyDownEnter)="onPepsConversionChange()">
      </lv-numeric-text-box>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-845">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionRebateCurrencySourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Conversion Rebate Currency
      </span>
      <lv-xl-label [field]="'PEPS_REBATE_CCY'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="peps-conversion-rebateCCY"
        [data]="conversionRebateCCYLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.conversionRebateCurrency"
        (ngModelChange)="onPepsConversionChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-837">
    <div class="lv-label lv-label-field lv-label-short"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionStartDateSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Conversion Start Date
      </span>
      <lv-xl-label [field]="'PEPS_START_DATE'"></lv-xl-label>
    </div>

    <lv-datepicker
      name="peps-conversion-start-date"
      [checkBoxLvId] = "dpCheckBoxId"
      [datePickerLvId] = "dpDatePickerId"
      [(ngModel)]="startDate"
      [checked]="startDateEnabled"
      (ngModelChange)="onPepsConversionStartDateChange()"
      (didCheckboxValueChange)="conversionStartDateCheckBoxChanged($event)">
    </lv-datepicker>

  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-838">
    <div class="lv-label lv-label-field lv-label-short"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionEndDateSourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Conversion End Date
      </span>
      <lv-xl-label [field]="'PEPS_END_DATE'"></lv-xl-label>
    </div>
    <lv-datepicker
      [(ngModel)]="endDate"
      [checked]="endDateEnabled"
      (ngModelChange)="onPepsConversionEndDateChange()"
      (didCheckboxValueChange)="conversionEndDateCheckBoxChanged($event)">
    </lv-datepicker>

  </lv-flex-box>
  <lv-flex-box direction="row" data-cy="DM-846">
    <div class="lv-label lv-label-field"
      showAfter="1500"
      kendoTooltip
      [tooltipTemplate]="conversionBeforeMaturitySourceTooltip"
      filter="span:not(lv-xl-label span)">
      <span>
        Conversion Before Maturity
      </span>
      <lv-xl-label [field]="'PEPS_EARLY_CONV'"></lv-xl-label>
    </div>
    <div class="lv-input-field">
      <kendo-dropdownlist
        name="peps-conversion-before-maturity"
        [data]="conversionBeforeMaturityLookup.items"
        valueField="id"
        textField="text"
        [valuePrimitive]="true"
        [(ngModel)]="model.conversionBeforeMaturityType"
        (ngModelChange)="onPepsConversionChange()">
      </kendo-dropdownlist>
    </div>
  </lv-flex-box>
</lv-flex-box>

<ng-template #lowerStrikeSourceTooltip>
  <lv-tooltip dm="dM-841"></lv-tooltip>
</ng-template>
<ng-template #higherStrikeSourceTooltip>
  <lv-tooltip dm="dM-839"></lv-tooltip>
</ng-template>
<ng-template #maxRatioLowerStrikeSourceTooltip>
  <lv-tooltip dm="dM-842"></lv-tooltip>
</ng-template>
<ng-template #minRatioHigherStrikeSourceTooltip>
  <lv-tooltip dm="dM-840"></lv-tooltip>
</ng-template>
<ng-template #parallelPEPSSourceTooltip>
  <lv-tooltip dm="dM-843"></lv-tooltip>
</ng-template>
<ng-template #conversionRebateSourceTooltip>
  <lv-tooltip dm="dM-844"></lv-tooltip>
</ng-template>
<ng-template #conversionRebateCurrencySourceTooltip>
  <lv-tooltip dm="dM-845"></lv-tooltip>
</ng-template>
<ng-template #conversionStartDateSourceTooltip>
  <lv-tooltip dm="dM-837"></lv-tooltip>
</ng-template>
<ng-template #conversionEndDateSourceTooltip>
  <lv-tooltip dm="dM-838"></lv-tooltip>
</ng-template>
<ng-template #conversionBeforeMaturitySourceTooltip>
  <lv-tooltip dm="dM-846"></lv-tooltip>
</ng-template>

