import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, HostBinding, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { LvBaseWidgetComponent } from '@lv-analytics/lv-base-widget.component';
import { AnalyticsEvents, AnalyticsSettingsEvents, CreditSource, IPricingWidgetState, PricingEnvironmentSections } from '@lv-analytics/models';
import { IBondPricingWidgetState } from '@lv-analytics/models/bond/pricing/bond-pricing-widget-state';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { LvDataMaster } from '@lv-core-ui/models';
import { LvErrorService, ResizeHandlerService } from '@lv-core-ui/services';
import { LvDateUtil, LvFormUtil, LvUtil, constants } from '@lv-core-ui/util';
import { LvExcelService } from '@lv-excel/services';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvBondPricingUtil } from './lv-bond-pricing.util';
import { IBondValuationInputs } from '@lv-analytics/models/bond/pricing/bond-valuation-inputs';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { IBondPricing } from '@lv-analytics/models/bond/pricing/bond-pricing';
import { PricingService } from '@lv-analytics/services';
import { ISaveBondPricingRequest } from '@lv-analytics/models/bond/pricing/save-bond-pricing-request';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LvPricingSidePanelComponent } from '@lv-analytics/components/lv-pricing';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvSettingsWidgetComponent } from '@lv-core-ui/components';
import { DecimalPipe } from '@angular/common';
import { BondService } from '@lv-analytics/services/bond/bond.service';
import _ from 'lodash';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';
import { filter } from 'rxjs';

@Component({
  selector: 'lv-bond-pricing',
  templateUrl: './lv-bond-pricing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LvBondPricingComponent extends LvBaseWidgetComponent<IBondPricingWidgetState> {

  @ViewChild('bondValuationInputs', { static: true }) valuationInputsForm: NgForm;
  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild('pricingWidget', { static: true }) pricingWidget: ElementRef;
  @ViewChild(LvSettingsWidgetComponent, { static: true }) settings: LvSettingsWidgetComponent;

  @Output() didSessionUpdatedEvent: EventEmitter<void>;

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get currentEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();
    return env.id;
  }

  get editWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Edit Excel View' : 'Edit Custom View';
  }

  get saveWidgetButtonText(): string {
    return this.isOpenedFromExcel ? 'Save as Excel View' : 'Save as Default View';
  }

  get isDisabled(): boolean {
    return !this._presenter?.bondLoaded;
  }

  get creditFieldPlaceholderValue(): number | string {
    return +this.creditPlaceholder;
  }

  get isWarrningVisible(): boolean {
    return this.currentWarrnings && this.currentWarrnings.length > 0 ? true : false;
  }

  isLoading: boolean;
  pricingSection = PricingEnvironmentSections.Pricing;
  resizeHandlerView: LvBondPricingUtil;
  model: IBondValuationInputs;
  originalValue: IBondValuationInputs;
  priceSuffix: string;
  minValuationDate: Date;
  creditPlaceholder: number | string;
  currentWarrnings: string[];

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public widgetStateManagerService: WidgetStateManagerService,
    private _errorService: LvErrorService,
    private _resizeHandlerService: ResizeHandlerService,
    private _presenter: LvAnalyticsPresenter,
    private _pricingService: PricingService,
    private _destroyRef: DestroyRef,
    private _dialogService: DialogService,
    private _decimalPipe: DecimalPipe,
    private _bondService: BondService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    super(changeDetectorRef, widgetStateManagerService, getPricingInitialState(), DefaultWidgetType.BondPricing, 'Bond');
    this.model = {
      valuationDate: new Date(),
    } as IBondValuationInputs;

    // todo add check for first settlement date.
    this.minValuationDate = new Date();
    this.priceSuffix = '';
    this.instrumentType = 'Bond';
    this.didSessionUpdatedEvent = new EventEmitter();
    this.currentWarrnings = null;
  }

  // todelete
  @HostBinding('style.width')
  get flexConfig(): string {
    return '100%';
  }

  /**
   * Initializes pricing component. Subscribes to all events relevant for pricing component.
   * One of the subscribed events is used to trigger calucaltion and send log request XMl flag.
   */
  onInit() {
    this.resizeHandlerView = new LvBondPricingUtil(
      this._resizeHandlerService,
      this._changeDetectorRef,
      this.pricingWidget,
      !!this._excelSvc?.isInitialized())

    this._presenter.onModelLoading
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(isLoading => {
        this.setLoadingState(isLoading)
      });

    this._presenter.onPricingModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        if (evt) {
          this.init(this._presenter.getModelData().bondValuationSession?.pricing);
          this.resizeHandlerView.init();
          this.priceSuffix = this.getPriceSuffix();
          this.setValuationDate();
          this.setPlaceholder();
        }

        this._changeDetectorRef.detectChanges();
      });

    this._presenter.onEventPublished
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        if (evt.eventId === AnalyticsEvents.SendValuationQueryStarted) {
          this.calculateValuation(true, evt.data);
        }

        this._changeDetectorRef.detectChanges();
      });

    this._presenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.init(this._presenter.getModelData().bondValuationSession?.pricing);
      this.resizeHandlerView.init();
      this.priceSuffix = this.getPriceSuffix();
      this.setValuationDate();
      this.setPlaceholder();
      this.currentWarrnings = null;
    });

    if (!!this._presenter.getModelData()?.bondValuationSession) {
      this.init(this._presenter.getModelData().bondValuationSession?.pricing);
      this.priceSuffix = this.getPriceSuffix();

      this.setValuationDate();
      this.setPlaceholder();
    }

    this._presenter.onModelUpdated.subscribe(evt => {
      if (evt?.eventId === AnalyticsSettingsEvents.BondInstrumentUpdated
        || evt?.eventId === AnalyticsSettingsEvents.BondInstrumentSaved
        || evt?.eventId === AnalyticsSettingsEvents.BondInstrumentLoaded) {
        this.priceSuffix = this.getPriceSuffix();
        this.setPlaceholder();
        this._changeDetectorRef.detectChanges();
      }
    });

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event !== TermsChangedEvent.Other))
      .subscribe(async event => {
        await this.loadPricing(this.envSettings.getSelectedEnvironment());
        await this.setBondPricing();
      });

    this._presenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        this.setBondPricing();
      });

    this.resizeHandlerView.init();
    this.currentWarrnings = null;
  }

  init(bondPricing: IBondPricing) {
    this.isLoading = true;
    this._changeDetectorRef.detectChanges();

    this.model.price = bondPricing?.price;
    this.model.flatCreditSpread = null;
    this.isLoading = false;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on environment change and loads pricing.
   * @param environment IEnvironmentSettingsItem object.
   */
  async onChangedEnvironment(environment: IEnvironmentSettingsItem): Promise<void> {
    await this.loadPricing(environment);
  }

  /**
   * Update pricing values in bond valuation session.
   */
  updatePricingWithCalculation(shouldPerformCalculation: boolean = false) {
    this._presenter.overrideBondInSession(bondValuationSession => {
      bondValuationSession.pricing = this.model;
    }, true);

    if (!_.isEqual(this.model, this.originalValue)) {
      this.didSessionUpdatedEvent.next();
      this.originalValue = _.cloneDeep(this.model);
    }

    this._changeDetectorRef.detectChanges();
    shouldPerformCalculation ? this.calculateValuation(false, '') : null;
  }

  /**
   * Executes calculation if all required conditions are meet.
   * @param {boolean} logRequestXml true if user triggered Send Valuation Query, false otherwise
   * @param {string} valuationQueryNotes notes that user entered on triggering Send Valuation Query
   */
  async calculateValuation(logRequestXml: boolean, valuationQueryNotes: string) {
    try {
      this.isLoading = true;
      this._changeDetectorRef.detectChanges();

      const result = await this._presenter.calculateBond(logRequestXml, valuationQueryNotes, this.model);

      if (result.warning) {
        this.currentWarrnings = result.warning;
      }
      else {
        this.currentWarrnings = null;
      }

    }
    catch (error) {
      this._errorService.handleError(error);
      this.currentWarrnings = null;
    }
    finally {
      this.isLoading = false;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Loads pricing data
   * @param {IEnvironmentSettingsItem} environment pass environment settings
   */
  async loadPricing(environment: IEnvironmentSettingsItem): Promise<void> {
    try {
      this.isLoading = true;
      this._changeDetectorRef.detectChanges();

      await this._presenter.loadBondPricing(environment);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Saves pricing.
   */
  async onSaveSection() {
    try {
      // this._presenter.validateBeforeSave();
      this.isLoading = true;
      this._changeDetectorRef.detectChanges();

      if (this.valuationInputsForm.valid) {
        await this._bondService.saveBondPricing(this.getSavePricingRequest(this.currentEnvironmentId));
        this._errorService.toastrService.success(LvDataMaster.getInfo('dM-3388', { 'value': 'Pricing' }));
      }
      else {
        LvFormUtil.markAllControlsAsTouched(this.valuationInputsForm);
      }
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Gets save pricing request.
   * @param environmentId Environment ID.
   * @returns ISavePricingRequest object.
   */
  getSavePricingRequest(environmentId: string): ISaveBondPricingRequest {
    return {
      leversysLocalId: this._presenter.bondSession.leversysLocalId,
      environmentId: environmentId,
      sourceId: this._presenter.bondSession.sessionId,
      pricingSettings: {
        price: this.model.price
      } as IBondPricing
    } as ISaveBondPricingRequest;
  }

  /**
   * Loads pricing section.
   */
  onReloadSection() {
    this.loadPricing(this.envSettings.getSelectedEnvironment());
  }

  /**
   * Does custom cleanup that needs to occur when the instance is destroyed.
   */
  onDestroy(): void {

  }

  /**
   * Occurs on show edit custom view.
   */
  onShowEditCustomView() {
    const dialogRef = this._dialogService.open({
      title: 'Pricing Custom Selection',
      content: LvPricingSidePanelComponent,
    });

    const dialog = dialogRef.content.instance as LvPricingSidePanelComponent;
    dialogRef.dialog.location.nativeElement.classList.add('lv-pricing-side-panel-window');
    dialogRef.dialog.location.nativeElement.classList.add('DM-5452');

    const pricingWidgetState = LvUtil.jsonParse(LvUtil.jsonStringify(this.widgetState)) as IPricingWidgetState;
    dialog.pricingWidgetState = pricingWidgetState;
    dialog.isBondPricing = true;

    dialog.didCancelEditing.subscribe(() => {
      dialogRef.close();
    });

    dialog.didSaveChanges.subscribe((result: IBondPricingWidgetState) => {
      this.widgetState = result;
      this.widgetState.useDefaultView = false;

      this._widgetStateManagerService.saveWidgetState(this.widgetType, {
        useDefaultView: this.widgetState.useDefaultView,
        displayMarketDataOverrides: this.widgetState.displayMarketDataOverrides,
      });

      this._widgetStateManagerService.useCustomView(this.widgetType);
      this.resizeHandlerView.init();
      this._changeDetectorRef.detectChanges();
      dialogRef.close();
    });
  }

  detectChanges(): void {
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Occurs on warning clicked.
   */
  onWarningClicked() {
    const timeout = 5000 + this.currentWarrnings.length * 2000;
    this.currentWarrnings.forEach(warning => {
      this._errorService.toastrService.warning(warning, null, {
        timeOut: timeout
      });
    });
  }

  private setValuationDate(): void {
    const firstSettlementDate = LvDateUtil.parse(this._presenter.getModelData()?.bondValuationSession?.terms?.general.firstSettlementDate);
    if (firstSettlementDate > new Date()) {
      this.model.valuationDate = firstSettlementDate;
      this.minValuationDate = firstSettlementDate;
    }
    else if (!!firstSettlementDate) {
      this.minValuationDate = firstSettlementDate;
    }
    this._changeDetectorRef.detectChanges();
  }

  private setPlaceholder(): void {
    const creditValue = this._presenter.getModelData()?.bondValuationSession?.marketData?.credit;

    if (creditValue?.creditSource === CreditSource.FlatSpread && creditValue?.issuerCreditParameters.flatCreditSpread) {
      this.creditPlaceholder =
        this._decimalPipe.transform(creditValue.issuerCreditParameters.flatCreditSpread, constants.numberFormat.zeroDigits);
    }
    else {
      this.creditPlaceholder = 'Flat Credit';
    }
  }

  /**
   * Sets loading state.
   * @param isLoading Loading state.
    */
  private setLoadingState(isLoading: boolean) {
    this.envSettings.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }

  getPriceSuffix(): string {
    const terms = this._presenter.getModelData().bondValuationSession?.terms;
    if (terms) {
      return terms.general.isPriceAsPar ? 'pts' : terms.general.currencyCode;
    }

    return 'N/A'
  }

  /**
   * Set bond pricing data.
   *
   * @private
   * @memberof LvBondPricingComponent
   */
  private setBondPricing() {
    this.init(this._presenter.getModelData().bondValuationSession?.pricing);
    this.resizeHandlerView.init();
    this.priceSuffix = this.getPriceSuffix();
    this.setValuationDate();
    this.setPlaceholder();
    this._changeDetectorRef.detectChanges();
  }
}

function getPricingInitialState(): IBondPricingWidgetState {
  return {
    useDefaultView: true,
    displayMarketDataOverrides: true,
  } as IBondPricingWidgetState;
}
