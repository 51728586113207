import { Injectable } from '@angular/core';
import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';
import { IBondCredit } from './bond-credit';
import { IBondCreditParameters } from './bond-credit-parameters';
import { ICreditTermStructureItem } from '@lv-analytics/models/market-data';
import { ISaveBondCreditRequest } from './save-bond-credit-request';

/**
 * Credit mapper.
 */
@Injectable()
export class BondCreditMapper {

  /**
   * Maps save request to API.
   * @param borrowSaveRequest ICreditRequest object.
   * @returns ICreditRequest object.
   */
  mapSaveRequestToApi(creditSaveRequest: ISaveBondCreditRequest): IBondCredit {
    return this.mapCreditToApi(creditSaveRequest.credit);
  }

  /**
   * Maps credit to UI.
   * @param borrowRequest ICredit object.
   * @returns ICredit object.
   */
  mapCreditToApi(credit: IBondCredit): IBondCredit {
    return {
      ... credit,
      issuerCreditParameters: this.mapCreditParametersToApi(credit.issuerCreditParameters),
    } as IBondCredit;
  }

  /**
   * Maps credit request to be in form readable to C# code (dates)
   * @param creditRequest Credit request
   * @returns mapped request
   */
  mapCreditToUi(creditRequest: IBondCredit): IBondCredit {

    const request = {
      ... creditRequest,
      issuerCreditParameters: this.mapCreditParametersToUi(creditRequest.issuerCreditParameters),
    } as IBondCredit;

    return request;
  }

  /**
   * Maps credit parameters to API.
   * @param parameters ICreditParameters object.
   * @returns ICreditParameters object.
   */
  private mapCreditParametersToApi(parameters: IBondCreditParameters): IBondCreditParameters {

    return {
      ... parameters,
      creditTermStructure: this.mapTermStructureItemsToApi(parameters.creditTermStructure),
      creditCDSTermStructure: this.mapTermStructureItemsToApi(parameters.creditCDSTermStructure),
      creditDefaultRateTermStructure: this.mapTermStructureItemsToApi(parameters.creditDefaultRateTermStructure),
      creditSurvivalProbabilityTermStructure: this.mapTermStructureItemsToApi(parameters.creditSurvivalProbabilityTermStructure)
    } as IBondCreditParameters;
  }

  /**
   * Maps terms structure items to API.
   * @param scheduleItems List of ICreditTermStructureItem objects.
   * @returns List of ICreditTermStructureItem objects.
   */
  private mapTermStructureItemsToApi(scheduleItems: ICreditTermStructureItem[]): ICreditTermStructureItem[] {
    const scheduleItemsRequest = [] as ICreditTermStructureItem[];
    scheduleItems?.forEach(a => {
      const scheduleItemRequest = {
        date: DateExtensions.getTimeWithOffset(a.date as Date),
        upfront: a.upfront,
        value: a.value
      } as ICreditTermStructureItem;
      scheduleItemsRequest.push(scheduleItemRequest);
    });

    return scheduleItemsRequest;
  }

  /**
   * Maps credit parameters to UI.
   * @param parameters ICreditParameters object.
   * @returns ICreditParameters object.
   */
  private mapCreditParametersToUi(parameters: IBondCreditParameters): IBondCreditParameters {

    const creditParameters =  {
      ... parameters,
      creditTermStructure: this.mapTermStructureItemsToUi(parameters.creditTermStructure),
      creditCDSTermStructure: this.mapTermStructureItemsToUi(parameters.creditCDSTermStructure),
      creditDefaultRateTermStructure: this.mapTermStructureItemsToUi(parameters.creditDefaultRateTermStructure),
      creditSurvivalProbabilityTermStructure: this.mapTermStructureItemsToUi(parameters.creditSurvivalProbabilityTermStructure)

    } as IBondCreditParameters;

    return creditParameters;
  }

  /**
   * Maps terms structure items to UI.
   * @param scheduleItems List of ICreditTermStructureItem objects.
   * @returns List of ICreditTermStructureItem objects.
   */
  private mapTermStructureItemsToUi(scheduleItems: ICreditTermStructureItem[]): ICreditTermStructureItem[] {
    const scheduleItemsRequest = [] as ICreditTermStructureItem[];
    scheduleItems.forEach(a => {
      const scheduleItemRequest = {
        date: DateExtensions.getDateWithoutOffset(a.date as number),
        upfront: a.upfront,
        value: a.value
      } as ICreditTermStructureItem;
      scheduleItemsRequest.push(scheduleItemRequest);
    });

    return scheduleItemsRequest;
  }
}
