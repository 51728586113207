import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ITermStructureItem } from '../../models/market-data/yield-curve/yield-curve-term-structure';
import { LvAnalyticsError } from '../../models/errors';
import { ITenor } from '../../models/market-data/tenor';
import { IMarketData } from '../../models/market-data/market-data';
import { IDividendsSessionRequest } from '../../models/market-data/dividends/requests/dividends-session-request';
import { IInterestRate } from '../../models/market-data/interest-rates/interest-rate';
import { ISaveInterestRatesRequest } from '../../models/market-data/interest-rates/interest-rates-request';

import { IVolatility, LoadVolatilityResponse } from '../../models/market-data/volatility/volatility';
import { IVolatilitySessionRequest } from '../../models/market-data/volatility/volatility-session-request';
import { IDividends, LoadDividensdResponse } from '../../models/market-data/dividends/dividends';
import { ICalculateScheduledDividendsRequest } from '../../models/market-data/dividends/requests/calculate-scheduled-dividends-request';
import { IScheduledDividends } from '../../models/market-data/dividends/scheduled-dividends';
// tslint:disable-next-line:max-line-length
import { ICreditSessionRequest } from '../../models/market-data/credit/credit-session-request';
import { ICredit } from '../../models/market-data/credit/credit';
import { IBorrowUpdateRequest } from '../../models/market-data/borrow/borrow-request';
import { IBorrow, LoadBorrowResponse } from '../../models/market-data/borrow/borrow';
import { BorrowMapper } from '../../models/market-data/borrow/borrow-mapper';
import { IFundingSessionUpdateRequest } from '../../models/market-data/funding/funding-request';
import { IFunding } from '../../models/market-data/funding/funding';
// tslint:disable-next-line:max-line-length
import { IWitholdingTaxSessionUpdateRequest } from '../../models/market-data/witholding-tax/witholding-tax-request';
import { IWitholdingTax } from '../../models/market-data/witholding-tax/witholding-tax';
import { CreditMapper } from '../../models/market-data/credit/credit-mapper';
import { IReloadMarketDataRequest } from '../../models/market-data/request/reload-market-data-request';
import { DividendsMapper } from '../../models/market-data/dividends/dividends-mapper';
import { VolatilityMapper } from '../../models/market-data/volatility/volatility-mapper';
import { YieldCurveMapper } from '../../models/market-data/yield-curve/yield-curve-mapper';
import { IAveragingData } from '../../models/market-data/averaging-data/averaging-data';
import { IAveragingDataSessionUpdateRequest } from '../../models/market-data/averaging-data/averaging-data-request';
import { SaveMarketDataRequest } from '../../models/market-data/market-data-request';
import { ISaveSectionRequest } from '../../models/save-section-request';
import { IDataEntitlement } from 'src/app/modules/shared/interfaces/data-entitlement';
import { v4 } from 'uuid';
import { ISaveMarketDataResponse } from '../../models/response/save-market-data-response';
import { HttpClientBase } from '@lv-core-ui/api';
import { LvUtil } from '@lv-core-ui/util';
import { IMarketDataSaveSessionRequest } from '@lv-analytics/models/market-data/market-data-save-session-request';
import { ISystemYieldCurveInfo } from '@lv-analytics/models/market-data/yield-curve/system-yield-curve-info';
import { LvDateService } from '@lv-core-ui/services';
import { IOtherMarketData } from '@lv-analytics/models/market-data/other/other-market-data';
import { IOtherMarketDataSessionUpdateRequest } from '@lv-analytics/models/market-data/other/other-market-data-request';
import { IEquityValuationSession } from '@lv-analytics/models/valuation-session/equity-valuation-session';


const VALUATION_SESSION_PATH = '/valuationSession';

/**
 * Market data service.
 */
@Injectable()
export class MarketDataService extends HttpClientBase {

  constructor(
    @Inject(HttpClient) http: HttpClient,
    lvDateService: LvDateService,
    private borrowMapper: BorrowMapper,
    private creditMapper: CreditMapper,
    private dividendMapper: DividendsMapper,
    private volatilityMapper: VolatilityMapper,
    private yieldCurveMapper: YieldCurveMapper
  ) {
    super(lvDateService, http, '/analytics');
    this._svcInstanceId = v4();
  }

  get instanceId(): string {
    return this._svcInstanceId;
  }
  private _svcInstanceId: string;

  /**
   * Gets market data.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @returns IMarketData object.
   */
  async getMarketData(sessionId: string, leversysId: string): Promise<IMarketData> {
    try {
      const settings = await this.getAsync<IMarketData>({
        sessionId: sessionId,
        leversysId: leversysId        
      }, '/valuationSession/marketData');

      this.applyMarketDataTransformations(settings);

      if (!settings.dividends.dividendCurrencySelected) {
        settings.dividends.dividendCurrencySelected = settings.dividends.dividendsCcy;
      }

      if (!settings.dividends.dividendsSchedule.dividendCurrencySelected) {
        settings.dividends.dividendsSchedule.dividendCurrencySelected = settings.dividends.dividendsSchedule.currency;
      }

      return settings;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Reloads market data.
   * @param request IReloadMarketDataRequest object.
   * @returns IMarketData object.
   */
  async reloadMarketData(request: IReloadMarketDataRequest): Promise<IMarketData> {
    try {
      const settings = await this.postAsync<IMarketData>({
        sessionId: request.sessionId,
        lwsIdentifier: request.lwsIdentifier,
        sectionEnvironments: request.sectionEnvironments,
        leversysLocalId: request.leversysLocalId,
      }, '/valuationSession/reloadMarketData');

      this.applyMarketDataTransformations(settings);

      if(settings.underlyingValuationSession){
        this.mapEquitySessionToUI(settings.underlyingValuationSession);
      }

      return settings;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Saves market data.
   * @param request SaveMarketDataRequest object.
   * @returns ISaveMarketDataResponse object.
   */
  async saveMarketData(request: SaveMarketDataRequest): Promise<ISaveMarketDataResponse> {
    try {
      this.mapRequestToApi(request);

      request.sourceId = this._svcInstanceId;

      this.applyDataEntitlement(request);

      const response = await this.postAsync<ISaveMarketDataResponse>(request, '/valuationSession/saveMarketData');
      this.applyMarketDataTransformations(response.marketData);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(error.message));
    }
  }

  /**
   * Overrides all market data sections in session
   * @param lwsIdentified Lws identifier of selected instrument
   * @param sessionId Session identifier
   * @param marketData Market data model
   */
  async overrideMarketData(lwsIdentified: string, sessionId: string, marketData: IMarketData) {
    const request = {
      volatility: this.volatilityMapper.mapVolatilityToApi(marketData.volatility),
      averagingData: marketData.averagingData,
      borrow: this.borrowMapper.mapBorrowToApi(marketData.borrow),
      credit: this.creditMapper.mapCreditToApi(marketData.credit),
      dividends: this.dividendMapper.mapDividendsToApi(marketData.dividends),
      funding: marketData.funding,
      witholdingTax: marketData.witholdingTax,
      interestRates: {
        interestRates: marketData.interestRates
      } as ISaveInterestRatesRequest,
      lwsIdentifier: lwsIdentified,
      sessionId: sessionId
    } as IMarketDataSaveSessionRequest;

    await this.postAsync<IVolatilitySessionRequest>(request, VALUATION_SESSION_PATH + '/overrideMarketData');
  }

  /**
   * Override volatility.
   * @param request IVolatilitySessionRequest object.
   */
  async overrideVolatility(request: IVolatilitySessionRequest): Promise<IEquityValuationSession> {
    try {
      request.volatility = this.volatilityMapper.mapVolatilityToApi(request.volatility);

      const result = await this.postAsync<IEquityValuationSession>(request, VALUATION_SESSION_PATH + '/overrideVolatility');

      if(result){
        await this.mapEquitySessionToUI(result);
    }

    return result;

  }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  private async mapEquitySessionToUI(result: IEquityValuationSession) {
    if (result.marketData.volatility) {
      result.marketData.volatility =
        await this.volatilityMapper.mapEquityVolatilityToUi(result.marketData.volatility);
    }
    if (result.marketData.dividends) {
      result.marketData.dividends =
        this.dividendMapper.mapEquityDividendsToUi(result.marketData.dividends);
    }
    if (result.marketData.borrow.termStructureItems) {
      result.marketData.borrow.termStructureItems =
        this.borrowMapper.mapTermStructureItemsToUi(result.marketData.borrow.termStructureItems);
    }
  }

  /**
   * Loads volatility.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IVolatility object.
   */
  async loadVolatility(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<LoadVolatilityResponse> {
    try {
      const response = await this.postAsync<LoadVolatilityResponse>(null, VALUATION_SESSION_PATH + '/loadVolatility', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });

      response.volatility = this.volatilityMapper.mapVolatilityToUi(response.volatility);
      
      if(response.underlyingValuationSession){
        this.mapEquitySessionToUI(response.underlyingValuationSession);
      }

      return response;     
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides credit.
   * @param request ICreditSessionRequest object.
   */
  async overrideCredit(request: ICreditSessionRequest) {
    try {
      request = this.creditMapper.mapUpdateRequestToApi(request);
      await this.postAsync<ICreditSessionRequest>(request, VALUATION_SESSION_PATH + '/overrideCredit');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Loads credit.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns ICredit object.
   */
  async loadCredit(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<ICredit> {
    try {
      const creditSettings = await this.postAsync<ICredit>(null, VALUATION_SESSION_PATH + '/loadCredit', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });

      return this.creditMapper.mapCreditToUi(creditSettings);
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Loads interest rates.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IInterestRate object.
   */
  async loadInterestRates(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<IInterestRate> {
    try {
      const interestRateSettings = await this.postAsync<IInterestRate>(null, VALUATION_SESSION_PATH + '/loadInterestRates', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });

      return interestRateSettings;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides interest rates.
   * @param request ISaveInterestRatesRequest object.
   */
  async overrideInterestRates(request: ISaveInterestRatesRequest) {
    try {
      await this.postAsync<ISaveInterestRatesRequest>(request, VALUATION_SESSION_PATH + '/overrideInterestRates');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets all system yield curves.
   * @returns List of ISystemYieldCurveInfo objects.
   */
  async GetAllSystemYieldCurves(): Promise<ISystemYieldCurveInfo[]> {
    try {
      const result = await this.getAsync<ISystemYieldCurveInfo[]>(null, '/yieldcurves/all');

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets system yield curve.
   * @param currencyCode Currency code.
   * @param id Identifier.
   * @returns List of ITermStructureItem objects.
   */
  async getSystemYieldCurve(currencyCode: string, id: number): Promise<ITermStructureItem[]> {
    try {
      const systemYieldCurve = await this.getAsync<ITermStructureItem[]>({
        currencyCode: currencyCode,
        id: id
      }, '/yieldcurves/system');

      return this.yieldCurveMapper.mapYieldCurveToUi(systemYieldCurve);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets custom yield curve.
   * @param currencyCode Currency code.
   * @param environmentId Environment ID.
   * @param systemYieldCurveId System yield curve ID.
   * @returns ITermStructureItem object.
   */
  async getCustomYieldCurve(currencyCode: string, environmentId: string): Promise<ITermStructureItem[]> {
    try {
      const customYieldCurve = await this.getAsync<ITermStructureItem[]>({
        currencyCode: currencyCode,
        environmentId: environmentId,
      }, '/yieldcurves/custom');

      return this.yieldCurveMapper.mapYieldCurveToUi(customYieldCurve);
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets all tenors.
   * @returns List of ITenor objects.
   */
  async getAllTenors(): Promise<ITenor[]> {
    try {
      const result = await this.getAsync<ITenor[]>(null, '/yieldcurves/tenors');

      result.sort(LvUtil.sortBy('code', false, this.convertTenorToNumber.bind(this)));

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets available system yield curves.
   * @param currencyCode Currency code.
   * @returns List of ISystemYieldCurveInfo objects.
   */
  async getAvailableSystemYieldCurves(currencyCode: string): Promise<ISystemYieldCurveInfo[]> {
    try {
      return await this.getAsync<ISystemYieldCurveInfo[]>({
        currencyCode: currencyCode
      }, '/yieldcurves/available');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets scheduled dividends.
   * @param dividend ICalculateScheduledDividendsRequest object.
   * @returns IScheduledDividends object.
   */
  async getScheduledDividends(dividend: ICalculateScheduledDividendsRequest): Promise<IScheduledDividends> {
    try {
      const scheduledDividends =
        await this.postAsync<IScheduledDividends>(dividend, '/dividends/calculateScheduledDividends');

      return scheduledDividends;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Gets dividends used.
   * @param dividend ICalculateScheduledDividendsRequest object.
   * @returns IScheduledDividends object.
   */
  async getDividendsUsed(dividend: ICalculateScheduledDividendsRequest): Promise<IScheduledDividends> {
    try {
      const scheduledDividends =
        await this.postAsync<IScheduledDividends>(dividend, '/dividends/calculateDisplayDividendsUsed');

      return scheduledDividends;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Override dividends.
   * @param request IDividendsSessionRequest objects.
   */
  async overrideDividends(request: IDividendsSessionRequest): Promise<IEquityValuationSession> {
    try {
      const result = await this.postAsync<IEquityValuationSession>(request, VALUATION_SESSION_PATH + '/overrideDividends');

      if(result){
        this.mapEquitySessionToUI(result);
      }

      return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Loads dividends.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IDividends object.
   */
  async loadDividends(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<LoadDividensdResponse> {
    try {
      const response = await this.postAsync<LoadDividensdResponse>(null, VALUATION_SESSION_PATH + '/loadDividends', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });

      if(response.underlyingValuationSession){
        this.mapEquitySessionToUI(response.underlyingValuationSession);
      }

      response.dividends = this.dividendMapper.mapDividendsToUi(response.dividends);

      return response;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides borrow.
   * @param request IBorrowUpdateRequest object.
   */
  async overrideBorrow(request: IBorrowUpdateRequest): Promise<IEquityValuationSession> {
    try {
      request = this.borrowMapper.mapUpdateRequestToApi(request);
      const result = await this.postAsync<IEquityValuationSession>(request, VALUATION_SESSION_PATH + '/overrideBorrow');     

      if(result){
        await this.mapEquitySessionToUI(result);
    }

    return result;
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Loads borrow.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IBorrow object.
   */
  async loadBorrow(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<LoadBorrowResponse> {
    try {
      const response = await this.postAsync<LoadBorrowResponse>(null, VALUATION_SESSION_PATH + '/loadBorrow', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });

      if(response.underlyingValuationSession){
        await this.mapEquitySessionToUI(response.underlyingValuationSession);
      }

      response.borrow = this.borrowMapper.mapBorrowToUi(response.borrow);

      return response;
    }
    catch (error) {
      throw this.handleError(error, (e) => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides funding.
   * @param request IFundingSessionUpdateRequest object.
   */
  async overrideFunding(request: IFundingSessionUpdateRequest) {
    try {
      await this.postAsync<IFundingSessionUpdateRequest>(request, VALUATION_SESSION_PATH + '/overrideFunding');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Loads funding.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IFunding object.
   */
  async loadFunding(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<IFunding> {
    try {
      return await this.postAsync<IFunding>(null, VALUATION_SESSION_PATH + '/loadFunding', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides averaging data.
   * @param request IAveragingDataSessionUpdateRequest object.
   */
  async overrideAveragingData(request: IAveragingDataSessionUpdateRequest) {
    try {
      await this.postAsync<IAveragingDataSessionUpdateRequest>(request, VALUATION_SESSION_PATH + '/overrideAveragingData');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Load averaging data.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IAveragingData object.
   */
  async loadAveragingData(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<IAveragingData> {
    try {
      return await this.postAsync<IAveragingData>(null, VALUATION_SESSION_PATH + '/loadAveragingData', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides witholding tax.
   * @param request IWitholdingTaxSessionUpdateRequest object.
   */
  async overrideWitholdingTax(request: IWitholdingTaxSessionUpdateRequest) {
    try {
      await this.postAsync<IWitholdingTaxSessionUpdateRequest>(request, VALUATION_SESSION_PATH + '/overrideWitholdingTax');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Load witholding tax.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns IWitholdingTax object.
   */
  async loadWitholdingTax(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<IWitholdingTax> {
    try {
      return await this.postAsync<IWitholdingTax>(null, VALUATION_SESSION_PATH + '/loadWitholdingTax', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Overrides witholding tax.
   * @param request IWitholdingTaxSessionUpdateRequest object.
   */
  async overrideOtherMarketData(request: IOtherMarketDataSessionUpdateRequest) {
    try {
      await this.postAsync<IOtherMarketDataSessionUpdateRequest>(request, VALUATION_SESSION_PATH + '/overrideOtherMarketData');
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }
  
  /**
   * Load other data.
   * @param sessionId Session ID.
   * @param lwsIdentifier LWS identifier.
   * @param environmentId Environment ID.
   * @returns Other data object.
   */
  async loadOtherMarketData(sessionId: string, lwsIdentifier: string, environmentId: string): Promise<IOtherMarketData> {
    try {
      return await this.postAsync<IOtherMarketData>(null, VALUATION_SESSION_PATH + '/loadOtherMarketData', {
        sessionId: sessionId,
        lwsIdentifier: lwsIdentifier,
        environmentId: environmentId
      });
    }
    catch (error) {
      throw this.handleError(error, e => new LvAnalyticsError(e.message));
    }
  }

  /**
   * Converts tenor code to number.
   * @param tenorCode Tenor code.
   * @returns Tenor code converted to number.
   */
  public convertTenorToNumber(tenorCode: any): number {
    const factor = tenorCode[tenorCode.length - 1];
    const value = Number.parseInt(tenorCode.slice(0, -1), 10);

    switch (factor) {
      case 'D':
      return value * 1;
      case 'W':
      return value * 100;
      case 'M':
      return value * 1000;
      case 'Y':
      return value * 12000;
    }

    return 0;
  }

  /**
   * Applies market data transformations.
   * @param settings IMarketData object.
   */
  private applyMarketDataTransformations(settings: IMarketData) {
    settings.borrow = this.borrowMapper.mapBorrowToUi(settings.borrow);

    settings.credit = this.creditMapper.mapCreditToUi(settings.credit);

    settings.dividends = this.dividendMapper.mapDividendsToUi(settings.dividends);

    settings.volatility = this.volatilityMapper.mapVolatilityToUi(settings.volatility);
  }
  
  /**
   * Applies data entitlement.
   * @param request ISaveSectionRequest or IDataEntitlement object.
   */
  private applyDataEntitlement(request: ISaveSectionRequest | IDataEntitlement) {
    request.sourceId = this._svcInstanceId;
  }

  /**
   * Maps request to API.
   * @param request SaveMarketDataRequest object.
   */
  private mapRequestToApi(request: SaveMarketDataRequest) {
    request.borrow = this.borrowMapper.mapSaveRequestToApi(request.borrow);
    request.credit = this.creditMapper.mapSaveRequestToApi(request.credit);
    request.dividends.dividends = this.dividendMapper.mapDividendsToApi(request.dividends.dividends);
    request.volatility.volatility = this.volatilityMapper.mapVolatilityToApi(request.volatility.volatility);
  }
}
