export enum CoPayCouponType {
	PercOfMarketPrice = 'PercOfMarketPrice',
	MaxOfDivinidendsAndPercOfMarketPrice = 'MaxOfDivinidendsAndPercOfMarketPrice'
}
export enum CoPayTriggerType {
	PercOfPar = 'PercOfPar',
	PercOfAccretedValue = 'PercOfAccretedValue'
}

export enum PikCouponType {
	None = 'None',
	PIKOnly = 'PIKOnly',
	PIKPlusCash = 'PIKPlusCash',
	PIKOrCash = 'PIKOrCash',
	Custom = 'Custom',
	PIKPlusCashOrCash = 'PIKPlusCashOrCash',
	PIKPlusCashOrPIK = 'PIKPlusCashOrPIK',
}

export enum PikCouponTypeDescription {
	None = 'None',
	PIKOnly = 'PIK Only',
	PIKPlusCash = 'PIK + Cash',
	PIKOrCash = 'PIK or Cash',
	Custom = 'Custom',
	PIKPlusCashOrCash = 'PIK or Floating',
	PIKPlusCashOrPIK = 'PIK + Floating'
}

export enum PikType {
	PIKOnly = 'PIKOnly',
	PIKPlusCash = 'PIKPlusCash',
	PIKOrCash = 'PIKOrCash',
	PIKPlusCashOrPIK = 'PIKPlusCashOrPIK',
	PIKPlusCashOrCash = 'PIKPlusCashOrCash'
}

export enum PikTypeDescription {
	PIKOnly = 'PIK Only',
	PIKPlusCash = 'PIK + Cash',
	PIKOrCash = 'PIK or Cash',
	PIKPlusCashOrPIK = 'PIK + Cash or PIK',
	PIKPlusCashOrCash = 'PIK + Cash or Cash'
}

export enum CouponReferenceTenor {
	OneMonth = 'OneMonth',
	ThreeMonth = 'ThreeMonth',
	SixMonth = 'SixMonth',
	TwelveMonth = 'TwelveMonth',
	FiveYears = 'FiveYears',
	TenYears = 'TenYears'
}

export enum IndexType {
	Equity = 'Equity',
	Volatility = 'Volatility'
}