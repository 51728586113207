import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { LvBondTermsUtil } from './lv-bond-terms.util';
import { LvDateService, LvErrorService, ResizeHandlerService } from '@lv-core-ui/services';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { LvBondTermsPresenter } from './lv-bond-terms.presenter';
import { BondTermsDocument, getDefaultBondDocument } from '../../models/bond-terms/BondTermsDocument';
import { BondTermsSettingsService } from '@lv-custom-instruments/services/bond-terms-settings/bond-terms-settings.service';
import { BondTermsService } from '../../services/bond-terms.services';
import { InstrumentsService } from '@lv-reference-data/instruments.service';
import { IBondDto } from '@lv-reference-data/bond-dto';
import { getFirstSettlementDate } from '@lv-convertible-bond/helpers/date-time-helper';
import { BondTermsSectionEvent } from '../../models';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { LvDataMaster } from '@lv-core-ui/models';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LvBondPutsComponent } from './lv-bond-puts/lv-bond-puts.component';
import { LvBondCallsComponent } from './lv-bond-calls/lv-bond-calls.component';
import { LvBondCouponComponent } from './lv-bond-coupon/lv-bond-coupon.component';
import { LvSidePanelComponent } from '@lv-core-ui/components';
import { v4 } from 'uuid';
import * as _ from 'lodash';

@Component({
  selector: 'lv-bond-terms',
  templateUrl: './lv-bond-terms.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LvBondTermsPresenter
  ]
})

export class LvBondTermsComponent implements OnInit, OnDestroy {
  @ViewChild('tabStrip', { static: true }) tabStrip: TabStripComponent;
  @ViewChild('putComponent', { static: false }) putComponent: LvBondPutsComponent;
  @ViewChild('callComponent', { static: false }) callComponent: LvBondCallsComponent;
  @ViewChild('couponComponent', { static: false }) couponComponent: LvBondCouponComponent;
  @ViewChild(LvSidePanelComponent) settingsPanel: LvSidePanelComponent;

  @Output() didInstrumentNameChanged: EventEmitter<string>;

  get isMakeWholeEnabled() {
    return this._presenter.isMakeWholeEnabled();
  }

  get leversysLocalId() {
    return this._leversysLocalId;
  }

  get isBondSelected(): boolean {
    return !!this._analyticsPresenter.bondSession?.terms || this._analyticsPresenter.isFirstLoadOfSession;
  }

  isFixDates: boolean;
  isFixDatesVisible: boolean;
  fixDatesId: string;
  updateDatesId: string;

  isLoading: boolean;
  instrumentType: string;
  public lvBondTermsUtil: LvBondTermsUtil;
  public bondDocument: BondTermsDocument;
  private _resizeHandlerService: ResizeHandlerService;
  private _leversysLocalId: string;
  private _destroyRef = inject(DestroyRef)

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvBondTermsPresenter,
    private _lvDateService: LvDateService,
    private _bondTermsSettings: BondTermsSettingsService,
    private _lvBondTermsService: BondTermsService,
    private _instrumentService: InstrumentsService,
    private _errorService: LvErrorService,
    private _analyticsPresenter: LvAnalyticsPresenter
  ) {
    this.isLoading = false;
    this.instrumentType = 'Bond';
    this.lvBondTermsUtil = {} as LvBondTermsUtil;
    this._resizeHandlerService = new ResizeHandlerService();

    this.didInstrumentNameChanged = new EventEmitter<string>();

    this.fixDatesId = v4();
    this.updateDatesId = v4();
    this.isFixDates = false;
    this.isFixDatesVisible = false;

    this.bondDocument = getDefaultBondDocument(_lvDateService);
    this._presenter.initModel(this.bondDocument);
    this._leversysLocalId = null;
  }

  async ngOnInit() {
    this.lvBondTermsUtil = new LvBondTermsUtil(this._resizeHandlerService, this.tabStrip, this._changeDetectorRef);
    this.lvBondTermsUtil.startListening();
    this.instrumentType = 'Bond';

    this._presenter.onModelUpdated.pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event !== BondTermsSectionEvent.ModelLoadedEvent))
      .subscribe(async () => {
        const sessionTerms = this._presenter.getModel();
        const isDraftReady = !this._leversysLocalId && BondTermsDocument.isDraftReady(sessionTerms);

        if (isDraftReady && !sessionTerms.general.name.includes('(Draft)')) {
          try {
            this._analyticsPresenter.loadBondDraftInSession(session => {
              session.terms = sessionTerms;
              return session;
            });

            this._leversysLocalId = 'draft';
            this.didInstrumentNameChanged.next(`${sessionTerms.general.name} (Draft)`);
          }
          catch (error) {
            this._errorService.handleError(error);
          }
        }
        else {
          try {
            await this._analyticsPresenter.overrideBondInSession(session => {
              session.terms = sessionTerms;
            });
          }
          catch (error) {
            this._errorService.handleError(error);
          }
        }
      });

    this._presenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(event => {
        if (this._leversysLocalId) {
          this._analyticsPresenter.onTermsChanged(event);
        }
      });

    this._analyticsPresenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.loadBondTerms(true);
    });

    await this.loadBondTerms(false);
  }

  onReload() {
    this.loadBondTerms(true);
  }

  /**
   * Occurs on show settings.
   */
  onShowSettings() {
    this.settingsPanel.togglePanel();
  }

  /**
   * Load bond terms
   */
  async loadBondTerms(reload: boolean): Promise<void> {
    try {
      this.setLoadingState(true);

      if (!this._analyticsPresenter.bondSession?.leversysLocalId) {
        const firstSettlement = getFirstSettlementDate(this._lvDateService.getUtcDate(new Date()));
        const bondTerms = await this._bondTermsSettings.createTermsFromSettings(firstSettlement, null);
        const bondTermsSettings = await this._bondTermsSettings.getBondTermsSettings();
        this._lvBondTermsService.setBondTermsFromSettings(bondTermsSettings);

        this._lvBondTermsService.mapBondToUi(bondTerms);

        this.bondDocument.general = bondTerms.general;
        this.bondDocument.accretion = bondTerms.accretion;
        this.bondDocument.call = bondTerms.call;
        this.bondDocument.call.callMakeWhole = bondTerms.call.callMakeWhole;
        this.bondDocument.put = bondTerms.put;
        this.bondDocument.coupon = bondTerms.coupon;
        this.bondDocument.cleanUpCall = bondTerms.cleanUpCall;
        this.bondDocument.identifiers = [];
      }
      else {
        if (reload) {
          await this._analyticsPresenter.loadBondSession();
        }
        else {
          this.bondDocument = this._analyticsPresenter.bondSession.terms;
          this._lvBondTermsService.mapBondToUi(this.bondDocument);
        }

        this.bondDocument = this._analyticsPresenter.bondSession.terms;
        this._leversysLocalId = this._analyticsPresenter.bondSession.leversysLocalId;
      }

      this._presenter.initModel(this.bondDocument);

      this._presenter.updateModel((terms: BondTermsDocument) => {
        terms = this.bondDocument;
      }, BondTermsSectionEvent.ModelLoadedEvent,
        'other');
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Save bond terms.
   */
  async onSaveInstrument() {
    try {
      this.setLoadingState(true);

      this.putComponent.applyScheduleChanges();
      this.callComponent.applyScheduleChanges();
      this.couponComponent.applyScheduleChanges();

      const document = this._presenter.getModel();
      const documentClone = _.cloneDeep(document);

      this._lvBondTermsService.mapToApi(documentClone);

      const bondDto = {
        terms: documentClone,
      } as IBondDto;

      if (this._leversysLocalId && this._leversysLocalId !== 'draft') {
        bondDto.leversysLocalId = this._leversysLocalId;
      }

      const savedBond = await this._instrumentService.saveBond(bondDto);

      this._leversysLocalId = savedBond.leversysLocalId;

      await this._analyticsPresenter.setupBondValuationSessionAndInstrument(bondValuationSession => {
        bondValuationSession.terms = savedBond.terms;
        bondValuationSession.leversysLocalId = savedBond.leversysLocalId;
      });

      this.didInstrumentNameChanged.next(bondDto.terms.general.name);

      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1826'));

    }
    catch (err) {
      this._errorService.toastrService.error(err);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  ngOnDestroy(): void {
    this.lvBondTermsUtil.stopListening();
  }

  /**
   * Gets custom instrument tooltip ID.
   * @param element HTML element.
   * @param sectionId Section ID.
   * @returns Section ID.
   */
  getPrivateInstrumentTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }
  private setLoadingState(loading: boolean): void {
    this.isLoading = loading;
    this._changeDetectorRef.detectChanges();
  }
}
