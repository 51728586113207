import { QuickAndFullTermsDocument } from '@lv-convertible-bond/models';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { getDateValue } from './date-time-helper';
import { DayCountDescription, FrequencyDescription, NoticePeriod, PutScheduleItem, PutValueTypeDescription } from '@lv-instrument-common/index';
import { LvDateUtil } from '@lv-core-ui/util';

export class TermsPutMapper {
	mapPut(fieldDictionary: any, model: QuickAndFullTermsDocument, initialModel: QuickAndFullTermsDocument, isPrivateInstrument: boolean) {

		fieldDictionary['PUTTABLE'] = {
			mapFn: (value: any) => {
				model.fullTerms.issueAndRedemption.isPuttable = value;
			},
			reverseMapFn: () => {
				return model.fullTerms.issueAndRedemption.isPuttable;
			},
			type: ExcelFieldDataTypes.String
		};

		fieldDictionary['PUT_VALUE_TYPE'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}
				model.fullTerms.put.valueType = value;
			},
			reverseMapFn: () => {
        if (!model.fullTerms.put) {
          return null;
        }

        return PutValueTypeDescription[model.fullTerms.put.valueType];
			},
			type: ExcelFieldDataTypes.Enum,
			enumType: PutValueTypeDescription
		};

		fieldDictionary['PUT_YLD_FRQ'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.yieldFrequency = value;
			},
			reverseMapFn: () => {
        if (!model.fullTerms.put) {
          return null;
        }

        return FrequencyDescription[model.fullTerms.put.yieldFrequency];
			},
			type: ExcelFieldDataTypes.Enum,
			enumType: FrequencyDescription
		};

		fieldDictionary['PUT_DAY_COUNT'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.yieldDaysCount = value;
			},
			reverseMapFn: () => {
        if (!model.fullTerms.put) {
          return null;
        }

        return DayCountDescription[model.fullTerms.put.yieldDaysCount];
			},
			type: ExcelFieldDataTypes.Enum,
			enumType: DayCountDescription
		};

		fieldDictionary['PUT_KEEP_ACCRD'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.keepAccrued = value;
			},
			reverseMapFn: () => {
				return model.fullTerms.put?.keepAccrued;
			},
			type: ExcelFieldDataTypes.String
		};

		fieldDictionary['PUT_FRFT_CPN'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.forfeitCoupon = value;
			},
			reverseMapFn: () => {
				return model.fullTerms.put?.forfeitCoupon;
			},
			type: ExcelFieldDataTypes.String
		};

		fieldDictionary['PUT_NTC_DAYS'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.notice = value;
			},
			reverseMapFn: () => {
				return model.fullTerms.put?.notice;
			},
			type: ExcelFieldDataTypes.Number
		};

		fieldDictionary['PUT_NTC_DAYS_TYPE'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.noticePeriod = value;
			},
			reverseMapFn: () => {
        if (!model.fullTerms.put) {
          return null;
        }

        return NoticePeriod[model.fullTerms.put.noticePeriod];
			},
			type: ExcelFieldDataTypes.Enum,
			enumType: NoticePeriod
		};

		fieldDictionary['PUT_DRNG_PERIOD'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.puttableDuringPeriod = value;
			},
			reverseMapFn: () => {
				return model.fullTerms.put?.puttableDuringPeriod;
			},
			type: ExcelFieldDataTypes.String
		};

		fieldDictionary['PUT_SCHED_RANGE'] = {
			mapFn: (value: any) => {
				if (!model.fullTerms.put) {
					model.fullTerms.put = initialModel.fullTerms.put;
				}

				model.fullTerms.put.scheduleItems = value.map((el: PutScheduleItem) => {
					el.startDate = LvDateUtil.parse(el.startDate);
					el.endDate = el.endDate ? LvDateUtil.parse(el.endDate) : null;
					return el;
				});
			},
			reverseMapFn: () => {
        if (!(model.fullTerms.put && model.fullTerms.put.scheduleItems)) {
          return null;
        }

        const value = model.fullTerms.put.scheduleItems.map(el => ({
          startDate: getDateValue(el.startDate),
          endDate: el.endDate ? getDateValue(el.endDate) : null,
          price: el.price,
          trigger: el.trigger
        })).sortBy('startDate');
        return value;
			},
			type: ExcelFieldDataTypes.Schedule
		};
	}

	setEndDateInSchedule(model: QuickAndFullTermsDocument) {
		const schedule = model.fullTerms.put?.scheduleItems;
		if (schedule?.values) {
			schedule.forEach((item) => {
				item.endDate = model.fullTerms.put.puttableDuringPeriod ? item.endDate : null;
				item.trigger = item.trigger > 0 ? item.trigger : 0;
			});
		}
	}
}