import { IMarketData } from './../../models/market-data/market-data';
import { VolatilitySurfaceRiskyType } from './../../models/market-data/volatility/volatility-enums';
import { IVolatilitySurface } from './../../models/market-data/volatility/volatility-surface';

import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';

/**
 * Maps volatility.
 * @param fieldDictionary Field dictionary.
 * @param model IMarketData object.
 */
export function mapVolatility(fieldDictionary: any, model: IMarketData): void {
  fieldDictionary['VOL_FLAT'] = {
    mapFn: (value: any) => {
      model.volatility.flatVol = value;
    },
    reverseMapFn: () => {
      return model.volatility.flatVol;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['VOL_UP'] = {
    mapFn: (value: any) => {
      model.volatility.upsideVol = value;
    },
    reverseMapFn: () => {
      return model.volatility.upsideVol;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['VOL_DOWN'] = {
    mapFn: (value: any) => {
      model.volatility.downsideVol = value;
    },
    reverseMapFn: () => {
      return model.volatility.downsideVol;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['VOL_LCL'] = {
    mapFn: (value: any) => {
      model.volatility.useLocalVolFromTwoVols = value;
    },
    reverseMapFn: () => {
      return model.volatility.useLocalVolFromTwoVols;
    },
    type: ExcelFieldDataTypes.String
  };

  fieldDictionary['VOL_RISK_TYPE'] = {
    mapFn: (value: any) => {
      model.volatility.riskyType = value;
    },
    reverseMapFn: () => {
      return VolatilitySurfaceRiskyType[model.volatility.riskyType];
    },
    type: ExcelFieldDataTypes.Enum,
    enumType: VolatilitySurfaceRiskyType
  };

  fieldDictionary['VOL_FX'] = {
    mapFn: (value: any) => {
      model.volatility.fxVolatility = value;
    },
    reverseMapFn: () => {
      return model.volatility.fxVolatility;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['VOL_FX_CORR'] = {
    mapFn: (value: any) => {
      model.volatility.fxCorrelation = value;
    },
    reverseMapFn: () => {
      return model.volatility.fxCorrelation;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['VOL_SURF_BASE_DT'] = {
    mapFn: (value: any) => {
      if (!model.volatility.volatilitySurface) {
        model.volatility.volatilitySurface = {} as IVolatilitySurface;
      }

      model.volatility.volatilitySurface.updateDate = value;
    },
    reverseMapFn: () => {
      return model.volatility.volatilitySurface.updateDate;
    },
    type: ExcelFieldDataTypes.Date
  };

  fieldDictionary['VOL_SURF_PRICE'] = {
    mapFn: (value: any) => {
      if (!model.volatility.volatilitySurface) {
        model.volatility.volatilitySurface = {} as IVolatilitySurface;
      }

      model.volatility.volatilitySurface.refUnderlying = value;
    },
    reverseMapFn: () => {
      return model.volatility.volatilitySurface.refUnderlying;
    },
    type: ExcelFieldDataTypes.Number
  };

  fieldDictionary['VOL_USE_STRIKE'] = {
    mapFn: (value: any) => {
      if (!model.volatility.volatilitySurface) {
        model.volatility.volatilitySurface = {} as IVolatilitySurface;
      }

      model.volatility.volatilitySurface.useStrikeVol = value;
    },
    reverseMapFn: () => {
      return model.volatility.volatilitySurface.useStrikeVol;
    },
    type: ExcelFieldDataTypes.String
  };

  fieldDictionary['VOL_SCHED_RANGE'] = {
    mapFn: (value: any) => {
      model.volatility.volatilityTermStructureSchedule = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getDateInUtcFromUtcString(item.date)
        };
      });
    },
    reverseMapFn: () => {
      const result = model.volatility.volatilityTermStructureSchedule.map(item => {
        return {
          ...item,
          date: DateExtensions.toOADate(item.date as Date)
        };
      });
      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };

  fieldDictionary['VOL_SURF_RANGE'] = {
    mapFn: (value: any) => {
      model.volatility.volatilitySurface.volatilitySurfacePoints = value.map(item => {
        return {
          ...item,
          date: DateExtensions.getTimeWithOffset(LvDateUtil.parse(item.date))
        };
      });
    },
    reverseMapFn: () => {
      const result = model.volatility.volatilitySurface.volatilitySurfacePoints.filter(x => !!x.value).map(item => {
        return {
          date: DateExtensions.toOADate(new Date(item.date)),
          argument: item.argument / 100,
          value: item.value
        };
      });

      return result;
    },
    type: ExcelFieldDataTypes.Schedule
  };
}
