import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { IEnvironmentSettingsItem, LvEnvironmentSettingsComponent } from '@lv-analytics/components/lv-environment-settings/lv-environment-settings.component';
import { LvAnalyticsPresenter } from '@lv-analytics/lv-analytics.presenter';
import { LvBaseWidgetComponent } from '@lv-analytics/lv-base-widget.component';
import { PricingEnvironmentSections } from '@lv-analytics/models';
import { IBondModelCustomizationState, getInitialBondModelCustomizationState } from '@lv-analytics/models/model-customization/bond/bond-model-customization-state';
import { WidgetStateManagerService } from '@lv-application-settings/services';
import { LvErrorService, ResizeHandlerService } from '@lv-core-ui/services';
import { DefaultWidgetType } from '@lv-shared/models';
import { LvBondModelCustomizationView } from './lv-bond-model-customization.view';
import { LvDataMaster } from '@lv-core-ui/models';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LvModelCustomizationCustomSelectionComponent } from '../../lv-model-customization-custom-selection/lv-model-customization-custom-selection.component';
import * as _ from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { TermsChangedEvent } from '@lv-analytics/models/events/terms-changed-event';

@Component({
  selector: 'lv-bond-model-customization',
  templateUrl: './lv-bond-model-customization.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LvBondModelCustomizationComponent extends LvBaseWidgetComponent<IBondModelCustomizationState> {
  @ViewChild(LvEnvironmentSettingsComponent, { static: true }) envSettings: LvEnvironmentSettingsComponent;
  @ViewChild('bondModelCustomizationContent', { static: true }) modelCustomizationContent: ElementRef;

  get isModelCustomizationDisabled(): boolean {
    return !this._analyticsPresenter.bondSession?.modelCustomization;
  }

  get isCallable(): boolean {
    return !!this._analyticsPresenter.bondSession?.terms?.general.isCallable;
  }

  get isPuttable(): boolean {
    return !!this._analyticsPresenter.bondSession?.terms?.general.isPuttable;
  }

  isLoading: boolean;
  modelCustomizationSection = PricingEnvironmentSections.ModelSettings;
  view: LvBondModelCustomizationView;

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _widgetStateManagerService: WidgetStateManagerService,
    private _analyticsPresenter: LvAnalyticsPresenter,
    private _errorService: LvErrorService,
    private _resizeHandlerService: ResizeHandlerService,
    private _dialogService: DialogService,
    private _destroyRef: DestroyRef,
  ) {
    super(
      _changeDetectorRef,
      _widgetStateManagerService,
      getInitialBondModelCustomizationState(),
      DefaultWidgetType.BondModelCustomization
    );

    this.isLoading = false;
    this.view = new LvBondModelCustomizationView(this.widgetState);
    this.view.init();
  }

  onInit(): void {
    this._resizeHandlerService.listenTo(this.modelCustomizationContent, elementRect => {
      if (elementRect.width > 0) {
        this.view.onResize(elementRect.width, this.widgetState);
      }
      this._changeDetectorRef.detectChanges();
    });

    this._analyticsPresenter.onAnalyticsSettingsUpdated.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.view.init(this._analyticsPresenter.bondSession?.modelCustomization);
      this._changeDetectorRef.detectChanges();
    });

    this._analyticsPresenter.onModelUpdated
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(evt => {
        this.view.init(this._analyticsPresenter.bondSession?.modelCustomization);
        this._changeDetectorRef.detectChanges();
      });

    this._analyticsPresenter.termsChanged
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(filter(event => event === TermsChangedEvent.PriorityFields))
      .subscribe(async event => {
        await this.onReloadSection();
      });

    if (this._analyticsPresenter.bondSession?.modelCustomization) {
      this.view.init(this._analyticsPresenter.bondSession?.modelCustomization);
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Occurs on change environment.
   * @param environment IEnvironmentSettingsItem object.
   */
  onChangeEnvironment(environment: IEnvironmentSettingsItem) {
    this._analyticsPresenter.setModelCustomizationEnv(environment, 'Bond');
    this.onReloadSection();
  }

  /**
   * Occurs on reload section.
   */
  async onReloadSection() {
    try {
      this.setLoadingState(true);

      await this._analyticsPresenter.reloadBondModelCustomization();
      this.loadModelCustomization();
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on model customization change and updates model customization.
   */
  async onModelCustomizationChange() {
    await this.updateModelCustomization();
  }

  /**
   * Updates model customization.
   */
  async updateModelCustomization() {
    try {
      this.setLoadingState(true);

      await this._analyticsPresenter.overrideBondInSession(bondSession => {
        bondSession.modelCustomization = this.view.model;
      }, true);
    }
    catch (e) {
      this._errorService.handleError(e);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on save section.
   */
  async onSaveSection() {
    try {
      this.setLoadingState(true);

      await this._analyticsPresenter.saveModelCustomization(this.getSelectedEnvironmentId());
      this._errorService.toastrService.success(LvDataMaster.getInfo('dM-1822'), 'Model Customization Settings');
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.setLoadingState(false);
    }
  }

  /**
   * Occurs on edit custom view.
   */
  onEditCustomView() {
    const dialogRef = this._dialogService.open({
      title: 'Model Customization Custom Selection',
      content: LvModelCustomizationCustomSelectionComponent,
      width: 486
    });

    const dialog = (dialogRef.content.instance as LvModelCustomizationCustomSelectionComponent);
    dialogRef.dialog.location.nativeElement.classList.add('DM-5450');

    this.widgetState.instrumentType = 'Bond';
    dialog.modelCustomizationState = _.cloneDeep(this.widgetState);

    dialog.didSave.subscribe(
      (updatedState) => {
        this.widgetState = updatedState;
        this.widgetState.useDefaultView = false;
        this._widgetStateManagerService.saveWidgetState(this.widgetType, this.widgetState);
        this._widgetStateManagerService.useCustomView(this.widgetType);
        this.view.updateModelCustomizationState(this.widgetState);
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  /**
   * Loads model customization.
   */
  private async loadModelCustomization() {
    try {
      this.isLoading = true;
      this.view.init(this._analyticsPresenter.bondModelCustomization);
    }
    catch (error) {
      this._errorService.handleError(error);
    }
    finally {
      this.isLoading = false;
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Loads state.
   * @param isLoading Loading state.
   */
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
    this.envSettings.setLoadingState(isLoading);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Gets selected environment ID.
   * @returns Selected environment ID.
   */
  getSelectedEnvironmentId(): string {
    const env = this.envSettings.getSelectedEnvironment();

    if (env) {
      return env.id;
    }

    return null;
  }

  onDestroy(): void { }
}
