/**
 * Buisiness Day Convention description.
 */
export enum BusinessDayConventionDescription {
    None = 'None',
    Previous = 'Previous',
    Following = 'Following',
    ModifiedFollowing = 'Modified Following'
  }
  
/**
 * Buisiness Day Convention.
 */
export enum BusinessDayConvention {
    None = 'None',
    Previous = 'Previous',
    Following = 'Following',
    ModifiedFollowing = 'ModifiedFollowing'
}