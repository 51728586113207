/**
 * Date Generation.
 */
export enum DateGeneration {
    Forward = 'Forward',
    Backward = 'Backward',
    QuarterlyMaturityImm = 'QuarterlyMaturityImm',
    MonthlyMaturityImm = 'MonthlyMaturityImm'
  }
  
/**
 * Date Generation description.
 */
export enum DateGenerationDescription {
    Forward = 'Forward',
    Backward = 'Backward',
    QuarterlyMaturityImm = 'Quarterly Maturity IMM',
    MonthlyMaturityImm  = 'Monthly Maturity IMM (MMR)'
}
  