import { CapExpressedAs, ContingentConversionPeriodType, ContingentConversionScheduleTriggerType,
  ContingentConversionTriggerParityLimit, FloorExpressedAs, PepsConversionBeforeMaturityType, QuarterType
} from '@lv-convertible-bond/models/convertible-bond-terms/Enums (2)';
import { FieldMapper } from '@lv-excel/helpers/field-mapper';
import { ExcelFieldDataTypes } from '@lv-excel/models/enums/excel-field-data-types';
import { TermsPutMapper } from './terms-mapper-put';
import { mapMakeWhole } from './terms-mapper-make-whole';
import { AcquisitionOptionDateInputType, AcquisitionOptionDateInputTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionOptionDateInputType';
import { CallMapper } from './terms-mapper.call';
import { AcquisitionVWAPPeriodInputType, AcquisitionVWAPPeriodInputTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionVWAPPeriodInputType';
import { AcquisitionSharesSettlType, AcquisitionSharesSettlTypeDescription } from '@lv-convertible-bond/models/convertible-bond-terms/enum-descriptions/conversion/AcquisitionSharesSettlType';
import { TermsConversionMapper } from './terms-mapper-conversion';
import { DateExtensions, LvDateUtil } from '@lv-core-ui/util';
import { TermsDividendProtectionMapper } from './terms-mapper-dividend-protection';
import { getDateValue } from './date-time-helper';
import { QuickTermsMapHelper } from '.';
import { LvDateService } from '@lv-core-ui/services';
import { mapNewIssueFields } from './terms-mapper.new-issue';
import {
  AdjustmentFormulaType, AdjustmentFormulaTypeDescription, CapExpressedAsDescription,
  CallTriggerPeriodTypeDescription, CashDistributionDateType, CashDistributionDateTypeDescription,
  ConvertibleBondSubTypeDescription, CoPayCouponTypeDescription, CoPayTriggerTypeDescription,
  DividendProtectionTresholdFormulaType, DividendProtectionType, QuickAndFullTermsDocument,
  DividendProtectionTypeDescription, DividendThreshold, ConvertibleBondSubType,
  DividendThresholdDescription, ExchangeableType, ExchangeableTypeDescription,
  FloorExpressedAsDescripton, MinAdjustmentType,  MinAdjustmentTypeDescripton, 
  SpotLevelAtAdjustmentFormulaType, SpotLevelAtAdjustmentFormulaTypeDescription,
  ContingentConversionPeriodTypeDescription, ContingentConversionScheduleTriggerTypeDescription,
  PepsConversionBeforeMaturityTypeDescription, ConversionSettlement,
  ConversionSettlementDescripton, DividendProtectionTresholdTypeDescription,
  QuickTerms, PriceTalk, StockPriceReference, StockReferenceType, DividendProtectionTresholdType
} from '@lv-convertible-bond/models';
import { AccretionGrossNet, AccretionType, AccretionTypeDesciption, CallMakeWholeType, CallMakeWholeTypeDescription, CallTriggerPeriodType, 
  CallTriggerType, CallTriggerTypeDescription, CallValueType, CallValueTypeDescription, CurrencyType, DayCount, DayCountDescription, PikTypeDescription,
  DiscountRateType, Frequency, FrequencyDescription, IsQuotedClean, NoticePeriod, RatchetMatrixCompensationValueType, AccrualMethodDescription,
  RatchetMatrixCompensationValueTypeDescription, RatchetMatrixOfferValueType, RatchetMatrixOfferValueTypeDescription, SetupStatus, PikCouponTypeDescription,
  RecoveryUponDefaultDescription, SinkingFundScheduleItem, PutValueType, PutValueTypeDescription, ExCouponPeriodType, CustomCouponScheduleItem,
  PikCustomScheduleItem, CoPayDownsideTriggerScheduleMember, CouponType, AccrualMethod, PikCouponType, CoPayCouponType, CoPayTriggerType, RecoveryUponDefault,
  CouponReferenceTenor, StepUpCouponScheduleItem, FloatingSpreadScheduleItem, FixingHistoryItem, CouponTypeDescription, CouponReferenceTenorDescription } from '@lv-instrument-common/index';

/**
 * Responsible  to keep information about mapping fields and data from excel
 * to public application and vice versa
 */
export class TermsExcelMapper extends FieldMapper<QuickAndFullTermsDocument> {

  /**
   * Get flag that describes if convertible is not new issue or is.
   */
  get isNotNewIssue(): boolean {
    return this._model.fullTerms.issueAndRedemption.setupStatus !== SetupStatus.NewIssue;
  }

  /**
   * Get flag that describes if convertible is cross currency instrument or not.
   */
  get isCrossFx(): boolean {
    return this._model.fullTerms?.issueAndRedemption.currencyCode !== this._model.fullTerms?.issueAndRedemption.underlyingEquity.currencyCode;
  }

  private _conversionMapper: TermsConversionMapper;
  private _putMapper: TermsPutMapper;
  private _dividendProtectionMapper: TermsDividendProtectionMapper;
  private _callMapper: CallMapper;
  private _qtmHelper: QuickTermsMapHelper;

  private _isPrivateInstrument: boolean;
  private _priceTalkValue: string;

  constructor(
    private _lvDateService: LvDateService
  ) {
    super();
    this._conversionMapper = new TermsConversionMapper();
    this._putMapper = new TermsPutMapper();
    this._dividendProtectionMapper = new TermsDividendProtectionMapper(_lvDateService);
    this._callMapper = new CallMapper();
    this._isPrivateInstrument = false;
    this._priceTalkValue = null;
    this._qtmHelper = new QuickTermsMapHelper();
  }

  setIsPrivateInstrument(value: boolean): void {
    this._isPrivateInstrument = value;
  }

  createMap(isDraft: boolean = false, isNewIssue: boolean = false) {

    this._fieldDictionary['CB_TYPE'] = {
      mapFn: (value: any) => {
        isDraft ? this._model.fullTerms.issueAndRedemption.subType = value : this._model.quickTerms.convertibleType = value;
      },
      reverseMapFn: () => {
        return ConvertibleBondSubTypeDescription[this._model.fullTerms.issueAndRedemption.subType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: ConvertibleBondSubTypeDescription
    };

    this._fieldDictionary['CCY'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.currencyCode = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.currencyCode;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['UND_CCY'] = {
      mapFn: (value: any) => {
        // tslint:disable-next-line: max-line-length
        this._model.fullTerms.issueAndRedemption.underlyingEquity.currencyCode = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.underlyingEquity.currencyCode;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['MAT_CCY'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.maturingCurrencyCode = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.maturingCurrencyCode;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['FRST_STL'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.firstSettlementDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.issueAndRedemption.firstSettlementDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['MAT'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.maturityDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.issueAndRedemption.maturityDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['PERP'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.isPerpetual = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.isPerpetual;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['NOM'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.nominalValue = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.nominalValue;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['ISSUE_PX'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.issueValue = value;
      },
      reverseMapFn: () => {
        if (this.isNotNewIssue) {
          return this._model.fullTerms.issueAndRedemption.issueValue;
        }
        else {
          return this.reverseMapIssuePrice();
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['REDEM_PX'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.redemptionValue = value;
      },
      reverseMapFn: () => {
        if (this.isNotNewIssue) {
          return this._model.fullTerms.issueAndRedemption.redemptionValue;
        }
        else {
          return this.reverseMapRedemptionPrice();
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['FIXED_FX'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.fixedFXRate = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.fixedFXRate;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['PRICED_AS_PAR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.isPriceAsPar = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.isPriceAsPar;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['ISSUE_PX_AS_PAR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.issueValueIsPercentOfNominal = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.issueValueIsPercentOfNominal;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['REDEM_PX_AS_PAR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.redemptionValueIsPercentOfNominal = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.redemptionValueIsPercentOfNominal;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['QUOTED'] = {
      mapFn: (value: any) => {
        // this._model.fullTerms.issueAndRedemption.isQuotedClean = value === IsQuotedClean.Clean ? true : false;
      },
      reverseMapFn: () => {
        // return this._model.fullTerms.issueAndRedemption.isQuotedClean ? IsQuotedClean.Clean : IsQuotedClean.Dirty;
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: IsQuotedClean
    };

    this._fieldDictionary['STL_DAYS'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.daysToSettle = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.daysToSettle;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['FX_QUANTO'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.fxQuanto = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.fxQuanto;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CCY_LINKED'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.underlyingCurrencyLinked = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.underlyingCurrencyLinked;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['FSCL_YEAR_START_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.fiscalYearStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.issueAndRedemption.fiscalYearStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['RCVR_DEFAULT'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.recoveryUponDefault = value;
      },
      reverseMapFn: () => {
        return RecoveryUponDefaultDescription[this._model.fullTerms.issueAndRedemption.recoveryUponDefault];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: RecoveryUponDefaultDescription
    };

    this._fieldDictionary['ACCR_DEFAULT'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.isAccruedUponDefault = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.isAccruedUponDefault;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['EXCH_TYPE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.exhangeableParameters.exhangeableType = value;
      },
      reverseMapFn: () => {
        return ExchangeableTypeDescription[this._model.fullTerms.issueAndRedemption.exhangeableParameters.exhangeableType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: ExchangeableTypeDescription
    };

    this._fieldDictionary['PLGD_SHRS'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.exhangeableParameters.percentOfPledgedShares = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.exhangeableParameters.percentOfPledgedShares;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['WRRNTS_COVERED'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.percCoveredWarrants = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.percCoveredWarrants;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['SINKABLE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.sinkable = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.issueAndRedemption.sinkable;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['SNK_FUND_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.issueAndRedemption.sinkingFundSchedule = value.map((el: SinkingFundScheduleItem) => {
          el.sinkingDate = LvDateUtil.parse(el.sinkingDate);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.issueAndRedemption.sinkingFundSchedule.map(el => ({
          ...el,
          sinkingDate: getDateValue(el.sinkingDate)
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule
    };


    //region Accertion

    this._fieldDictionary['ACCR_TYPE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.accretion.type = value;
      },
      reverseMapFn: () => {
        return AccretionTypeDesciption[this._model.fullTerms.accretion.type];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: AccretionTypeDesciption
    };

    this._fieldDictionary['ACCR_DAY_COUNT'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.accretion.dayCount = value;
      },
      reverseMapFn: () => {
        return DayCountDescription[this._model.fullTerms.accretion.dayCount];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DayCountDescription
    };

    this._fieldDictionary['ACCR_FREQ'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.accretion.frequency = value;
      },
      reverseMapFn: () => {
        return FrequencyDescription[this._model.fullTerms.accretion.frequency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: FrequencyDescription
    };

    this._fieldDictionary['ACCR_GROSS_NET'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.accretion.grossNet = value;
      },
      reverseMapFn: () => {
        return AccretionGrossNet[this._model.fullTerms.accretion.grossNet];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: AccretionGrossNet
    };

    this._fieldDictionary['ACCR_START_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.accretion.accretionStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.accretion.accretionStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['ACCR_END_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.accretion.accretionEndDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.accretion.accretionEndDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['ACCR_FXD_RATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.accretion.fixedAccretionRate = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.accretion.fixedAccretionRate;
      },
      type: ExcelFieldDataTypes.Number
    };

    //endregion
    //region Fixed Coupon

    this._fieldDictionary['CPN_TYPE'] = {
      mapFn: (value: any) => {
        if (!this._model.fullTerms.coupon.fixed) {
          this._model.fullTerms.coupon.fixed = this._initialModel.fullTerms.coupon.fixed;
        }
        if (!this._model.fullTerms.coupon.custom) {
          this._model.fullTerms.coupon.custom = this._initialModel.fullTerms.coupon.custom;
        }
        if (!this._model.fullTerms.coupon.floating) {
          this._model.fullTerms.coupon.floating = this._initialModel.fullTerms.coupon.floating;
        }
        if (!this._model.fullTerms.coupon.pik) {
          this._model.fullTerms.coupon.pik = this._initialModel.fullTerms.coupon.pik;
        }
        if (!this._model.fullTerms.coupon.coPay) {
          this._model.fullTerms.coupon.coPay = this._initialModel.fullTerms.coupon.coPay;
        }
        this._model.fullTerms.coupon.type = value;
      },
      reverseMapFn: () => {
        return CouponTypeDescription[this._model.fullTerms.coupon.type];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CouponTypeDescription
    };

    this._fieldDictionary['CPN_RT'] = {
      mapFn: (value: any) => {
        if (this.isNotNewIssue && !!this._model.fullTerms.coupon.fixed) {
          this._model.fullTerms.coupon.fixed.rate = value;
        } 
      },
      reverseMapFn: () => {
        if (this.isNotNewIssue &&
          this._model.fullTerms.coupon.fixed &&
          this._model.fullTerms.coupon.fixed.rate > 0) {
          return this._model.fullTerms.coupon.fixed.rate;
        }
        else {
          const cpnRt = this.reverseMapCouponPriceTalk();
          return  cpnRt > 0 ? cpnRt : null;
        }
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_DAY_COUNT'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.dayCount = value;
      },
      reverseMapFn: () => {
        return DayCountDescription[this._model.fullTerms.coupon.dayCount];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DayCountDescription
    };

    this._fieldDictionary['CPN_FQ'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.frequency = value;
      },
      reverseMapFn: () => {
        return FrequencyDescription[this._model.fullTerms.coupon.frequency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: FrequencyDescription
    };

    this._fieldDictionary['CPN_ACC_METHOD'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.accrualMethod = value;
      },
      reverseMapFn: () => {
        return AccrualMethodDescription[this._model.fullTerms.coupon.accrualMethod];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: AccrualMethodDescription
    };

    this._fieldDictionary['CPN_EX_PERIOD'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.exCouponPeriod = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.exCouponPeriod;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_EX_DATE_TYPE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.exCouponPeriodType = value;
      },
      reverseMapFn: () => {
        return ExCouponPeriodType[this._model.fullTerms.coupon.exCouponPeriodType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: ExCouponPeriodType
    };

    this._fieldDictionary['CPN_ACC_START_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.accrualStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.accrualStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_ACC_END_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.accrualEndDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.accrualEndDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_FRST'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.firstCouponPaymentDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.firstCouponPaymentDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_PEN'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.penultimateCouponPaymentDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.penultimateCouponPaymentDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_DATE_EOM'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.couponDatesEOM = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.couponDatesEOM;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_GRNTED'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.isGuaranteed = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.isGuaranteed;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_GRNTED_FRST'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.guaranteedStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.guaranteedStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_GRNTED_LAST'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.guaranteedStartEndDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.guaranteedStartEndDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_TYPE_CHNG_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.typeChangeDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.typeChangeDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_USE_STEPUP_SCHED'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.fixed.useStepupCouponSchedule = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.fixed.useStepupCouponSchedule;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_SCHED_CUSTOM_RANGE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.custom.schedule = value.map((el: CustomCouponScheduleItem) => {
          el.date = LvDateUtil.parse(el.date);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.coupon.custom.schedule.map(el => ({
          ...el,
          date: getDateValue(el.date)
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    this._fieldDictionary['CPN_SCHED_STEPUP_RANGE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.fixed.stepUpCouponSchedule  = value.map((el: StepUpCouponScheduleItem) => {
          el.endDate = LvDateUtil.parse(el.endDate);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.coupon.fixed.stepUpCouponSchedule.map(el => ({
          ...el,
          endDate: getDateValue(el.endDate)
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    //endregion

    //region Floating coupon

    this._fieldDictionary['CPN_FLOAT_DAY_COUNT'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.dayCount = value;
      },
      reverseMapFn: () => {
        return DayCountDescription[this._model.fullTerms.coupon.floating.dayCount];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DayCountDescription
    };

    this._fieldDictionary['CPN_FLOAT_FQ'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.frequency = value;
      },
      reverseMapFn: () => {
        return FrequencyDescription[this._model.fullTerms.coupon.floating.frequency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: FrequencyDescription
    };

    this._fieldDictionary['CPN_FLOAT_REF_TENOR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.referenceTenor = value;
      },
      reverseMapFn: () => {
        return CouponReferenceTenorDescription[this._model.fullTerms.coupon.floating.referenceTenor];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CouponReferenceTenorDescription
    };

    this._fieldDictionary['CPN_FLOAT_REF_FIXNG_DLY'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.fixingDelay = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.floating.fixingDelay;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_FLOAT_FLOOR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.floor = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.floating.floor;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_FLOAT_SPRD'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.spread = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.floating.spread;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_FLOAT_MLTPLR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.multiplier = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.floating.multiplier;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_FLOAT_CAP'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.cap = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.floating.cap;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_FLOAT_DAYS_SCHED'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.useFloatingCustomDates = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.floating.useFloatingCustomDates;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_FLOAT_SPREAD_SCHED'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.useFloatingSpreadSchedule = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.floating.useFloatingSpreadSchedule;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_SCHED_FLOAT_RANGE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.floatingSpreadSchedule = value.map((el: FloatingSpreadScheduleItem) => {
          el.endDate = LvDateUtil.parse(el.endDate);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.coupon.floating.floatingSpreadSchedule.map(el => ({
          ...el,
          endDate: getDateValue(el.endDate)
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    this._fieldDictionary['CPN_FXNG_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.fixingHistoryItems = value.map((el: FixingHistoryItem) => {
          el.date = LvDateUtil.parse(el.date);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.coupon.floating.fixingHistoryItems.map(el => ({
          ...el,
          date: getDateValue(el.date)
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    this._fieldDictionary['CPN_SCHED_CUSTOM_DATES'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.floating.floatingCustomDates = value.map((el: Date) => {
          el = LvDateUtil.parse(el);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.coupon.floating.floatingCustomDates.map(el => {
          return {
            date: DateExtensions.toOADate(el as Date)
          };
        });
        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    //endregion

    //region PIK Coupon

    this._fieldDictionary['CPN_PIK_TYPE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.pik.pikCouponType = value;
      },
      reverseMapFn: () => {
        return PikCouponTypeDescription[this._model.fullTerms.coupon.pik.pikCouponType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: PikCouponTypeDescription
    };

    this._fieldDictionary['CPN_PIK_RATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.pik.pikCouponRate = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.pik.pikCouponRate;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_PIK_CASH_RATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.pik.cashCouponRate = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.pik.cashCouponRate;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_NMNL_ADJ'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.pik.pikNominalAdjustment = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.pik.pikNominalAdjustment;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_PIK_END_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.pik.pikEndDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.pik.pikEndDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_PIK_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.pik.pikCustomSchedule = value.map((el: PikCustomScheduleItem) => {
          el.date = LvDateUtil.parse(el.date);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.coupon.pik.pikCustomSchedule.map(el => ({
          date: getDateValue(el.date),
          pikCouponOne: el.pikCouponOne,
          cashCouponOne: el.cashCouponOne,
          pikCouponTwo: el.pikCouponTwo,
          cashCouponTwo: el.cashCouponTwo,
          pikType: PikTypeDescription[el.pikType]
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    //endregion

    //region CoPay Coupon

    this._fieldDictionary['CPN_COPAY_FREQ'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayFrequency = value;
      },
      reverseMapFn: () => {
        return FrequencyDescription[this._model.fullTerms.coupon.coPay.coPayFrequency];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: FrequencyDescription
    };

    this._fieldDictionary['CPN_COPAY_DAY_COUNT'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDayCount = value;
      },
      reverseMapFn: () => {
        return DayCountDescription[this._model.fullTerms.coupon.coPay.coPayDayCount];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: DayCountDescription
    };

    this._fieldDictionary['CPN_COPAY_TYPE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayCouponType = value;
      },
      reverseMapFn: () => {
        return CoPayCouponTypeDescription[this._model.fullTerms.coupon.coPay.coPayCouponType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CoPayCouponTypeDescription
    };

    this._fieldDictionary['CPN_COPAY_TRGGR_TYPE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayTriggerType = value;
      },
      reverseMapFn: () => {
        return CoPayTriggerTypeDescription[this._model.fullTerms.coupon.coPay.coPayTriggerType];
      },
      type: ExcelFieldDataTypes.Enum,
      enumType: CoPayTriggerTypeDescription
    };

    this._fieldDictionary['CPN_COPAY'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.hasCoPay = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.hasCoPay;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_COPAY_ACC_START_DATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayAccrualStartDate = value;
      },
      reverseMapFn: () => {
        return getDateValue(this._model.fullTerms.coupon.coPay.coPayAccrualStartDate);
      },
      type: ExcelFieldDataTypes.Date
    };

    this._fieldDictionary['CPN_COPAY_LST_FXNG'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayLastCBFixing = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayLastCBFixing;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_COPAY_UPSIDE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayUpside = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayUpside;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_COPAY_UPSIDE_RATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayUpsideCoupon = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayUpsideCoupon;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_COPAY_UPSIDE_TRGGR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayUpsideTrigger = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayUpsideTrigger;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_COPAY_DWNSIDE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDownside = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayDownside;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_COPAY_DWNSIDE_RATE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDownsideCoupon = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayDownsideCoupon;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_COPAY_DWNSIDE_TRGGR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDownsideTrigger = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayDownsideTrigger;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_COPAY_DWNSIDE_INCR'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDownsideIncrementPerCoupon = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayDownsideIncrementPerCoupon;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_COPAY_DWNSIDE_CAP'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDownsideTriggerCap = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayDownsideTriggerCap;
      },
      type: ExcelFieldDataTypes.Number
    };

    this._fieldDictionary['CPN_COPAY_TRGR_SCHED'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDownsideUseSchedule = value;
      },
      reverseMapFn: () => {
        return this._model.fullTerms.coupon.coPay.coPayDownsideUseSchedule;
      },
      type: ExcelFieldDataTypes.String
    };

    this._fieldDictionary['CPN_COPAY_DWNSIDE_SCHED_RANGE'] = {
      mapFn: (value: any) => {
        this._model.fullTerms.coupon.coPay.coPayDownsideTriggerSchedule = value.map((el: CoPayDownsideTriggerScheduleMember) => {
          el.startDate = LvDateUtil.parse(el.startDate);
          return el;
        });
      },
      reverseMapFn: () => {
        const value = this._model.fullTerms.coupon.coPay.coPayDownsideTriggerSchedule.map(el => ({
          startDate: getDateValue(el.startDate),
          value: el.value
        }));
        return value;
      },
      type: ExcelFieldDataTypes.Schedule,
    };

    //endregion

    this._conversionMapper.mapConversionFields(this._fieldDictionary,
      this._model,
      this._initialModel,
      this._isPrivateInstrument,
      isNewIssue);

    this._putMapper.mapPut(this._fieldDictionary, this._model, this._initialModel, this._isPrivateInstrument);
    this._dividendProtectionMapper.mapDividendProtection(this._fieldDictionary, this._model);
    this._callMapper.mapCall(this._fieldDictionary, this._model, this._initialModel, this._isPrivateInstrument);

    mapMakeWhole(this._fieldDictionary, this._model, this._initialModel, this._isPrivateInstrument);
    mapNewIssueFields(this._fieldDictionary, this._model, this.isNotNewIssue, this.isCrossFx)
  }

  mappEnumType(value: any, enumType: any) {
    switch (enumType) {
      case ConvertibleBondSubTypeDescription:
        return ConvertibleBondSubType[this.getEnumKeyByEnumValue(enumType, value)];
      case RecoveryUponDefaultDescription:
        return RecoveryUponDefault[this.getEnumKeyByEnumValue(enumType, value)];
      case ExchangeableTypeDescription:
        return ExchangeableType[this.getEnumKeyByEnumValue(enumType, value)];
      case IsQuotedClean:
        return IsQuotedClean[this.getEnumKeyByEnumValue(enumType, value)];
      case AccretionTypeDesciption:
        return AccretionType[this.getEnumKeyByEnumValue(enumType, value)];
      case DayCountDescription:
        return DayCount[this.getEnumKeyByEnumValue(enumType, value)];
      case FrequencyDescription:
        return Frequency[this.getEnumKeyByEnumValue(enumType, value)];
      case AccretionGrossNet:
        return AccretionGrossNet[this.getEnumKeyByEnumValue(enumType, value)];
      case PutValueTypeDescription:
        return PutValueType[this.getEnumKeyByEnumValue(enumType, value)];
      case NoticePeriod:
        return NoticePeriod[this.getEnumKeyByEnumValue(enumType, value)];
      case CouponTypeDescription:
        return CouponType[this.getEnumKeyByEnumValue(enumType, value)];
      case AccrualMethodDescription:
        return AccrualMethod[this.getEnumKeyByEnumValue(enumType, value)];
      case ExCouponPeriodType:
        return ExCouponPeriodType[this.getEnumKeyByEnumValue(enumType, value)];
      case PikCouponTypeDescription:
        return PikCouponType[this.getEnumKeyByEnumValue(enumType, value)];
      case CoPayCouponTypeDescription:
        return CoPayCouponType[this.getEnumKeyByEnumValue(enumType, value)];
      case CoPayTriggerTypeDescription:
        return CoPayTriggerType[this.getEnumKeyByEnumValue(enumType, value)];
      case CouponReferenceTenorDescription:
        return CouponReferenceTenor[this.getEnumKeyByEnumValue(enumType, value)];
      case FloorExpressedAsDescripton:
        return FloorExpressedAs[this.getEnumKeyByEnumValue(enumType, value)];
      case CapExpressedAsDescription:
        return CapExpressedAs[this.getEnumKeyByEnumValue(enumType, value)];
      case CallValueTypeDescription:
        return CallValueType[this.getEnumKeyByEnumValue(enumType, value)];
      case CallTriggerTypeDescription:
        return CallTriggerType[this.getEnumKeyByEnumValue(enumType, value)];
      case CallTriggerPeriodTypeDescription:
        return CallTriggerPeriodType[this.getEnumKeyByEnumValue(enumType, value)];
      case CurrencyType:
        return CurrencyType[this.getEnumKeyByEnumValue(enumType, value)];
      case CallMakeWholeTypeDescription:
        return CallMakeWholeType[this.getEnumKeyByEnumValue(enumType, value)];
      case DividendProtectionTypeDescription:
        return DividendProtectionType[this.getEnumKeyByEnumValue(enumType, value)];
      case CashDistributionDateTypeDescription:
        return CashDistributionDateType[this.getEnumKeyByEnumValue(enumType, value)];
      case AdjustmentFormulaTypeDescription:
        return AdjustmentFormulaType[this.getEnumKeyByEnumValue(enumType, value)];
      case SpotLevelAtAdjustmentFormulaTypeDescription:
        return SpotLevelAtAdjustmentFormulaType[this.getEnumKeyByEnumValue(enumType, value)];
      case MinAdjustmentTypeDescripton:
        return MinAdjustmentType[this.getEnumKeyByEnumValue(enumType, value)];
      case DividendThresholdDescription:
        return DividendThreshold[this.getEnumKeyByEnumValue(enumType, value)];
      case DividendProtectionTresholdTypeDescription:
        return DividendProtectionTresholdType[this.getEnumKeyByEnumValue(enumType, value)];
      case CurrencyType:
        return CurrencyType[this.getEnumKeyByEnumValue(enumType, value)];
      case DividendProtectionTresholdFormulaType:
        return DividendProtectionTresholdFormulaType[this.getEnumKeyByEnumValue(enumType, value)];
      case AcquisitionOptionDateInputTypeDescription:
        return AcquisitionOptionDateInputType[this.getEnumKeyByEnumValue(enumType, value)];
      case AcquisitionVWAPPeriodInputTypeDescription:
        return AcquisitionVWAPPeriodInputType[this.getEnumKeyByEnumValue(enumType, value)];
      case AcquisitionSharesSettlTypeDescription:
        return AcquisitionSharesSettlType[this.getEnumKeyByEnumValue(enumType, value)];
      case QuarterType:
        return QuarterType[this.getEnumKeyByEnumValue(enumType, value)];
      case ContingentConversionPeriodTypeDescription:
        return ContingentConversionPeriodType[this.getEnumKeyByEnumValue(enumType, value)];
      case ContingentConversionTriggerParityLimit:
        return ContingentConversionTriggerParityLimit[this.getEnumKeyByEnumValue(enumType, value)];
      case ContingentConversionScheduleTriggerTypeDescription:
        return ContingentConversionScheduleTriggerType[this.getEnumKeyByEnumValue(enumType, value)];
      case PepsConversionBeforeMaturityTypeDescription:
        return PepsConversionBeforeMaturityType[this.getEnumKeyByEnumValue(enumType, value)];
      case DiscountRateType:
        return DiscountRateType[this.getEnumKeyByEnumValue(enumType, value)];
      case RatchetMatrixCompensationValueTypeDescription:
        return RatchetMatrixCompensationValueType[this.getEnumKeyByEnumValue(enumType, value)];
      case RatchetMatrixOfferValueTypeDescription:
        return RatchetMatrixOfferValueType[this.getEnumKeyByEnumValue(enumType, value)];
      case ConversionSettlementDescripton:
        return ConversionSettlement[this.getEnumKeyByEnumValue(enumType, value)];
    }
  }

  mappEnumTypeV3(value: any, enumType: any) {
    switch (enumType) {
      case ConvertibleBondSubTypeDescription:
        return ConvertibleBondSubType[value];
      case RecoveryUponDefaultDescription:
        return RecoveryUponDefault[value];
      case ExchangeableTypeDescription:
        return ExchangeableType[value];
      case IsQuotedClean:
        return IsQuotedClean[value];
      case AccretionTypeDesciption:
        return AccretionType[value];
      case DayCountDescription:
        return DayCount[value];
      case FrequencyDescription:
        return Frequency[value];
      case AccretionGrossNet:
        return AccretionGrossNet[value];
      case PutValueTypeDescription:
        return PutValueType[value];
      case NoticePeriod:
        return NoticePeriod[value];
      case CouponTypeDescription:
        return CouponType[value];
      case AccrualMethodDescription:
        return AccrualMethod[value];
      case ExCouponPeriodType:
        return ExCouponPeriodType[value];
      case PikCouponTypeDescription:
        return PikCouponType[value];
      case CoPayCouponTypeDescription:
        return CoPayCouponType[value];
      case CoPayTriggerTypeDescription:
        return CoPayTriggerType[value];
      case CouponReferenceTenorDescription:
        return CouponReferenceTenor[value];
      case FloorExpressedAsDescripton:
        return FloorExpressedAs[value];
      case CapExpressedAsDescription:
        return CapExpressedAs[value];
      case CallValueTypeDescription:
        return CallValueType[value];
      case CallTriggerTypeDescription:
        return CallTriggerType[value];
      case CallTriggerPeriodTypeDescription:
        return CallTriggerPeriodType[value];
      case CurrencyType:
        return CurrencyType[value];
      case CallMakeWholeTypeDescription:
        return CallMakeWholeType[value];
      case DividendProtectionTypeDescription:
        return DividendProtectionType[value];
      case CashDistributionDateTypeDescription:
        return CashDistributionDateType[value];
      case AdjustmentFormulaTypeDescription:
        return AdjustmentFormulaType[value];
      case SpotLevelAtAdjustmentFormulaTypeDescription:
        return SpotLevelAtAdjustmentFormulaType[value];
      case MinAdjustmentTypeDescripton:
        return MinAdjustmentType[value];
      case DividendThresholdDescription:
        return DividendThreshold[value];
      case DividendProtectionTresholdTypeDescription:
        return DividendProtectionTresholdType[value];
      case CurrencyType:
        return CurrencyType[value];
      case DividendProtectionTresholdFormulaType:
        return DividendProtectionTresholdFormulaType[value];
      case AcquisitionOptionDateInputTypeDescription:
        return AcquisitionOptionDateInputType[value];
      case AcquisitionVWAPPeriodInputTypeDescription:
        return AcquisitionVWAPPeriodInputType[value];
      case AcquisitionSharesSettlTypeDescription:
        return AcquisitionSharesSettlType[value];
      case QuarterType:
        return QuarterType[value];
      case ContingentConversionPeriodTypeDescription:
        return ContingentConversionPeriodType[value];
      case ContingentConversionTriggerParityLimit:
        return ContingentConversionTriggerParityLimit[value];
      case ContingentConversionScheduleTriggerTypeDescription:
        return ContingentConversionScheduleTriggerType[value];
      case PepsConversionBeforeMaturityTypeDescription:
        return PepsConversionBeforeMaturityType[value];
      case DiscountRateType:
        return DiscountRateType[value];
      case RatchetMatrixCompensationValueTypeDescription:
        return RatchetMatrixCompensationValueType[value];
      case RatchetMatrixOfferValueTypeDescription:
        return RatchetMatrixOfferValueType[value];
      case ConversionSettlementDescripton:
        return ConversionSettlement[value];
    }
  }

  customMapp(inputData: any[]) {

    const quoted = inputData.find(x => x.key === 'QUOTED')?.value;
    const conversionPriceCCY = inputData.find(x => x.key === 'CONV_PRICE_CCY');
    const currencyCCY = this._model.fullTerms.issueAndRedemption.currencyCode;
    const undCurrencyCCY = this._model.fullTerms.issueAndRedemption.underlyingEquity.currencyCode;
    const isCrossFx = currencyCCY !== undCurrencyCCY;

    const triggerTypeField = inputData.find(x => x.key === 'CO_CO_TRGGR_TYPE');

    const resetNumAvgDays = inputData.find(x => x.key === 'RES_NUM_AVG_DAYS');
    const resetFixLag = inputData.find(x => x.key === 'RES_FIX_LAG');

    const pepsMinCr = inputData.find(x => x.key === 'PEPS_MIN_CR')?.value;
    const pepsMaxCr = inputData.find(x => x.key === 'PEPS_MAX_CR')?.value;
    const pepsLowerStrike = inputData.find(x => x.key === 'PEPS_LWR_CP')?.value;
    const pepsHigherStrike = inputData.find(x => x.key === 'PEPS_MAX_CR')?.value;

    if ((this.hasValue(pepsMinCr) ||
        this._model.fullTerms.conversion.pepsConversion?.lowerStrike === 0) &&
        this._model.fullTerms.conversion.pepsConversion?.maxRatio > 0) {
          this._model.fullTerms.conversion.pepsConversion.lowerStrike = 
          this._model.fullTerms.issueAndRedemption.nominalValue / this._model.fullTerms.conversion.pepsConversion?.maxRatio;
    }

    if ((this.hasValue(pepsMaxCr) ||
        this._model.fullTerms.conversion.pepsConversion?.higherStrike === 0) &&
        this._model.fullTerms.conversion.pepsConversion?.customMinRatio > 0) {
          this._model.fullTerms.conversion.pepsConversion.higherStrike = 
          this._model.fullTerms.issueAndRedemption.nominalValue / this._model.fullTerms.conversion.pepsConversion?.customMinRatio;
    }

    if ((this.hasValue(pepsLowerStrike) ||
        this._model.fullTerms.conversion.pepsConversion?.maxRatio === 0) &&
        this._model.fullTerms.conversion.pepsConversion?.lowerStrike > 0) {
          this._model.fullTerms.conversion.pepsConversion.maxRatio = 
          this._model.fullTerms.issueAndRedemption.nominalValue / this._model.fullTerms.conversion.pepsConversion?.lowerStrike;
    }

    if ((this.hasValue(pepsHigherStrike) ||
      this._model.fullTerms.conversion.pepsConversion?.customMinRatio === 0) &&
      this._model.fullTerms.conversion.pepsConversion?.higherStrike > 0) {
        this._model.fullTerms.conversion.pepsConversion.customMinRatio = 
        this._model.fullTerms.issueAndRedemption.nominalValue / this._model.fullTerms.conversion.pepsConversion?.higherStrike;
    }

    if (this.hasValue(quoted)) {
      this._model.fullTerms.issueAndRedemption.isQuotedClean = quoted;
    }

    if (!!resetNumAvgDays?.value || !!resetFixLag?.value) {
      this._model.fullTerms.conversion.reset.useResetAveraging = true;
    }
  
    if (triggerTypeField?.value) {
      // tslint:disable-next-line: max-line-length
      const triggerType = ContingentConversionScheduleTriggerType[triggerTypeField.value];
      this._conversionMapper.mapTriggerType(triggerType, this._model);
    }  

    // custom map logic from backend for draft
    this._conversionMapper.disableConversionSectionsForNonRegularInstruments(this._model);

    if (this._model.fullTerms.issueAndRedemption.isPerpetual) {
      this._model.fullTerms.issueAndRedemption.redemptionValue = 0;
    }

    if (this._model.fullTerms.call && !this._model.fullTerms.call?.callNoticeGiven) {
      this._model.fullTerms.call.callDate = null;
    }   

    this._putMapper.setEndDateInSchedule(this._model);

    if (conversionPriceCCY) {
      this._conversionMapper.recalculateConversionPriceOrRatio(this._model, conversionPriceCCY.value, isCrossFx);
    }

    const resetScheduleField = inputData.find(x => x.key === 'RES_SCHED_RANGE');
    if (!!resetScheduleField?.value) {
      this._model.fullTerms.conversion.reset.schedule = resetScheduleField.value.map(item => {

        return {
          ...item,
          startDate: LvDateUtil.parse(item.startDate),
          endDate: (item.endDate && this._model.fullTerms.conversion.reset.resettableDuringPeriod) ? LvDateUtil.parse(item.endDate) : null
        };
      });
    }
    if (!!inputData.find(x => x.key === 'FIXED_STK_REF_CBCCY')?.value) {
      this.customMapForFixedStockRefCb(this._model, inputData.find(x => x.key === 'FIXED_STK_REF_CBCCY')?.value, this.isNotNewIssue, isCrossFx);
    }
  }

  setUpNewIssue(inputData: any[]) {
    if (inputData.find(x => !!x.value && this._newIssueFields[x.key])) {
      this._model.fullTerms.issueAndRedemption.setupStatus = SetupStatus.NewIssue;

      if (!this._model.quickTerms) {
        this._model.quickTerms = new QuickTerms();
        this._model.quickTerms.priceTalk = this.getDefaultPriceTalk();
        this._model.quickTerms.stockPriceReference = {} as StockPriceReference;
      }

      if (!this._model.fullTerms.priceTalk) {
        this._model.fullTerms.priceTalk = this.getDefaultPriceTalk();        
      }

      if (!this._model.fullTerms.stockPriceReference) {
        this._model.fullTerms.stockPriceReference = {} as StockPriceReference;
      }

      this._qtmHelper.getQuickTermsEntryFromConvertibleBond(this._model.fullTerms, this._model.quickTerms);
    }
  }

  getDefaultPriceTalk(): PriceTalk{
    const priceTalk = {} as PriceTalk;
    priceTalk.redemptionValueBest = 100;
    priceTalk.redemptionValueWorst = 100;
    priceTalk.issuePriceBest = 100;
    priceTalk.issuePriceWorst = 100;

    return priceTalk
  }

  /**
   * Custom map for fixed stock ref.
   * @param model Terms model.
   * @param value New stock ref value.
   * @param isNotNewIssue flag that describes whether cb is not new issue.
   * @param isCrossFx flag that indicates whether cb is cross fx.
   * @returns 
   */
  private customMapForFixedStockRefCb(model: QuickAndFullTermsDocument, value: number, isNotNewIssue: boolean, isCrossFx: boolean): void {
    if (isNotNewIssue) {
      return;
    }

    model.fullTerms.stockPriceReference.referenceType = StockReferenceType.Fixed; 

    if (isCrossFx) {
      model.fullTerms.stockPriceReference.fixedStockRef = value * model.fullTerms.issueAndRedemption.fixedFXRate;
    }
    else {
      model.fullTerms.stockPriceReference.fixedStockRef = value;
    }

    model.quickTerms.stockPriceReference = model.fullTerms.stockPriceReference;
  }

  /**
   * Returns coupon value depends on selected value in price talk
   * @returns Coupon value
   */
  private reverseMapCouponPriceTalk() {
    switch (this._priceTalkValue?.toLocaleLowerCase()) {
      case 'best':
        return this._model.fullTerms.priceTalk.couponBest;
    
      case 'worst':
        return this._model.fullTerms.priceTalk.couponWorst;

      default:
        return this._model.fullTerms.priceTalk.couponBest;
    }
  }

  /**
   * Returns Redemption price value depends on selected value in price talk
   * @returns Redemption price value
   */
  private reverseMapRedemptionPrice() {
    switch (this._priceTalkValue?.toLocaleLowerCase()) {
      case 'best':
        return this._model.fullTerms.priceTalk.redemptionValueBest;
    
      case 'worst':
        return this._model.fullTerms.priceTalk.redemptionValueWorst;

      default:
        return this._model.fullTerms.priceTalk.redemptionValueBest;
    }
  }

  /**
   * Returns Issue price value depends on selected value in price talk
   * @returns Issue price value
   */
  private reverseMapIssuePrice() {
    switch (this._priceTalkValue?.toLocaleLowerCase()) {
      case 'best':
        return this._model.fullTerms.priceTalk.issuePriceBest;
    
      case 'worst':
        return this._model.fullTerms.priceTalk.issuePriceWorst;

      default:
        return this._model.fullTerms.priceTalk.issuePriceBest;
    }
  }

  private hasValue(value: any): boolean {
    return value !== undefined && value !== null;
  }

  private _newIssueFields: Record<string, boolean> = {
    'PRICE_TALK': true,
    'ASSUMED_CPN_RT': true,
    'ASSUMED_ISSUE_PX': true,
    'ASSUMED_REDEM_PX': true,
    'ISSUE_STK_REF_CBCCY': true,
    'ISSUE_FX_REF': true,
    'ISSUE_STK_REF_EQCCY': true,
    'ASSUMED_YLD': true,
    'ASSUMED_PREM_PRC': true,
    'FIXED_STK_REF_CBCCY': true,
    'FIXED_STK_REF_EQCCY': true,
    'NI_YLD_RANGE': true,
    'NI_REDEM_PX_RANGE': true,
    'NI_CPN_RT_RANGE': true,
    'NI_ISSUE_PX_RANGE': true,
    'NI_PREM_PRC_RANGE': true,
  }
}
