<lv-section-settings
    [settings]="settings"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="fundingOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]"
    [dataCy]="'DM-1490'">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-funding-section-settings [settings]="systemSettings"></lv-funding-section-settings>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-funding-section-settings
      [settings]="settings"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-funding-section-settings>
  </ng-template>
</lv-section-settings>

<ng-template #fundingOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1490"></lv-tooltip>
  </ng-container>
</ng-template>
