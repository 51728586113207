import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ElementRef, Optional, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LvCouponView } from './lv-coupon.view';
import { LvConvertibleBondTermsPresenter } from '../lv-convertible-bond-terms.presenter';
import { CouponType } from '@lv-analytics/models';
import { ConvertibleBondTermsSectionEvent } from '@lv-convertible-bond/models';
import { LvExcelService } from '@lv-excel/services';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import { LvFloatingCouponComponent } from './lv-floating-coupon/lv-floating-coupon.component';
import { TermsFieldsIdentifiers } from '@lv-convertible-bond/models/terms-fields-identifiers';

@Component({
  selector: 'lv-coupon',
  templateUrl: './lv-coupon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCouponComponent implements OnInit, OnDestroy {

  @ViewChild('floatingComponent', { static: false }) floatingComponent: LvFloatingCouponComponent;

  get isOpenedFromExcel(): boolean {
    return !!this._excelSvc?.isInitialized();
  }

  get addAdditionalFloatingClass() {
     return this.view.isPikCouponTypePikOrFloatingOrPikPlusFloating ? 'lv-coupon-floating-container'  : '';
  }

  private _modelSubscription: Subscription;
  public view: LvCouponView;

  dpAccStartDateCheckBoxId: string;
  dpAccStartDatePickerId: string;
  dpAccEndDateCheckBoxId: string;
  dpAccEndDatePickerId: string;
  dpFirstPaymentDateCheckBoxId: string;
  dpFirstPaymentDatePickerId: string;
  dpPenPaymentDateCheckBoxId: string;
  dpPenPaymentDatePickerId: string;
  dpCouponChangedDateCheckBoxId: string;
  dpCouponChangedDatePickerId: string;
  dpGuaranteedStartDateId: string;
  dpGuaranteedEndDateId: string;


  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _presenter: LvConvertibleBondTermsPresenter,
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
    @Optional() private _excelSvc: LvExcelService
  ) {
    this.view = new LvCouponView(_lvConvertibleBondTermsService);
    this.dpAccEndDateCheckBoxId = 'accEndDateCheckBoxId';
    this.dpAccEndDatePickerId = 'accEndDatePickerId';
    this.dpAccStartDateCheckBoxId = 'accStartDateCheckBoxId';
    this.dpAccStartDatePickerId = 'accStartDatePickerId';
    this.dpFirstPaymentDateCheckBoxId = 'firstPaymentDateCheckBoxId';
    this.dpFirstPaymentDatePickerId = 'firstPaymentDatePickerId';
    this.dpPenPaymentDateCheckBoxId = 'penPaymentDateCheckBoxId';
    this.dpPenPaymentDatePickerId = 'penPaymentDatePickerId';
    this.dpCouponChangedDatePickerId = 'couponChangedDatePickerId';
    this.dpCouponChangedDateCheckBoxId = 'couponChangedDateCheckBoxId';
    this.dpGuaranteedStartDateId = 'guaranteedStartDateId';
    this.dpGuaranteedEndDateId = 'guaranteedEndDateId';
  }

  ngOnInit() {
    this._modelSubscription = this._presenter.onModelUpdated
      .pipe(
        filter(event => event.eventId !== ConvertibleBondTermsSectionEvent.CouponEvent &&
          event.eventId !== ConvertibleBondTermsSectionEvent.CouponPriceTalk &&
          event.eventId !== ConvertibleBondTermsSectionEvent.CouponDaysOrFrequencyChangedEvent &&
          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitCallsEvent &&
          event.eventId !== ConvertibleBondTermsSectionEvent.MWInitConversionEvent)
      )
      .subscribe(event => {
        if (event.data) {
          this.view.init(event.data.fullTerms.coupon, event.data.fullTerms.issueAndRedemption, event.data.fullTerms.priceTalk);
          this._presenter.setCouponType();
          this._presenter.setCoPayAccruedDate();
          this._changeDetectorRef.detectChanges();
        }
      });

    if (this._presenter.isModelLoaded()) {
      const fullTerms = this._presenter.getModel().fullTerms;
      this.view.init(fullTerms.coupon,
        fullTerms.issueAndRedemption,
        fullTerms.priceTalk);
      this._presenter.setCouponType();
      this._presenter.setCoPayAccruedDate();

      this._changeDetectorRef.detectChanges();
    }
  }

  couponTypeChange() {
    this.view.couponTypeChange();

    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.CouponEvent,
      data: this.view.model,
      sourceOfUpdate: TermsFieldsIdentifiers.CouponType
    }, false);

    if (this.view.model.type === CouponType.ZeroCoupon || this.view.model.type === CouponType.Custom ||
      this.view.model.type === CouponType.Floating) {

      this._presenter.updateModel({
        eventId: ConvertibleBondTermsSectionEvent.CouponPriceTalk,
        data: this.view.priceTalk,
        sourceOfUpdate: TermsFieldsIdentifiers.CouponBestMidWorst
      });
    } else {
      this.onModelChange();
    }
  }

  hasCoPayChange() {
    this.view.hasCoPayChange();
    this.onModelChange();
  }

  onPenultimateCouponPaymentDateChaged() {
    this.view.penultimatePaymentDateChanged();
    this.onModelChange();
  }

  onGuarantedCouponChange() {
    this.onModelChange();
  }

  accrualStartDateCheckboxChanged(value: boolean) {
    this.view.accrualStartDateCheckboxChanged(value);
    this.onModelChange();
  }

  accrualEndDateCheckboxChanged(value: boolean) {
    this.view.accrualEndDateCheckboxChanged(value);
    this.onModelChange();
  }

  firstCouponPaymentDateCheckboxChanged(value: boolean) {
    this.view.firstCouponPaymentDateCheckboxChanged(value);
    this.onModelChange();
  }

  penultimateCouponPaymentDateCheckboxChanged(value: boolean) {
    this.view.penultimateCouponPaymentDateCheckboxChanged(value);
    this.onModelChange();
  }

  couponChangedDateCheckboxChanged(value: boolean) {
    this.view.couponChangedDateCheckboxChanged(value);
    this.onModelChange();
  }

  guaranteedStartCheckBoxChange(value: boolean) {
    this.view.guaranteedStartCheckBoxChange(value);
    this.onModelChange();
  }

  guaranteedEndCheckBoxChange(value: boolean) {
    this.view.guaranteedEndCheckBoxChange(value);
    this.onModelChange();
  }

  onCouponDaysOrFrequencyChanged() {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.CouponDaysOrFrequencyChangedEvent,
      data: this.view.model,
      sourceOfUpdate: 'other'
    }, true);
  }

  onModelChange(publishExternalEvent: boolean = true) {
    this._presenter.updateModel({
      eventId: ConvertibleBondTermsSectionEvent.CouponEvent,
      data: this.view.model,
      sourceOfUpdate: 'other'
    }, publishExternalEvent);
  }

  ngOnDestroy(): void {
    this._modelSubscription.unsubscribe();
  }

  getCouponTootlipId(element: ElementRef<HTMLElement>, sectionId: string) {
    return element.nativeElement.getAttribute('data-tooltip-id') === sectionId;
  }

  applyScheduleChanges() {
    if (this.view.model && this.floatingComponent) {
      if (this.floatingComponent.floatingFixingHistorySchedule) {
        this.floatingComponent.floatingFixingHistorySchedule.applyAdvancedGridChanges();
      }

      if (this.floatingComponent.floatingSpreadSchedule) {
        this.floatingComponent.floatingSpreadSchedule.applyAdvancedGridChanges();
      }

      if (this.floatingComponent.floatingCustomDatesSchedule) {
        this.floatingComponent.floatingCustomDatesSchedule.applyAdvancedGridChanges();
      }
    }
  }

  /**
   * Populate floating model if data are not present.
   */
  pikCouponTypeChange():void {
    this.view.onPikCouponTypeChange();
    this.onModelChange();
  }
}
