import { v4 } from 'uuid';
import { DateExtensions, LvLookupEnum } from '@lv-core-ui/util';
import { CallMakeWholeDataConversion, ConversionData, CustomMakeWholeData, TakeoverProtection } from '@lv-convertible-bond/models';
import { ConvertibleBondTermsService } from '@lv-convertible-bond/services';
import {
  Call, CallMakeWholeData, CallMakeWholeType, IssueAndRedemption, RatchetMatrixCompensationValueTypeDescription,
  RatchetMatrixOfferValueType, RatchetMatrixOfferValueTypeDescription, CouponType, Coupon
} from '@lv-instrument-common/index';

export class LvMakeWholeView {

  //#region  Properties
  get callRatchetMatrixVisible() {
    if (this.call?.recieveMakeWhole && !this.conversion?.recieveMakeWholeConversion && this.issueAndRedemption.isCallable) {
      return this.call.callMakeWhole.type === CallMakeWholeType.RatchetMatrix;
    }
    else {
      return false;
    }
  }

  get conversionRatchetMatrixVisible() {
    if (this.conversion.recieveMakeWholeConversion && this.conversion.callMakeWhole) {
      return this.conversion.callMakeWhole.type === CallMakeWholeType.RatchetMatrix;
    }
    else {
      return false;
    }
  }

  get makeWholeCallVisible() {
    return !this.conversion?.recieveMakeWholeConversion && (this.call?.recieveMakeWhole && this.issueAndRedemption?.isCallable);
  }

  get makeWholeConversionVisible() {
    return this.conversion?.recieveMakeWholeConversion;
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;

    if (this.conversion.callMakeWhole) {
      this.conversion.mwStartDate = value;
    }
  }

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;

    if (this.conversion.callMakeWhole) {
      this.conversion.mwEndDate = value;
    }
  }

  startDateEnabled: boolean;
  endDateEnabled: boolean;

  // #endregion

  _startDate: Date;
  _endDate: Date;
  callMakeWholeData: CallMakeWholeData;
  callMakeWholeDataConversion: CallMakeWholeDataConversion;
  conversion: ConversionData;
  call: Call;
  coupon: Coupon;
  issueAndRedemption: IssueAndRedemption;
  takeoverProtection: TakeoverProtection;
  customMakeWholeData: CustomMakeWholeData;

  callMakeWholeCheckBoxId: string;
  conversionMakeWholeCheckBoxId: string;
  isConversionMakeWholeEnabled: boolean;

  offerValueTypeLookup: LvLookupEnum;
  compensationValueTypeLookup: LvLookupEnum;

  constructor(
    private _lvConvertibleBondTermsService: ConvertibleBondTermsService,
  ) {

    this.conversion = {
      schedule: [],
      exerciseSchedule: [],
      callMakeWhole: {} as CallMakeWholeDataConversion,
    } as ConversionData;

    this.call = {
      softCall: {},
      scheduleItems: [],
      callMakeWhole: {

      } as CallMakeWholeData
    } as Call;

    this.init(null, this.call, this.conversion, {} as IssueAndRedemption, null, {} as TakeoverProtection);

    this.callMakeWholeCheckBoxId = v4();
    this.conversionMakeWholeCheckBoxId = v4();

    this.startDateEnabled = false;
    this.endDateEnabled = false;
    this.isConversionMakeWholeEnabled = false;

    this.offerValueTypeLookup = new LvLookupEnum(RatchetMatrixOfferValueTypeDescription);
    this.compensationValueTypeLookup = new LvLookupEnum(RatchetMatrixCompensationValueTypeDescription);
  }

  init(
    conversionRatio?: number,
    call?: Call,
    conversion?: ConversionData,
    issueAndRedemption?: IssueAndRedemption,
    coupon?: Coupon,
    takeoverProtection?: TakeoverProtection,
    isExcel: boolean = false
  ) {

    const makeWholeSettings = this._lvConvertibleBondTermsService.makeWholeSettings;

    if (conversion) {
      this.conversion = conversion;
    }
    else if (this._lvConvertibleBondTermsService.convertibleBondDocument?.conversion) {
      this.conversion = this._lvConvertibleBondTermsService.convertibleBondDocument.conversion;
    }

    this.call = call ?? this._lvConvertibleBondTermsService.convertibleBondDocument.call;

    if (!this.call.callMakeWhole) {
      this.call.callMakeWhole = this._lvConvertibleBondTermsService.convertibleBondDocument.call.callMakeWhole;
    }

    if (!this.conversion.callMakeWhole && !!this.call.callMakeWhole) {
      this.conversion.callMakeWhole = { ...this.call.callMakeWhole } as unknown as CallMakeWholeDataConversion;
    }

    this.issueAndRedemption = issueAndRedemption;
    this.takeoverProtection = takeoverProtection ?? this._lvConvertibleBondTermsService.convertibleBondDocument?.takeoverProtection;

    if (conversion) {
      this.conversion.callMakeWhole = conversion.callMakeWhole;
      this.setConversionMWDates();

      if (!isExcel && !!this.conversion.callMakeWhole) {
        this.conversion.callMakeWhole.ratchetMatrixOfferValueType = this.takeoverProtection.ratchetMatrixOfferValueType;
        this.conversion.callMakeWhole.ratchetMatrixCompensationValueType = this.takeoverProtection.ratchetMatrixCompensationValueType;
      }
    }

    if (!isExcel && !!this.takeoverProtection) {
      if (!!this.takeoverProtection.ratchetMatrixCompensationValueType) {
        this.call.callMakeWhole.ratchetMatrixCompensationValueType = this.takeoverProtection.ratchetMatrixCompensationValueType;
      }

      if (!!this.takeoverProtection.ratchetMatrixOfferValueType) {
        this.call.callMakeWhole.ratchetMatrixOfferValueType = this.takeoverProtection.ratchetMatrixOfferValueType;
      }
    }

    if (coupon) {
      this.isConversionMakeWholeEnabled = coupon.type !== CouponType.ZeroCoupon;

      // if conversion make whole is disabled then we need to set value to false.
      if (!this.isConversionMakeWholeEnabled) {
        this.conversion.recieveMakeWholeConversion = false;
      }
    }

    // I conversion make whole is set to true in settings update value
    // from settings when coupon is changed from zero type to some other.
    if (this.coupon?.type === CouponType.ZeroCoupon && coupon?.type !== CouponType.ZeroCoupon) {
      this.conversion.recieveMakeWholeConversion = makeWholeSettings.conversionMakeWhole;
    }

    this.coupon = { ...coupon };
    this.updateRatio(conversionRatio);
  }

  updateRatio(conversionRatio: number) {
    if (this.conversion || this.call) {
      this.setCustomMakeWholeData(conversionRatio);
    }
  }

  onConversionMakeWholeChanged() {
    if (this.conversion.recieveMakeWholeConversion) {
      this.setConversionMWDates();
    }
  }

  setCustomMakeWholeData(conversionRatio: number) {
    this.customMakeWholeData = new CustomMakeWholeData();
    this.customMakeWholeData.currency = this.issueAndRedemption.currencyCode ? this.issueAndRedemption.currencyCode : '';
    this.customMakeWholeData.conversionRatio = conversionRatio;
    this.customMakeWholeData.nominal = this.issueAndRedemption.nominalValue;
    this.customMakeWholeData.offerValueType = RatchetMatrixOfferValueType.StockPrice;
    if (this.call && this.call.callMakeWhole) {
      this.customMakeWholeData.offerValueType = this.call.callMakeWhole.ratchetMatrixOfferValueType;
    }
    if (this.conversion && this.conversion.callMakeWhole) {
      this.customMakeWholeData.offerValueType = this.conversion.callMakeWhole.ratchetMatrixOfferValueType;
    }
  }

  setConversionMWDates() {
    const makeWholeSettings = this._lvConvertibleBondTermsService.makeWholeSettings;

    if (!this.conversion.mwStartDate) {
      this._startDate = DateExtensions.addDays(this.issueAndRedemption.firstSettlementDate, makeWholeSettings?.startDate);
      this.startDateEnabled = !!makeWholeSettings?.startDate;
    } else {
      this._startDate = this.conversion.mwStartDate;
      this.startDateEnabled = true;
    }

    if (!this.conversion.mwEndDate) {
      this._endDate = DateExtensions.addDays(this.issueAndRedemption.maturityDate, -makeWholeSettings?.endDate);
      this.endDateEnabled = !!makeWholeSettings?.endDate;
    } else {
      this._endDate = this.conversion.mwEndDate;
      this.endDateEnabled = true;
    }
  }

  onCallMakeWholeChanged() {
    this.call.callMakeWhole = this.call.callMakeWhole ?? this._lvConvertibleBondTermsService.convertibleBondDocument.call.callMakeWhole;
  }

  starDateChecboxChanged(value: boolean): void {
    if (this.conversion.callMakeWhole) {
      this.conversion.mwStartDate = value ? this._startDate : null;
    }
  }

  endDateChecboxChanged(value: boolean): void {
    if (this.conversion.callMakeWhole) {
      this.conversion.mwEndDate = value ? this._endDate : null;
    }
  }
}
