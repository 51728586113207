<lv-flex-box direction="row">
    <lv-flex-box class="lv-cds-terms-settings-column">
        <lv-flex-box data-cy="DM-1522" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="nominalValue"
                 filter="span">
                <span> Nominal Value </span>
            </div>
            <div class="lv-input-field">
                <lv-numeric-text-box
                    [format]="formatFour"
                    [decimals]="decimalsFour"
                    [disabled]="!overrideDefaults"
                    [(ngModel)]="settings.settings.nominalValue"
                    name="nominal-value-cds">
                </lv-numeric-text-box>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-1517" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="ccy"
                 filter="span">
                <span> CCY </span>
            </div>
            <div class="lv-input-field lv-bond-general-ccy">
                <kendo-dropdownlist
                    lvId="lv-ccy-cds"
                    class="lv-terms-general-small-input"
                    [data]="ccyLookup.items"
                    [(ngModel)]="settings.settings.currencyCode"
                    [disabled]="!overrideDefaults"
                    name="ccy-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>
  
        <lv-flex-box data-cy="DM-1530" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="daysToSettle"
                 filter="span">
                <span> Days To Settle </span>
            </div>
            <div class="lv-input-field">
                <lv-numeric-text-box
                    [format]="formatFour"
                    [decimals]="decimalsFour"
                    [disabled]="!overrideDefaults"
                    [(ngModel)]="settings.settings.daysToSettle"
                    name="daysToSettle">
                </lv-numeric-text-box>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-1533" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="couponFrequency"
                 filter="span">
                <span> Coupon Frequency </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-coupon-frequency-cds"
                    class="lv-terms-general-small-input"
                    [data]="couponFrequencyLookup.items"
                    [(ngModel)]="settings.settings.couponFrequency"
                    [disabled]="!overrideDefaults"
                    name="coupon-frequency-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-1532" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="couponDayCount"
                 filter="span">
                <span> Coupon Day Count </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-coupon-day-count-cds"
                    class="lv-terms-general-small-input"
                    [data]="couponDayCountLookup.items"
                    [(ngModel)]="settings.settings.couponDayCount"
                    [disabled]="!overrideDefaults"
                    name="coupon-day-count-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>
        
        <lv-flex-box data-cy="DM-5069" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="BusinessDayConvention"
                 filter="span">
                <span> Business Day Convention </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-business-day-convention-cds"
                    class="lv-terms-general-small-input"
                    [data]="businessDayConventionLookup.items"
                    [(ngModel)]="settings.settings.businessDayConvention"
                    [disabled]="!overrideDefaults"
                    name="business-day-convention-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5070" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="cdsDateGeneration"
                 filter="span">
                <span> Date Generation </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-business-day-convention-cds"
                    class="lv-terms-general-small-input"
                    [data]="dateGenerationLookup.items"
                    [(ngModel)]="settings.settings.dateGeneration"
                    [disabled]="!overrideDefaults"
                    name="data-generation-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-1527" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="accruedUponDefault"
                 filter="span">
                <span> Accrued Upon Default </span>
            </div>
            <div class="lv-field lv-field--checkbox">
                <input class="k-checkbox"
                       type="checkbox"
                       [disabled]="!overrideDefaults"
                       [(ngModel)]="settings.settings.accruedUponDefault"
                       name="accruedUponDefault">
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-1525" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="quoted"
                 filter="span">
                <span> Quoted </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-quoted-cds"
                    class="lv-terms-general-small-input"
                    [data]="quotedLookup.items"
                    [(ngModel)]="settings.settings.quoted"
                    [disabled]="!overrideDefaults"
                    name="quoted-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5072" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="referanceEntityType"
                 filter="span">
                <span> Referance Entity Type </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-referance-entity-type-cds"
                    class="lv-terms-general-small-input"
                    [data]="referenceEntityTypeLookup.items"
                    [(ngModel)]="settings.settings.referenceEntityType"
                    [disabled]="!overrideDefaults"
                    name="referance-entity-type-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5073" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="quoteType"
                 filter="span">
                <span> Quote Type </span>
            </div>
            <div class="lv-input-field">
                <kendo-dropdownlist
                    lvId="lv-quote-type-cds"
                    class="lv-terms-general-small-input"
                    [data]="quoteTypeLookup.items"
                    [(ngModel)]="settings.settings.quoteType"
                    [disabled]="!overrideDefaults"
                    name="quote-type-cds"
                    valueField="id"
                    textField="text"
                    valuePrimitive="true"
                    [popupSettings]="{ width: 120 }">
                </kendo-dropdownlist>
            </div>
        </lv-flex-box>

        <lv-flex-box data-cy="DM-5074" direction="row">
            <div class="lv-label lv-label-field"
                 showAfter="1500"
                 kendoTooltip
                 [tooltipTemplate]="multiplicationFactor"
                 filter="span">
                <span> Multiplication Factor</span>
            </div>
            <div class="lv-input-field align-sufix">
                <lv-numeric-text-box
                    class="lv-input-field"
                    [disabled]="!overrideDefaults"
                    [format]="formatZero"
                    [decimals]="decimalsZero"
                    [(ngModel)]="settings.settings.multiplicationFactor"
                    name="multiplicationFactor">
                </lv-numeric-text-box>
                <span class="lv-sufix-label">%</span>
            </div>
        </lv-flex-box>

    </lv-flex-box>
</lv-flex-box>
  
  <ng-template #nominalValue>
    <lv-tooltip dm="dM-1522"></lv-tooltip>
  </ng-template>
  <ng-template #ccy>
    <lv-tooltip dm="dM-1517"></lv-tooltip>
  </ng-template>
  <ng-template #daysToSettle>
    <lv-tooltip dm="dM-1530"></lv-tooltip>
  </ng-template>
  <ng-template #couponFrequency>
    <lv-tooltip dm="dM-1533"></lv-tooltip>
  </ng-template>
  <ng-template #couponDayCount>
    <lv-tooltip dm="dM-1532"></lv-tooltip>
  </ng-template>
  <ng-template #BusinessDayConvention>
    <lv-tooltip dm="dM-5069"></lv-tooltip>
  </ng-template>
  <ng-template #cdsDateGeneration>
    <lv-tooltip dm="dM-5070"></lv-tooltip>
  </ng-template>
  <ng-template #accruedUponDefault>
    <lv-tooltip dm="dM-1527"></lv-tooltip>
  </ng-template>
  <ng-template #quoted>
    <lv-tooltip dm="dM-1525"></lv-tooltip>
  </ng-template>
  <ng-template #referanceEntityType>
    <lv-tooltip dm="dM-5072"></lv-tooltip>
  </ng-template>
  <ng-template #quoteType>
    <lv-tooltip dm="dM-5073"></lv-tooltip>
  </ng-template>
  <ng-template #multiplicationFactor>
    <lv-tooltip dm="dM-5074"></lv-tooltip>
  </ng-template>
  
