import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LvLookupEnum } from '@lv-core-ui/util';
import { ICDSTermsSectionSettings } from '../../../models/cds-terms-settings/cds-terms-section-settings';
import { IBaseCDSTermsSettings } from '../../../models/cds-terms-settings/base-cds-terms-settings';
import { Currency, IsQuotedClean } from '@lv-instrument-common/index';
import { DayCountDescription, FrequencyDescription } from "@lv-analytics/models";
import { BusinessDayConventionDescription } from '../../../models/cds-terms-settings/enums/cds-business-day-convention';
import { DateGenerationDescription } from '../../../models/cds-terms-settings/enums/cds-date-generation';
import { QuoteType } from '../../../models/cds-terms-settings/enums/cds-quote-type';
import { ReferanceEntityTypeDescription } from '../../../models/cds-terms-settings/enums/cds-reference-entity-type';

@Component({
  selector: 'lv-cds-terms-section-settings',
  templateUrl: './lv-cds-terms-section-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})  
export class LvCdsTermsSectionSettingsComponent implements OnInit{
  @Input() settings: IBaseCDSTermsSettings<ICDSTermsSectionSettings>;
  @Input() overrideDefaults: boolean;

  formatZero = 'n0';
  decimalsZero = '0';
  decimalsTwo = '2';
  formatTwo = '#,###.##';
  decimalsFour = '4';
  formatFour = '#,###.####';

  ccyLookup: LvLookupEnum;
  couponFrequencyLookup: LvLookupEnum;
  couponDayCountLookup: LvLookupEnum;
  businessDayConventionLookup: LvLookupEnum;
  dateGenerationLookup: LvLookupEnum;
  quotedLookup: LvLookupEnum;
  referenceEntityTypeLookup: LvLookupEnum;
  quoteTypeLookup: LvLookupEnum;
  
  ngOnInit() {
    this.overrideDefaults = true;
    this.ccyLookup = new LvLookupEnum(Currency);
    this.couponFrequencyLookup = new LvLookupEnum(FrequencyDescription);
    this.couponDayCountLookup = new LvLookupEnum(DayCountDescription);
    this.businessDayConventionLookup = new LvLookupEnum(BusinessDayConventionDescription);
    this.dateGenerationLookup = new LvLookupEnum(DateGenerationDescription);
    this.quotedLookup = new LvLookupEnum(IsQuotedClean);
    this.referenceEntityTypeLookup = new LvLookupEnum(ReferanceEntityTypeDescription);
    this.quoteTypeLookup = new LvLookupEnum(QuoteType);

    this.settings = {
          overrideSystemDefaults: true,
          settings: {},
        } as IBaseCDSTermsSettings<ICDSTermsSectionSettings>
  }
}
