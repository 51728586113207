import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ICdsCreditSettings } from '@lv-analytics/models/cds/market-data/market-data-cds-credit-settings/market-data-cds-credit-settings';

@Component({
  selector: 'lv-cds-market-data-credit-settings',
  templateUrl: './lv-cds-market-data-credit-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LvCdsMarketDataCreditSettingsComponent {
    @Input() settings: ICdsCreditSettings;
  
    @HostBinding('class.lv-credit-settings')
    get isLvEquityOptionCreditSettingsComponent () {
      return true;
    }
}
