<lv-flex-box
  direction="row"
  data-cy="DM-1483">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="interestRatesSourceSettingsTooltip"
    filter="span">
    <span> Interest Rate Source </span>
  </div>

  <div class="lv-input-field lv-input-field--auto lv-interest-rates-section-settings-input">
    <kendo-dropdownlist
      name="interestRatesSource"
      valueField="id"
      textField="text"
      [data]="interestRateSource.items"
      [valuePrimitive]="true"
      [(ngModel)]="settings.interestRateSource"
      [disabled]="!overrideSystemDefaults">
    </kendo-dropdownlist>
  </div>
</lv-flex-box>

<lv-flex-box
  direction="row"
  data-cy="DM-1484">

  <div class="lv-label lv-label-field"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="interestRatesYieldCurveSettingsTooltipTemplate"
    filter="span">
    <span> Yield Curve </span>
  </div>
  <div class="lv-interest-rates-section-settings-advanced-grid-wrapper">
    <lv-advanced-grid
      [columns]="columns"
      [records]="settings.yieldCurveSettings"
      [scrollRows]="9"
      [height]="200"
      [editDisabled]="!overrideSystemDefaults"
      [class.lv-advanced-grid-disabled-values]="!overrideSystemDefaults"
      [createFormGroup]="createFormGroup"
      class="lv-interest-rates-yield-curve-settings"
      lvId="lv-interest-rates-cds-yield-curve-settings">
    </lv-advanced-grid>
  </div>
</lv-flex-box>

<ng-template #interestRatesSourceSettingsTooltip>
  <lv-tooltip dm="dM-1483"></lv-tooltip>
</ng-template>
<ng-template #interestRatesYieldCurveSettingsTooltipTemplate>
  <lv-tooltip dm="dM-1484"></lv-tooltip>
</ng-template>
