<lv-flex-box class="lv-terms-section-settings-system-defaults">
  <div class="lv-label-field lv-label lv-label--title space-bottom">SYSTEM DEFAULTS</div>

  <ng-template
    [ngTemplateOutlet]="systemDefaults?.templateRef"
    [ngTemplateOutletContext]="{
      systemDefaults: systemDefault,
      $implicit: systemDefault
    }">
  </ng-template>
</lv-flex-box>
<lv-flex-box class="lv-terms-section-settings-my-defaults">
  <div class="lv-label-field lv-label lv-label--title">MY DEFAULTS</div>
  <div>
    <lv-flex-box
      class="lv-input-field space-bottom"
      direction="row"
      [attr.data-cy]="dataCy">
      <div class="lv-label lv-label-field"
        [attr.data-tooltip-id]="'overrideTermsSystemDefaultsLabel'">
        Override System Defaults
      </div>
      <div class="lv-field lv-field--checkbox">
        <input type="checkbox"
          class="k-checkbox override-system-defaults"
          [attr.id]="'overrideTermsSystemDefaults'"
          [(ngModel)]="myDefault.overrideSystemDefaults"/>
        <label class="k-checkbox-label"
          lvId="override-system-defaults-checkbox-my-defaults"
          [attr.for]="'overrideTermsSystemDefaults'"></label>
      </div>
    </lv-flex-box>
    <ng-template
      [ngTemplateOutlet]="myDefaultsSettings?.templateRef"
      [ngTemplateOutletContext]="{
        settings: myDefault,
        $implicit: myDefault
      }">
    </ng-template>
  </div>
</lv-flex-box>
