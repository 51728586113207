<lv-section-settings
    [settings]="settings"
    showAfter="1500"
    kendoTooltip
    [tooltipTemplate]="interestRatesOverrideSystemDefaultsSettingsTooltip"
    filter="div[data-tooltip-id]"
    [dataCy]="'DM-1492'">
  <ng-template systemDefaultsSettings let-systemSettings>
    <lv-cds-interest-rates-section-settings
      [settings]="systemSettings"
      [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData">
    </lv-cds-interest-rates-section-settings>
  </ng-template>

  <ng-template environmentSettings let-settings let-overrideSystemDefaults="overrideSystemDefaults">
    <lv-cds-interest-rates-section-settings
      [settings]="settings"
      [systemYieldCurvesSettingsData]="systemYieldCurvesSettingsData"
      [overrideSystemDefaults]="overrideSystemDefaults">
    </lv-cds-interest-rates-section-settings>
  </ng-template>
</lv-section-settings>

<ng-template #interestRatesOverrideSystemDefaultsSettingsTooltip let-anchor>
  <ng-container *ngIf="getTootlipId(anchor, 'overrideSystemDefaultsLabel')">
    <lv-tooltip dm="dM-1492"></lv-tooltip>
  </ng-container>
</ng-template>
